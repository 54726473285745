import http from "./httpService";

const apiEndpoint = "/incomeType";

function incomeTypeUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getIncomeTypes() {
  return http.get(apiEndpoint);
}

export function getIncomeType(id) {
  return http.get(incomeTypeUrl(id));
}

export function saveIncomeType(incomeType) {
  if (incomeType._id) {
    const body = { ...incomeType };
    delete body._id;
    return http.put(incomeTypeUrl(incomeType._id), body);
  }
  return http.post(apiEndpoint, incomeType);
}

export function deleteIncomeType(incomeTypeId) {
  return http.delete(incomeTypeUrl(incomeTypeId));
}
