import React, { Component } from "react";
import FactoringOverduesTable from "./factoringOverduesTable";
import Pagination from "../common/pagination";
import { deleteFactoring, getOverdueFactorings, setFactoringDate } from "../../services/factoringService";
import { getCustomers } from "../../services/customerService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";
import currencyFormating from "../../utils/currencyFormating";
import moment from "moment";
import Loader from '../common/loader';

class FactoringOverdues extends Component {
  state = {
    factorings: [],
    customers: [],
    loanTypes: [],
    currentPage: 1,
    pageSize: 60,
    searchQuery: "",
    sortColumn: {
      path: "overDays",
      order: "desc"
    },
  };

  async componentDidMount() {
    const today = moment().hour(0).minutes(0).second(0);
    const { data: customers } = await getCustomers();
    const { data: factorings }  = await getOverdueFactorings();
    factorings.forEach(factoring => {
      factoring.overDays = Math.ceil(moment.duration(today.diff(moment(factoring.invoiceDueDate))).asDays());
    });

    this.setState({ factorings, customers });
  }

  handleDelete = async factoring => {
    if (window.confirm("Are you sure you want delete this item")) {
      const originalFactorings = this.state.factorings;
      const factorings = this.state.factorings.filter(item => item._id !== factoring._id);
      this.setState({ factorings });
      try {
        await deleteFactoring(factoring._id);
        this.setState({ currentPage: 1 });
      } catch (ex) {
        if (ex.response && ex.response.status === 404);
        toast.error("This loan has already been deleted.");
        this.setState({ factorings: originalFactorings });
      }
    }
  };

  b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'application/pdf' });
}

  handleDateChange = async (factoring, date, dateName) => {
    const originalFactorings = this.state.factorings;
    let factorings = this.state.factorings;
    let updateFactoring = factorings.find(item => item._id === factoring._id);
    updateFactoring[dateName] = moment(date).toString();

    this.setState({ factorings });
    try {
      if(updateFactoring[dateName] === "Invalid date") {
        updateFactoring[dateName] = null;
      }
      await setFactoringDate(factoring._id, updateFactoring[dateName], dateName )
      toast.info("Date set");
    } catch (ex) {
      if (ex.response && ex.response.status === 404);
      toast.error("Cannot update date");
      this.setState({ factorings: originalFactorings });
    }
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleSearch = query => {
    this.setState({
      searchQuery: query,
      selectedLoanStatus: null,
      selectedLoanType: null,
      currentPage: 1
    });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      factorings: allFactorings
    } = this.state;

    let filtered = allFactorings;

    if (searchQuery) {
      filtered = allFactorings.filter(factoring =>
        factoring.customerName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const factorings = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: factorings };
  };

  loader() {
    return {

    }
  }

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { totalCount, data: factorings } = this.getPagedData();

    const totalFactoringBalance = Math.round(factorings.reduce(function (a, b) {
      return a + b.invoiceAmount
    }, 0) * 100) / 100;

    return (
      <div className="page">

        <div style={{display:'flex', flexFlow: 'row'}}>
          <div className="box">
            <p>There are {totalCount} factorings in the page</p>
            <p>Total overdue balance is {currencyFormating(totalFactoringBalance)}</p>
          </div>
        </div>
        <div style={{display:'flex', flexFlow: 'row'}}>
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
        </div>
          <FactoringOverduesTable
            factorings={factorings}
            sortColumn={sortColumn}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
            onDateChange={this.handleDateChange}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            onPageChange={this.handlePageChange}
            currentPage={currentPage}
          />
      <div className="row">
        <Loader data={this.state.factorings}/>
      </div>
    </div>
    );
  }
}

export default FactoringOverdues;
