import http from "./httpService";

const apiEndpoint = "/loantypes";

function loanTypeUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getLoanTypes() {
  return http.get(apiEndpoint);
}

export function getLoanType(id) {
  return http.get(loanTypeUrl(id));
}

export function saveLoanType(loanType) {
  if (loanType._id) {
    const body = { ...loanType };
    delete body._id;
    return http.put(loanTypeUrl(loanType._id), body);
  }
  return http.post(apiEndpoint, loanType);
}

export function deleteLoanType(loanTypeId) {
  return http.delete(loanTypeUrl(loanTypeId));
}
