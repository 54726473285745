import React, { Component } from "react";
import { updateTable } from "../services/customerService";

class UpdateTable extends Component  {

  doSubmit = async () => {
    await updateTable();
  };

  render() {
    return (
      <div>
        <h1>Table update Button</h1>
        <button onClick={this.doSubmit} >Make update</button>
      </div>
    );
  }
}

export default UpdateTable;
