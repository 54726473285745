import React from "react";
import TableHeaderNoSort from "./tableHeaderNoSort";
import TableBody from "./tableBody";

const TableNoSort = ({ columns, data }) => {
  return (
    <table className="table table-striped ">
      <TableHeaderNoSort columns={columns} />
      <TableBody data={data} columns={columns} />
    </table>
  );
};

export default TableNoSort;
