import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getCustomer, saveCustomer } from "../../services/customerService";
import { getLoans } from "../../services/loanService";

class CustomerForm extends Form {
  state = {
    data: {
      name: "",
      regCode: "",
      contactPerson: "",
      email: "",
      phone: "",
      IBAN: "",
    },
    loans: [],
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .min(0)
      .max(50)
      .label("Name"),
    regCode: Joi.number()
      .required()
      .label("Registration Code"),
    contactPerson: Joi.string()
      .required()
      .min(0)
      .max(50)
      .label("Contact Person"),
    email: Joi.string()
      .required()
      .email()
      .label("Email"),
    phone: Joi.number()
      .required()
      .min(0)
      .label("Telephone"),
    IBAN: Joi.string()
      .length(20)
      .label("IBAN"),
  };

  async populateLoans() {
    const { data: loans } = await getLoans();
    this.setState({ loans });
  }

  async populateCustomer() {
    try {
      const customerId = this.props.match.params.id;
      if (customerId === "new") return;

      const { data: customer } = await getCustomer(customerId);
      this.setState({ data: this.mapToViewModel(customer) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateLoans();
    await this.populateCustomer();
  }

  mapToViewModel(customer) {
    return {
      _id: customer._id,
      name: customer.name,
      regCode: customer.regCode,
      contactPerson: customer.contactPerson,
      email: customer.email,
      phone: customer.phone,
      IBAN: customer.IBAN,
    };
  }

  doSubmit = async () => {
    await saveCustomer(this.state.data);

    this.props.history.push("/customers");
  };

  render() {
    return (
      <div className={"form"}>
        <h1>Customer Form</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Name")}
          {this.renderInput("regCode", "Registration Code", "number")}
          {this.renderInput("contactPerson", "Contact Person")}
          {this.renderInput("email", "Email")}
          {this.renderInput("phone", "Telephone", "number")}
          {this.renderInput("IBAN", "IBAN")}
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default CustomerForm;
