import React, { Component } from "react";
import TableNoSort from "../common/tableNoSort";
import moment from "moment";

class factoringPartialTable extends Component {
  columns = [
    {
      path: "date",
      label: "Date",
      content: payment =>
        moment(payment.date)
          .format("DD-MM-YY")
          .toString()
    },
    {
      path: "amount",
      label: "Amount"
    },
    {
      path: "comment",
      label: "Comment"
    },
    {
      key: "delete",
      content: payment => (
        <button
          onClick={() => this.props.onDelete(payment)}
          className="btn btn-danger btn-sm"
        >
          Delete
        </button>
      )
    }
  ];
 
  render() {
    const payments = this.props.payments;
    return <TableNoSort columns={this.columns} data={payments} />;
  }
}


export default factoringPartialTable;
