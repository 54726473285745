import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import {
    getContract,
    deleteContract,
    deleteContractTerm
} from "../../services/contractService";
import ContractTermForm from "./contractTermForm";
import ContractTermTable from "./contractTermTable";
import TableNoSort from "../common/tableNoSort";
import currencyFormating from "../../utils/currencyFormating";

class FactoringDetails extends Component {
    state = {
        data: {
            contract: {
                _id: this.props.match.params.id,
            },
            contracts: []
        },
    };
    columns = [
        {
            path: "customerName",
            label: "Customer"
        },
        {
            path: "contractNumber",
            label: "Contract Number",
            content: contract => (
                <Link to={`/contracts/${contract._id}`}>{contract.contractNumber}</Link>
            )
        },
        {
            path: "date",
            label: "Contract Date",
            content: contract =>
                moment(contract.date)
                    .format("DD-MM-YY")
                    .toString()
        },
        { path: "limit", label: "Limit", content: contract => currencyFormating(contract.limit) },
        { path: "loanStatusName", label: "Factoring Status" },
        {
            key: "delete",
            content: contract => (
                <button
                    onClick={() => this.handleDelete(contract)}
                    className="btn btn-danger btn-sm"
                >
                    Delete
                </button>
            )
        }
    ];

    async populateContract() {
        const contractsUpdate = [ ...this.state.data.contracts ];
        const { data: contract } = await getContract(this.props.match.params.id);
        contractsUpdate.push(contract);

        contractsUpdate.paidInterest = contract.paidInterest;
        const data = {
            contracts: contractsUpdate,
            contract: contract,
        }
        this.setState({ data });
    }

    async componentDidMount() {
        await this.populateContract();
    }

    handleDelete = async contract => {
        try {
            await deleteContract(contract._id);
            toast.info("Contract removed");
            window.location = "/contracts";
        } catch (ex) {
            if (ex.response && ex.response.status === 404);
            toast.error("This contract has already been deleted.");
        }
    };

    handleContractTermDelete = async term => {
        if (window.confirm("Are you sure you want delete this item")) {
            const contracts = this.state.contracts;
            const contractId = contracts[0]._id;
            const allTerms = contracts[0].terms;
            const originalContracts = contracts;
            const updatedTerms = allTerms.filter(pp => pp._id !== term._id);
            contracts[0].terms = updatedTerms;
            this.setState({ contracts });

            try {
                await deleteContractTerm(contractId, term);
                toast.info("Payment removed");
                window.location.reload();
            } catch (ex) {
                if (ex.response && ex.response.status === 404);
                toast.error("This principal payment is already deleted.");

                this.setState({ loans: originalContracts });
            }
        }
    };

    render() {
        const { contract, contracts } = this.state.data;

        return (
            <div>
                <p>Contract detail view</p>
                <div>
                    <TableNoSort columns={this.columns} data={contracts} />
                </div>
                <div>
                    <Link
                        className="btn btn-primary"
                        to={`/contracts/form/${contract ? contract._id : ''}`}
                        style={{ marginBottom: 20 }}
                    >
                        Change contract data
                    </Link>
                </div>
                <div>
                    <ContractTermForm contractId={contract ? contract._id : ''} />
                </div>
         
                <div>
                    <p>Contract terms</p>
                    <ContractTermTable contract={contracts[0]} onDelete={this.handleContractTermDelete}/>
                </div>
            </div>
        );
    }
}

export default FactoringDetails;
