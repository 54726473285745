import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import auth from "../../services/authService";

class CostTypeTable extends Component {
  columns = [
    {
      path: "name",
      label: "CostType Name",
      content: costType => (
        <Link to={`/costType/${costType._id}`}>
          {costType.name}
        </Link>
      )
    }
  ];
  deleteColumn = {
    key: "delete",
    content: costType => (
      <button
        onClick={() => this.props.onDelete(costType)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }
  render() {
    const { costTypes, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={costTypes}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default CostTypeTable;
