import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoanStatusesTables from "./loanStatusesTable";
import Pagination from "../common/pagination";
import {
  getLoanStatuses,
  deleteLoanStatus
} from "../../services/loanStatusService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";

class LoanStatuses extends Component {
  state = {
    loanStatuses: [],
    currentPage: 1,
    pageSize: 10,
    searchQuery: "",
    selectedGenre: null,
    sortColumn: {
      path: "name",
      order: "asc"
    }
  };

  async componentDidMount() {
    const { data: loanStatuses } = await getLoanStatuses();
    this.setState({ loanStatuses });
  }

  handleDelete = async loanStatus => {
    const originalLoanStatuses = this.state.loanStatuses;
    const loanStatuses = this.state.loanStatuses.filter(
      ls => ls._id !== loanStatus._id
    );
    this.setState({ loanStatuses });
    try {
      await deleteLoanStatus(loanStatus._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404);
      toast.error("This loanStatuses has already been deleted.");

      this.setState({ loanStatuses: originalLoanStatuses });
    }
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleSearch = query => {
    this.setState({
      searchQuery: query,
      currentPage: 1
    });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      loanStatuses: allLoanStatuses
    } = this.state;

    let filtered = allLoanStatuses;

    if (searchQuery)
      filtered = allLoanStatuses.filter(ls =>
        ls.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const loanStatuses = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: loanStatuses };
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;

    const { totalCount, data: loanStatuses } = this.getPagedData();

    return (
      <div className="page">
      <div className="row">
        <div className="col">
          {user && (
            <Link
              className="btn btn-primary"
              to="/loanstatuses/new"
              style={{ marginBottom: 20 }}
            >
              New Loan Status
            </Link>
          )}
          <p>There is {totalCount} loan statuses in the database</p>
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
          <LoanStatusesTables
            loanStatuses={loanStatuses}
            sortColumn={sortColumn}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            onPageChange={this.handlePageChange}
            currentPage={currentPage}
          />
        </div>
      </div>
      </div>

    );
  }
}

export default LoanStatuses;
