import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import auth from "../../services/authService";

class OtherTable extends Component {
  columns = [
    {
      path: "name",
      label: "Other Name",
      content: other => (
        <Link to={`/other/${other._id}`}>
          {other.name}
        </Link>
      )
    },
  ];
  deleteColumn = {
    key: "delete",
    content: other => (
      <button
        onClick={() => this.props.onDelete(other)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }
  render() {
    const { others, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={others}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default OtherTable;
