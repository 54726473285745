import React, { Component } from "react";
import _ from "lodash";
import moment from 'moment';
import { toast } from "react-toastify";

import { getFactoringCustomers } from '../../services/customerService';
import { getContractPayers } from '../../services/contractService';
import { getFilteredFactorings, setFactoringDate, deleteFactoring } from '../../services/factoringService';
import FactoringTable from "./factoringTable";
import { paginate } from "../../utils/paginate";
import currencyFormating from "../../utils/currencyFormating";
import Pagination from "../common/pagination";
import Loader from '../common/loader';
import DatePicker from "react-date-picker";


class FactoringFilter extends Component {
    state = {
        customers: [],
        data: {
            customer: '',
        },
        notPaid: false,
        notReceived: false,
        received: false,
        customerId: '',
        contractPayers: [],
        selectedPayerId: '',
        factorings: [],
        currentPage: 1,
        pageSize: 2000,
        sortColumn: {
          path: "invoiceDate",
          order: "desc"
        },
        getFactoringPushed: false,
        startDate: null,
        endDate: null,
    }

    handleDateChange = async (factoring, date, dateName) => {
        const originalFactorings = this.state.factorings;
        let factorings = this.state.factorings;
        let updateFactoring = factorings.find(item => item._id === factoring._id);
        updateFactoring[dateName] = moment(date).toString();

        this.setState({ factorings });
        try {
          if (updateFactoring[dateName] === "Invalid date") {
            updateFactoring[dateName] = null;
          }
          await setFactoringDate(factoring._id, updateFactoring[dateName], dateName )
          toast.info("Date set");
        } catch (ex) {
          if (ex.response && ex.response.status === 404);
          toast.error("Cannot update date");
          this.setState({ factorings: originalFactorings });
        }
      };

    handlePageChange = page => {
        this.setState({ currentPage: page });
      };

    async componentDidMount() {
        const {data: customers } = await getFactoringCustomers()
        this.setState({ customers })
    }

    async setPayers(customerId) {
        let contractPayers = [];

        if (customerId) {
            const { data } = await getContractPayers(customerId);
            contractPayers = data;
        };

        // reset other
        const selectedPayerId = '';
        const startDate = null;
        const endDate = null;

        this.setState({contractPayers, selectedPayerId, startDate, endDate})
    }

    async getFactorings(){
        const data = {
            customerId: this.state.customerId ? this.state.customerId : 'null',
            payerId: this.state.selectedPayerId,
            notPaid: this.state.notPaid,
            notReceived: this.state.notReceived,
            received: this.state.received,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        };

        const getFactoringPushed = true;
        const result = await getFilteredFactorings(data);

        this.setState({ getFactoringPushed, factorings: result.data })
    }

    handleDelete = async factoring => {
        if (window.confirm("Are you sure you want delete this item")) {
          const originalFactorings = this.state.factorings;
          const factorings = this.state.factorings.filter(item => item._id !== factoring._id);
          this.setState({ factorings });
          try {
            await deleteFactoring(factoring._id);
            this.setState({ currentPage: 1 });
          } catch (ex) {
            if (ex.response && ex.response.status === 404);
            toast.error("This loan has already been deleted.");
            this.setState({ factorings: originalFactorings });
          }
        }
      };

    getPagedData = () => {
        const {
          pageSize,
          currentPage,
          sortColumn,
          factorings: allFactorings,
        } = this.state;

        const sorted = _.orderBy(allFactorings, [sortColumn.path], [sortColumn.order]);

        const factorings = paginate(sorted, currentPage, pageSize);

        return { totalCount: allFactorings.length, data: factorings };
      };


    render() {
        const { pageSize, currentPage, sortColumn, getFactoringPushed } = this.state;
        const { totalCount, data: filteredFactorings } = this.getPagedData();
        const notReceivedFacotrings = filteredFactorings.filter((factoring) => {
            return factoring.receivedDate === null;
          })
          const notPaidFacotrings = filteredFactorings.filter((factoring) => {
            return factoring.paymentDate === null;
          })

          const totalFactoringBalance = Math.round(notReceivedFacotrings.reduce(function (a, b) {
            return a + b.invoiceAmount
          }, 0) * 100) / 100;

          const totalNotPaidFacotringBalance = Math.round(notPaidFacotrings.reduce(function (a, b) {
            return a + b.payment
          }, 0) * 100) / 100;

          const totalFees = Math.round(filteredFactorings.reduce(function (a, b) {
            return a + b.fee
          }, 0) * 100) / 100;

        return (
            <div>
                <div className="col-sm">
                    <div className="form">
                        <label>Select Customer: </label>

                    <div className="row">
                        <select name={'Customers'} id={'customers'} style={{ width:"200", padding:"10px"}} onChange={(e) => {
                            const customerId = e.target.value;
                            this.setPayers(customerId);
                            this.setState({ customerId })}}>
                        <option value="" />
                            {this.state.customers.map(option => (
                            <option key={option._id} value={option._id}>
                                {option.name}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className="row">
                        <label>Select Payer: </label>
                    </div>
                    <div className="row">
                        <select name={'Payers'} id={'payers'} style={{ width:"200", padding:"10px" }} onChange={(e) => {
                            const selectedPayerId = e.target.value
                            this.setState({ selectedPayerId })}}>
                        <option value="" />
                            {this.state.contractPayers.map(option => (
                            <option key={option.payerId} value={option.payerId}>
                                {option.payerName}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className="row">
                            <input
                            id={'notpaid'}
                            style={{alignSelf:"center"}}
                                type="checkbox"
                                onChange={() => {
                                    this.setState({notPaid: !this.state.notPaid})
                                }}>

                            </input>
                        <label htmlFor={"notpaid"} style={{ padding:"10px"}}>Not Paid</label>
                            <input
                            id={'notReceived'}
                            style={{alignSelf:"center"}}
                                type="checkbox"
                                onChange={() => {
                                    this.setState({notReceived: !this.state.notReceived})
                                }}>

                        </input>
                        <label htmlFor={"notReceived"} style={{ padding:"10px"}}>Not Received</label>
                            <input
                                id={'received'}
                                style={{alignSelf:"center"}}
                                    type="checkbox"
                                    onChange={() => {
                                        this.setState({received: !this.state.received})
                                    }}>
                            </input>
                        <label htmlFor={"notReceived"} style={{ padding:"10px"}}>Received</label>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                            Select Invoice Start Date:
                            </div>
                            <div className="row" style={{ padding:"10px"}}>
                                <DatePicker value={this.state.startDate} onChange={startDate => this.setState({startDate})}  />
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                Select Invoice End Date:
                            </div>
                            <div className="row" style={{ padding:"10px"}}>
                                <DatePicker value={this.state.endDate} onChange={endDate => this.setState({endDate})}  />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <button className="btn btn-primary" onClick={() => {this.getFactorings()}}>Get Factorings</button>
                    </div>
                    </div>
                    <div style={{display:'flex', flexFlow: 'row'}}>
                        <div className="box">
                            <p>There is {totalCount} factorings in the page</p>
                            <p>Total not received factoring balance in page is {currencyFormating(totalFactoringBalance)}</p>
                            <p>Total not paid factoring balance in page is {currencyFormating(totalNotPaidFacotringBalance)}</p>
                            <p>Total fees in page is {currencyFormating(totalFees)}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <FactoringTable
                            factorings={filteredFactorings}
                            sortColumn={sortColumn}
                            onDelete={this.handleDelete}
                            onDownloadPDF={() => {console.log('Not implemented')}}
                            onSort={() => {console.log('Not implemented')}}
                            onDateChange={this.handleDateChange}
                        />
                        <Pagination
                            itemsCount={totalCount}
                            pageSize={pageSize}
                            onPageChange={this.handlePageChange}
                            currentPage={currentPage}
                        />
                        </div>
                    </div>
                    <div className="row">
                        {getFactoringPushed && <Loader data={this.state.factorings}/>}
                    </div>
                    <div className="row">
                        {!getFactoringPushed && <p>Push Get Factorings button, you fool!</p>}
                    </div>
                </div>
            </div>
        )
    }

}

export default FactoringFilter;