import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { initBankBalance } from "../../services/bankService";
import { toast } from "react-toastify";

class BankBalanceForm extends Form {
  state = {
    data: {
        balance: 0
    },
    errors: {}
  };

  schema = {
    balance: Joi.number()
      .required()
      .label("Balance")
  };

  doSubmit = async () => {
    const data = this.state.data;

    await initBankBalance(data.balance);
    toast.info("Bank Balance initalized!");

    window.location.reload();
  };

  render() {
    return (
      <div>
        <form onSubmit={this.doSubmit}>
          {this.renderInput("balance", "Initial Blance", "number")}
          {this.renderButton("Init Balance")}
        </form>
      </div>
    );
  }
}

export default BankBalanceForm;
