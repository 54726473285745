import React, { Component } from "react";
import { getTransaction } from '../../services/bankService';

class FactoringPartial extends Component {
    state = {
        transaction: {},
    };

    async componentDidMount() {
        const { data: transaction } = await getTransaction(this.props.match.params.id);
        this.setState({ transaction });
    }

    render() {
        const { transaction } = this.state;

        let metadata = {};
        if (transaction.additionalInfo && transaction.additionalInfo.metadata) {
            metadata = transaction.additionalInfo.metadata;
        }
  
        return (
            <div className="row">
                <div className="col">
                <p>{`TypeID: ${metadata.typeId}`} </p>
                <p>{`CustomerID: ${metadata.customerId}`}</p>
                <p>{`EqualsTotals: ${metadata.equalsTotal}`}</p>
                <p>{`ResidualAmount: ${metadata.residualAmount}`}</p>
                <p>{`ResidualComment: ${metadata.residualComment}`}</p>
                </div>
            </div>
        );
    }
}

export default FactoringPartial;
