import http from "./httpService";

const apiEndpoint = "/factoring";

function factoringUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export function getFactorings() {
    return http.get(apiEndpoint);
}

export function getFilteredFactorings(data) {
    const { customerId, payerId, notReceived, received, notPaid, startDate, endDate } = data;
    const start = startDate === null? null: new Date(startDate).getTime();
    const end = endDate === null? null: new Date(endDate).getTime();

    return http.get(`${apiEndpoint}/filtered/${customerId}?payerid=${payerId}&notreceived=${notReceived}&received=${received}&notpaid=${notPaid}&start=${start}&end=${end}`);
}

export function getOverdueFactorings() {
    return http.get(`${apiEndpoint}/overdues/`);
}

export function getNotReceivedFactorings() {
    return http.get(`${apiEndpoint}/notreceived/`);
}

export function getCustomerSum() {
    return http.get(`${apiEndpoint}/customersum/`);
}

export function getCustomerSumByMonthly() {
    return http.get(`${apiEndpoint}/monthlyfeesbycustomer/`);
}

export function getPayerSum() {
    return http.get(`${apiEndpoint}/payersum/`);
}

export function getWeeklyDueDate() {
    return http.get(`${apiEndpoint}/weeklyduedate/`);
}

export function getWeeklyFees() {
    return http.get(`${apiEndpoint}/weeklyfees/`);
}

export function getFactoringFees() {
    return http.get(`${apiEndpoint}/fees/`);
}

export function getFactoring(factoringId) {
    return http.get(factoringUrl(factoringId));
}

export function saveFactoring(factoring) {
    if (factoring._id) {
        const body = { ...factoring };
        delete body._id;
        return http.put(factoringUrl(factoring._id), body);
    }
    return http.post(apiEndpoint, factoring);
}

export function deleteFactoring(factoringId) {
    return http.delete(factoringUrl(factoringId));
}

export function setFactoringDate(factoringId, date, dateName) {

    const body = {
        date: date,
        dateName: dateName
    };

    return http.put(`${apiEndpoint}/date/${factoringId}`, body);
}

export function addPartialPayment(factoringId, payment) {
    const body = {
        payment: payment
    };

    return http.put(`${apiEndpoint}/partialadd/${factoringId}`, body);
}

export function deletePartialPayment(factoringId, payment) {
    const body = {
        payment: payment
    };

    return http.put(`${apiEndpoint}/partialdelete/${factoringId}`, body);
}
