import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CompanyDashboard from "./components/companyDashboard";
import Settings from "./components/settings";
import Accounting from "./components/accounting/accounting";
import Loans from "./components/loans/loans";
import LoanForm from "./components/loans/loanForm";
import LoanDetails from "./components/loans/loanDetails";
import TakenLoans from "./components/takenLoans/takenLoans";
import TakenLoanForm from "./components/takenLoans/takenLoanForm";
import TakenLoanDetails from "./components/takenLoans/takenLoanDetails";
import Profile from "./components/users/profile";
import Contracts from "./components/factoring/contracts";
import ContractForm from "./components/factoring/contractForm";
import ContractDetails from "./components/factoring/contractDetails";
import LoanTypes from "./components/loanTypesAndStatuses/loanTypes";
import LoanTypeForm from "./components/loanTypesAndStatuses/loanTypeForm";
import LoanStatuses from "./components/loanTypesAndStatuses/loanStatuses";
import LoanStatusForm from "./components/loanTypesAndStatuses/loanStatusForm";
import Costs from "./components/costs/costs";
import Incomes from "./components/incomes/incomes";
import CostTypes from "./components/costTypes/costTypes";
import IncomeTypes from "./components/incomeTypes/incomeTypes";
import CostForm from "./components/costs/costForm";
import IncomeForm from "./components/incomes/incomeForm";
import IncomeTypeForm from "./components/incomeTypes/incomeTypeForm";
import CostTypeForm from "./components/costTypes/costTypeForm";
import OtherForm from "./components/others/otherForm";
import Others from "./components/others/others";
import Customers from "./components/customers/customers";
import Creditors from "./components/creditors/creditors";
import CreditorForm from "./components/creditors/creditorForm";
import Payers from "./components/payers/payers";
import PayerForm from "./components/payers/payerForm";
import FactoringForm from "./components/factoring/factoringForm";
import Factoring from './components/factoring/factoring';
import FactoringFilter from './components/factoring/factoringFilter';
import FactoringOverdues from './components/factoring/factoringOverdues';
import FactoringPartial from './components/factoring/factoringPartial';
import NotReceivedFactoring from './components/factoring/factoringNotReceived';
import CustomerForm from "./components/customers/customerForm";
import NotFound from "./components/notFound";
import NavBar from "./components/navBar";
import LoginForm from "./components/users/loginForm";
import RegisterForm from "./components/users/registerForm";
import Logout from "./components/users/logout";
import ProtectedRoute from "./components/common/protectedRoute";
import auth from "./services/authService";
import "react-toastify/dist/ReactToastify.css";
import UpdateTable from './components/updateTable';
import OpenTransaction from './components/bank/openTransactions';
import Transaction from './components/bank/transaction';
import Transactions from './components/bank/transactions';
import suggestedTransactions from './components/bank/suggestedTransactions';
import TransactionDeals from './components/bank/transactionDeals';
import TransactionMetaData from './components/bank/transactionMetaData';
import TermForm from './components/factoring/contractTermForm';
import bankBalanceForm from './components/bank/bankBalanceForm';

class App extends Component {
  state = {};
  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }
  render() {
    const { user } = this.state;
    return (
      <React.Fragment>
        <ToastContainer />
        <NavBar user={user} />
        <main className="container-fluid background-color">
          <Switch>
            <Route path="/register" component={RegisterForm} />
            <Route path="/login" component={LoginForm} />
            <ProtectedRoute path="/profile" component={Profile} />
            <Route path="/logout" component={Logout} />
            <ProtectedRoute
              path="/updatetable"
              render={props => (
                <UpdateTable {...props} user={this.state.user} />
              )}
            />
            <ProtectedRoute path="/customers/:id" component={CustomerForm} />
            <ProtectedRoute
              path="/customers"
              render={props => <Customers {...props} user={this.state.user} />}
            />
            <ProtectedRoute path="/creditors/:id" component={CreditorForm} />
            <ProtectedRoute
              path="/creditors"
              render={props => <Creditors {...props} user={this.state.user} />}
            />
            <ProtectedRoute path="/transaction/process/:id" component={Transaction} />
            <ProtectedRoute path="/transaction/deals/:id" component={TransactionDeals} />
            <ProtectedRoute path="/transaction/metadata/:id" component={TransactionMetaData} />
            <ProtectedRoute path="/transactions/" component={Transactions} />
            <ProtectedRoute path="/suggestedTransactions/" component={suggestedTransactions} />
            <ProtectedRoute path="/initbankbalance/" component={bankBalanceForm} />
            <ProtectedRoute
              path="/opentransactions"
              render={props => <OpenTransaction {...props} user={this.state.user} />}
            />
            <ProtectedRoute
              path="/factoring/overdues"
              render={props => <FactoringOverdues {...props} user={this.state.user} />}
            />
            <ProtectedRoute path="/factoring/partial/:id" component={FactoringPartial} />
            <ProtectedRoute path="/factoring/notreceived" component={NotReceivedFactoring} />
            <ProtectedRoute path="/factoringfilter" component={FactoringFilter} />
            <ProtectedRoute path="/factoring/:id" component={FactoringForm} />
            <ProtectedRoute
              path="/factoring"
              render={props => <Factoring {...props} user={this.state.user} />}
            />
            <ProtectedRoute path="/payers/:id" component={PayerForm} />
            <ProtectedRoute
              path="/payers"
              render={props => <Payers {...props} user={this.state.user} />}
            />

            <ProtectedRoute path="/loans/form/:id" component={LoanForm} />
            <ProtectedRoute path="/takenloans/form/:id" component={TakenLoanForm} />
            <ProtectedRoute path="/contracts/form/:id" component={ContractForm} />
            <ProtectedRoute path="/contracts/:id" component={ContractDetails} />
            <ProtectedRoute path="/contractterm/:id" component={TermForm} />
            <ProtectedRoute path="/loans/:id" component={LoanDetails} />
            <ProtectedRoute path="/takenloans/:id" component={TakenLoanDetails} />
            <ProtectedRoute
              path="/takenloans"
              render={props => <TakenLoans {...props} user={this.state.user} />}
            />
            <ProtectedRoute
              path="/loans"
              render={props => <Loans {...props} user={this.state.user} />}
            />
            <ProtectedRoute
              path="/contracts"
              render={props => <Contracts {...props} user={this.state.user} />}
            />
            <ProtectedRoute path="/loantypes/:id" component={LoanTypeForm} />
            <ProtectedRoute
              path="/loantypes"
              render={props => <LoanTypes {...props} user={this.state.user} />}
            />
            <ProtectedRoute
              path="/loanstatuses/:id"
              component={LoanStatusForm}
            />
            <ProtectedRoute
              path="/loanstatuses"
              render={props => (
                <LoanStatuses {...props} user={this.state.user} />
              )}
            />
            <ProtectedRoute
              path="/cost/:id"
              component={CostForm}
            />
            <ProtectedRoute
              path="/income/:id"
              component={IncomeForm}
            />
            <ProtectedRoute
              path="/costType/:id"
              component={CostTypeForm}
            />
            <ProtectedRoute
              path="/incomeType/:id"
              component={IncomeTypeForm}
            />
            <ProtectedRoute
              path="/other/:id"
              component={OtherForm}
            />
            <ProtectedRoute
              path="/cost"
              render={props => (
                <Costs {...props} user={this.state.user} />
              )}
            />
            <ProtectedRoute
              path="/income"
              render={props => (
                <Incomes {...props} user={this.state.user} />
              )}
            />
            <ProtectedRoute
              path="/costType"
              render={props => (
                <CostTypes {...props} user={this.state.user} />
              )}
            />
            <ProtectedRoute
              path="/incomeType"
              render={props => (
                <IncomeTypes {...props} user={this.state.user} />
              )}
            />
            <ProtectedRoute
              path="/other"
              render={props => (
                <Others {...props} user={this.state.user} />
              )}
            />
            <ProtectedRoute
              path="/dashboard"
              render={props => (
                <CompanyDashboard {...props} user={this.state.user} />
              )}
            />
            <ProtectedRoute
              path="/accounting"
              render={props => <Accounting {...props} user={this.state.user} />}
            />
            <ProtectedRoute
              path="/settings"
              render={props => <Settings {...props} user={this.state.user} />}
            />
            <Route path="/not-found" component={NotFound} />
            <Redirect from="/" exact to="/login" />
            <Redirect to="/not-found" />
          </Switch>
        </main>
      </React.Fragment>
    );
  }
}

export default App;
