import React from "react";
import Joi from "joi-browser";
import moment from "moment";
import Form from "../common/form";
import { toast } from "react-toastify";

class InterestForm extends Form {
  state = {
    data: {
      date: moment(Date.now())
        .format("YYYY-MM-DD")
        .toString(),
      interestMethodId: 1
    },
    interestMethods: [
      { _id: 1, name: "Fix" },
      { _id: 2, name: "Schedule" },
      { _id: 3, name: "Overdraft" }
    ],
    errors: {}
  };

  schema = {
    date: Joi.date()
      .required()
      .label("Interest End Date"),
    interestMethodId: Joi.number()
      .required()
      .label("Interest Method")
  };

  doSubmit = async () => {
    const loanId = this.props.loanId;
    let { date, interestMethodId } = this.state.data;
    let method;

    if (interestMethodId == 1) {
      method = "fix";
    } else if (interestMethodId == 2) {
      method = "schedule";
    } else if (interestMethodId == 3) {
      method = "overdraft";
    } else {
      method = "overdraft";
    }
    const isInterestForPeriodGenerated = this.props.isInterestService;
    const generateSaveInterest = this.props.generateService;

    const { data } = await isInterestForPeriodGenerated(loanId, { date: date });

    if (data.isInserted) {
      toast.error("Monthly Interest Already Inserted For this period!");
    } else {
      await generateSaveInterest(loanId, { date, method });
      toast.info("Monthly Interest Generated and Saved!");
    }
  };

  populateInterest() {
    const data = this.state.data;
    this.setState({ data: this.mapToViewModel(data) });
  }

  async componentDidMount() {
    await this.populateInterest();
  }

  mapToViewModel(interest) {
    return {
      interestMethodId: interest.interestMethodId,
      date: moment(interest.date)
        .format("YYYY-MM-DD")
        .toString()
    };
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("date", "Interest End Date", "date")}
          {this.renderSelect(
            "interestMethodId",
            "Interest Method",
            this.state.interestMethods
          )}
          {this.renderButton("Generate Interest")}
        </form>
      </div>
    );
  }
}

export default InterestForm;
