import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import auth from "../../services/authService";

class PayersTable extends Component {
    columns = [
        {
            path: "name",
            label: "Name",
            content: payer => (
                <Link to={`/payers/${payer._id}`}>{payer.name}</Link>
            )
        },
        { path: "regCode", label: "Code" },
        { path: "contactPerson", label: "Contact" },
        { path: "email", label: "Email" },
        { path: "phone", label: "Phone" },
    ];
    deleteColumn = {
        key: "delete",
        content: payer => (
            <button
                onClick={() => this.props.onDelete(payer)}
                className="btn btn-danger btn-sm"
            >
                Delete
      </button>
        )
    };

    constructor() {
        super();
        const user = auth.getCurrentUser();
        if (user && user.isAdmin) this.columns.push(this.deleteColumn);
    }
    render() {
        const { payers, onSort, sortColumn } = this.props;

        return (
            <Table
                columns={this.columns}
                data={payers}
                sortColumn={sortColumn}
                onSort={onSort}
            />
        );
    }
}

export default PayersTable;
