import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import {
    getTakenLoan,
    deleteTakenLoan,
    getInterest,
    deletePrincipalPayment,
    deleteInterestPayment,
    deleteAdditionalPayment,
    makeAdditionalPayment,
    makePrincipalPayment,
    makeInterestPayment,
    daleteLoanSchedule,
    generateSaveInterest,
    isInterestForPeriodGenerated,
    makeSchedulePayment,
    saveScheduleToDb
} from "../../services/takenLoanService";
import PaymentPrincipalForm from "../loans/paymentPrincipalForm";
import PaymentPrincipalTable from "../loans/paymentPrincipalTable";
import PaymentInterestForm from "../loans/paymentInterestForm";
import PaymentInterestTable from "../loans/paymentInterestTable";
import PaymentAdditionalForm from "../loans/paymentAdditionalForm";
import PaymentAdditionalTable from "../loans/paymentAdditionalTable";
import PaymentScheduleForm from "../loans/paymentScheduleForm";
import TableNoSort from "../common/tableNoSort";
import InterestForm from "../loans/interestForm";
import CalculatedInterestTable from "../loans/calculatedInterestTable";

class TakenLoanDetails extends Component {
    state = {
        data: {
            takenLoan: {
                _id: this.props.match.params.id,
                paidInterest: "NA"
            },
            takenLoans: []
        },
        interestToDate: "NA",
        lastInterestPeriod: "NA"
    };

    columns = [
        {
            path: "creditorName",
            label: "Creditor"
        },
        {
            path: "contractNumber",
            label: "Contract Number"
        },
        {
            path: "date",
            label: "Contract Date",
            content: takenLoan =>
                moment(takenLoan.date)
                    .format("DD-MM-YY")
                    .toString()
        },
        {
            path: "dueDate",
            label: "Due Date",
            content: takenLoan =>
                moment(takenLoan.dueDate)
                    .format("DD-MM-YY")
                    .toString()
        },
        { path: "rate", label: "Rate" },
        { path: "amount", label: "Amount" },
        { path: "balance", label: "Balance", content: takenLoan => Math.round(takenLoan.balance * 100) / 100 },
        { path: "loanTypeName", label: "Loan Type" },
        { path: "loanStatusName", label: "Loan Status" },
        { path: "paymentDate", label: "Payment Date" },
        {
            key: "delete",
            content: takenLoan => (
                <button
                    onClick={() => this.handleDelete(takenLoan)}
                    className="btn btn-danger btn-sm"
                >
                    Delete
        </button>
            )
        }
    ];

    async populateTakenLoan() {
        const takenLoans = this.state.data.takenLoans;
        const { data: takenLoan } = await getTakenLoan(this.props.match.params.id);
        this.state.data.takenLoans.push(takenLoan);
        this.setState({ takenLoans });
        this.state.data.takenLoan.paidInterest = takenLoan.paidInterest;
        this.setState({ takenLoan });
    }
    async populateInterestToDate() {
        let interestToDate;
        let lastInterestPeriod;
        const loanId = this.props.match.params.id;
        const { data } = await getInterest(loanId);
        interestToDate = data.interestDebt;
        lastInterestPeriod = data.lastPeriod.period;
        this.setState({ interestToDate });
        this.setState({ lastInterestPeriod });
    }

    async componentDidMount() {
        await this.populateTakenLoan();
        await this.populateInterestToDate();
    }

    handleDelete = async takenLoan => {
        try {
            await deleteTakenLoan(takenLoan._id);
            toast.info("Taken Loan removed");
            window.location = "/takenloans";
        } catch (ex) {
            if (ex.response && ex.response.status === 404);
            toast.error("This taken loan has already been deleted.");
        }
    };

    handlePrincipalPaymentDelete = async payment => {
        if (window.confirm("Are you sure you want delete this item")) {
            const takenLoans = this.state.takenLoans;
            const loanId = takenLoans[0]._id;
            const allPayments = takenLoans[0].principalPayments;
            const originaltakenLoans = takenLoans;
            const updatedPayments = allPayments.filter(pp => pp._id !== payment._id);
            takenLoans[0].principalPayments = updatedPayments;
            this.setState({ takenLoans });

            try {
                await deletePrincipalPayment(loanId, payment);
                toast.info("Payment removed");
                window.location.reload();
            } catch (ex) {
                if (ex.response && ex.response.status === 404);
                toast.error("This principal payment is already deleted.");

                this.setState({ takenLoans: originaltakenLoans });
            }
        }
    };

    handleInterestPaymentDelete = async payment => {
        if (window.confirm("Are you sure you want delete this item")) {
            const takenLoans = this.state.takenLoans;
            const loanId = takenLoans[0]._id;
            const allPayments = takenLoans[0].interestPayments;
            const originaltakenLoans = takenLoans;
            const updatedPayments = allPayments.filter(ip => ip._id !== payment._id);
            takenLoans[0].interestPayments = updatedPayments;
            this.setState({ takenLoans });

            try {
                await deleteInterestPayment(loanId, payment);
                toast.info("Interest payment removed");
                window.location.reload();
            } catch (ex) {
                if (ex.response && ex.response.status === 404);
                toast.error("This interest payment is already deleted.");

                this.setState({ takenLoans: originaltakenLoans });
            }
        }
    };

    handleAdditionalPaymentDelete = async payment => {
        if (window.confirm("Are you sure you want delete this item")) {
            const takenLoans = this.state.takenLoans;
            const loanId = takenLoans[0]._id;
            const allPayments = takenLoans[0].additionalPayments;
            const originaltakenLoans = takenLoans;
            const updatedPayments = allPayments.filter(pp => pp._id !== payment._id);
            takenLoans[0].additionalPayments = updatedPayments;
            this.setState({ takenLoans });

            try {
                await deleteAdditionalPayment(loanId, payment);
                toast.info("Payment removed");
                window.location.reload();
            } catch (ex) {
                if (ex.response && ex.response.status === 404);
                toast.error("This additional payment is already deleted.");

                this.setState({ takenLoans: originaltakenLoans });
            }
        }
    };
    render() {
        const { takenLoan, takenLoans } = this.state.data;
        let paidInterest = takenLoan.paidInterest;
        if (takenLoan.paidInterest !== "NA") {
            paidInterest = Math.round(takenLoan.paidInterest * 100) / 100;
        }

        return (
            <div>
                <p>Loan detail view</p>
                <div>
                    <TableNoSort columns={this.columns} data={takenLoans} />
                </div>
                <div>
                    <Link
                        className="btn btn-primary"
                        to={`/takenLoans/form/${takenLoan._id}`}
                        style={{ marginBottom: 20 }}
                    >
                        Change loan data
          </Link>
                </div>

                <div className="row">
                    <div className="col-sm-2" style={{ width: 200 }}>
                        <div className="card bg-light mb-3">
                            <div className="card-body">
                                <h6 className="card-subtitle mb-2 text-muted">
                                    Interest Debt To Date
                </h6>
                                <h5>{this.state.interestToDate} €</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2" style={{ width: 200 }}>
                        <div className="card bg-light mb-3">
                            <div className="card-body">
                                <h6 className="card-subtitle mb-2 text-muted">
                                    Last Interest Period
                </h6>
                                <h5>{this.state.lastInterestPeriod}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="card bg-light mb-3" style={{ width: 200 }}>
                            <div className="card-body">
                                <h6 className="card-subtitle mb-2 text-muted">
                                    Interest Paid To Date
                </h6>
                                <h5>{paidInterest} €</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3" style={{ width: 200 }}>
                        <div className="card bg-light mb-3" style={{ width: 250 }}>
                            <div className="card-body">
                                <h6 className="card-subtitle mb-2 text-muted">
                                    Generate Monthly Interest
                </h6>
                                <h5>
                                    <InterestForm generateService={generateSaveInterest} isInterestService={isInterestForPeriodGenerated} loanId={takenLoan._id} />
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3" style={{ width: 200 }}>
                        <div className="card bg-light mb-3" style={{ width: 250 }}>
                            <div className="card-body">
                                <h6 className="card-subtitle mb-2 text-muted">
                                    Make Additional Payment
                </h6>
                                <h5>
                                    <PaymentAdditionalForm service={makeAdditionalPayment} loanId={takenLoan._id} />
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3" style={{ width: 200 }}>
                        <div className="card bg-light mb-3" style={{ width: 250 }}>
                            <div className="card-body">
                                <h6 className="card-subtitle mb-2 text-muted">
                                    Make Principal Payment
                </h6>
                                <h5>
                                    <PaymentPrincipalForm service={makePrincipalPayment} loanId={takenLoan._id} />
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2" style={{ width: 200 }}>
                        <div className="card bg-light mb-3" style={{ width: 250 }}>
                            <div className="card-body">
                                <h6 className="card-subtitle mb-2 text-muted">
                                    Make Interest Payment
                </h6>
                                <h5>
                                    <PaymentInterestForm service={makeInterestPayment} loanId={takenLoan._id} />
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="accordion" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseOne"
                                        aria-expanded="false"
                                        aria-controls="collapseOne"
                                    >
                                        Additional payments table
                  </button>
                                </h2>
                            </div>

                            <div
                                id="collapseOne"
                                className="collapse show"
                                aria-labelledby="headingOne"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">
                                    {takenLoans[0] && (
                                        <PaymentAdditionalTable
                                            loan={takenLoans[0]}
                                            onDelete={this.handleAdditionalPaymentDelete}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link collapsed"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                    >
                                        Principal payments table
                  </button>
                                </h2>
                            </div>
                            <div
                                id="collapseTwo"
                                className="collapse"
                                aria-labelledby="headingTwo"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">
                                    {takenLoans[0] && (
                                        <PaymentPrincipalTable
                                            loan={takenLoans[0]}
                                            onDelete={this.handlePrincipalPaymentDelete}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link collapsed"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                    >
                                        Interest Payments table
                                    </button>
                                </h2>
                            </div>
                            <div
                                id="collapseThree"
                                className="collapse"
                                aria-labelledby="headingThree"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">
                                    {takenLoans[0] && (
                                        <PaymentInterestTable
                                            loan={takenLoans[0]}
                                            onDelete={this.handleInterestPaymentDelete}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                <button
                                    className="btn btn-link collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                >
                                    Calculated Interest table
                                </button>
                                </h2>
                            </div>
                            <div
                                id="collapseThree"
                                className="collapse"
                                aria-labelledby="headingThree"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">
                                {takenLoans[0] && (
                                    <CalculatedInterestTable
                                    monthInterests={takenLoans[0].monthInterests}
                                    onDelete={this.handleInterestPaymentDelete}
                                    />
                                )}
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
                <div className="row">
                    <div style={{ width: 300, marginTop: 30, marginBottom: 30 }}>
                        {takenLoans[0] && (
                            <PaymentScheduleForm
                                makeSchedulePayment={makeSchedulePayment}
                                saveScheduleToDb={saveScheduleToDb}
                                service={daleteLoanSchedule}
                                loanId={takenLoan._id}
                                loan={takenLoans[0]} />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default TakenLoanDetails;