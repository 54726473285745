import React from "react";
import Joi from "joi-browser";
import { saveTerm } from "../../services/contractService"
import { getPayers } from "../../services/payerServices"
import Form from "../common/form";
import { toast } from "react-toastify";

class ContractTermForm extends Form {
    state = {
        data: {
            payerId: "",
            limit: 15000,
            rate: 3,
            deadline: 30
        },
        payers: [],
        contract: {},
        errors: {},
        term: {},
        termId: ''
    };

    schema = {
        _id: Joi.string(),
        payerId: Joi.string()
            .required()
            .label("Payer"),
        limit: Joi.number()
            .required()
            .min(0)
            .max(200000)
            .label("Limit"),
        rate: Joi.number()
            .required()
            .min(0)
            .max(100)
            .label("Rate"),
        deadline: Joi.number()
            .required()
            .min(0)
            .max(365)
            .label("Deadline"),
    };
    async populatePayers() {
        const { data: payers } = await getPayers();
        this.setState({ payers });
    }
    async populateTerm() {
        try {
            const termId = (this.props.match && this.props.match.params.id) || 'new'; 
            if (termId === "new") { 
                this.setState({ termId });
                return;
            }
            const contract = this.props.location.state.contract;
            const term = contract.terms.find(term => term._id === termId)

            this.setState({ termId, contract, data:this.mapToViewModel(term) });
        } catch (err) {
            console.log(err);
        }
    }

    async componentDidMount() {
        await this.populatePayers();
        await this.populateTerm();
    }

    mapToViewModel(term) {
        return {
        _id: term._id,
        payerId: term.payerId,
        limit: term.limit,
        rate: term.rate,
        deadline: term.deadline,
    }}

    doSubmit = async () => {
        const { termId, data } = this.state;

        if (termId === 'new') {
            const contractId = this.props.contractId;
 
            await saveTerm(contractId, termId, data);
    
            toast.info("Additional term added!");
            window.location = `/contracts/${contractId}`
        } else {
            const contractId = this.state.contract._id
            const { data } = this.state;

            await saveTerm(contractId, termId, data);
    
            toast.info("term updated!");
            window.location = `/contracts/${contractId}`
        }
    };

    render() {
        return (
            <div className={"form"}>
                <form onSubmit={this.handleSubmit}>
                    {this.renderSelect("payerId", "Payer", this.state.payers)}
                    {this.renderInput("limit", "Limit", "number")}
                    {this.renderInput("rate", "Rate", "number")}
                    {this.renderInput("deadline", "Deadline", "number")}
                    {this.renderButton("Save")}
                </form>
            </div>
        );
    }
}

export default ContractTermForm;
