import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Table from "../common/table";
import FileSaver from "file-saver";
import { getPDF } from "../../services/downloadPDF";
import { toast } from "react-toastify";

class FactoringOverduesTable extends Component {
  constructor() {
    super();
    this.state = {
      selectedFactoringIds: [],
      today: moment()
    }
  }

  today = moment()

  columns = [
    {
      path: "customerName",
      label: "Customer"
    },
    {
        path: "invoiceNumber",
        label: "Number",
        content: factoring => (
          <Link to={`/factoring/${factoring._id}`}>{factoring.invoiceNumber}</Link>
        )
    },
    {
        path: "invoiceDate",
        label: "Date",
        content: factoring =>
            moment(factoring.invoiceDate)
            .format("DD-MM-YY")
            .toString()
    },
    {
        path: "invoiceAmount",
        label: "Amount"
    },
    {
        path: "payerName",
        label: "Payer"
    },
    {
      path: "dueDate",
      label: "Due Date",
      content: factoring => 
            moment(factoring.invoiceDueDate)
              .format("DD-MM-YY")
              .toString()
      },
    { 
      key: "partialPayment",
      label: "Partial Payment",
      content: factoring => (
        <Link to={`/factoring/partial/${factoring._id}`}>Payments</Link>
      )
    },
    {
      label: "Select",
      key: "select",
      content: factoring => (
        <input
          type="checkbox"
          onChange={() => {
            this.handleFactoringSelection(factoring._id);
          }}
        >
        </input>
      )
    },
    {
      path: "overDays",
      label: "Days over"
  },
  ];

  handleFactoringSelection = (factoringId) => {
    let selectedFactoringIds = [];
    selectedFactoringIds = this.state.selectedFactoringIds;
    const isInArray = selectedFactoringIds.includes(factoringId);

    if (isInArray){
      selectedFactoringIds = selectedFactoringIds.filter( (id) => {
        return id !== factoringId;
      })
    } else {
      selectedFactoringIds.push(factoringId)
    }

    this.setState({ selectedFactoringIds });
  }

  b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'application/pdf' });
}

  handleDownloadPDF = async () => {
      const factoringIds = this.state.selectedFactoringIds;
      if (factoringIds.length > 0) {
        let { customerName } = this.props.factorings.find((factoring) => {
          return factoring._id === factoringIds[0];
        })
        customerName = customerName.replace(' ', '_')
        
        try {
         const pdfDoc =  await getPDF(factoringIds);;
         const blob = this.b64toBlob(pdfDoc.data);
         FileSaver.saveAs(blob, `Raport ${moment().format('DD.MM.YYYY')}-${customerName}.pdf`);
         window.location.reload();
        } catch (ex) {
          if (ex.response && ex.response.status === 500);
          toast.error("Cannot download pdf.");
        }
      } else {
        toast.warn('No factorings selected');
      }
  };

  render() {
    const { factorings, onSort, sortColumn } = this.props;

    return (
      <div>
        <div className="boxTransparentRight">
          <button
          onClick={() => this.handleDownloadPDF()}
          className="btn btn-primary btn-sm"
          >
            Generate PDF
          </button>
        </div>
      <Table
        columns={this.columns}
        data={factorings}
        sortColumn={sortColumn}
        onSort={onSort}
      />
      </div>
    );
  }
}

export default FactoringOverduesTable;
