import React, { Component } from "react";
import TableNoSort from "../common/tableNoSort";
import auth from "../../services/authService";
import { toast } from "react-toastify";
import { generatePdf } from "../../services/generatePDF";

class paymentScheduleTable extends Component {
  state = {
    isSchedule: true
  };
  columns = [
    { path: "jrk", label: "Jrk" },
    {
      path: "date",
      label: "Date"
    },
    {
      path: "amount",
      label: "Amount"
    },
    {
      path: "interest",
      label: "Interest"
    },
    {
      path: "principal",
      label: "Principal"
    },
    {
      path: "total",
      label: "Total"
    },

    {
      path: "balance",
      label: "Balance"
    },
    {
      path: "isPaid",
      label: "isPaid"
    }
  ];
  paymentColumn = {
    key: "payment",
    content: payment => (
      <div>
        {payment.isPaid === "No" && (
          <button
            onClick={() => this.handleSchedulePayment(payment)}
            className="btn btn-primary"
          >
            Make payment
          </button>
        )}
      </div>
    )
  };

  handleScheduleSave = async (loanId, data) => {
    // remove temp _id
    data.forEach(function (item) {
      delete item._id;
    });
    const saveScheduleToDb = this.props.saveScheduleToDb;

    await saveScheduleToDb(loanId, data);
    toast.info("Schdedule saved!");
    window.location.reload();
  };

  handleSchedulePayment = async payment => {
    const loanId = this.props.loanId;
    const makeSchedulePayment = this.props.makeSchedulePayment;

    await makeSchedulePayment(loanId, payment);
    toast.info("Schdedule payment made!");
    window.location.reload();
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.paymentColumn);
  }

  handleGeneratePDF(columns) {
    const head = [[]];
    const body = [];
    const data = this.props.data;
    for (let i = 0; i < data.length; i++) {
      body.push(Object.values(data[i]));
    }
    for (let i = 0; i < this.columns.length; i++) {
      head[0].push(columns[i].label);
    }
    generatePdf(head, body);
  }
  render() {
    const data = this.props.data;
    const isSchedule = this.props.isSchedule;
    return (
      <div>
        <div>
          {isSchedule && (
            <button
              onClick={() => this.handleGeneratePDF(this.columns)}
              className="btn btn-primary"
              style={{ marginBottom: 20 }}
            >
              Generate PDF
            </button>
          )}
        </div>
        <div>
          {!isSchedule && (
            <button
              onClick={() => this.handleScheduleSave(this.props.loanId, data)}
              className="btn btn-primary"
              style={{ marginBottom: 20 }}
            >
              Save schedule
            </button>
          )}
        </div>
        <div>
          <TableNoSort columns={this.columns} data={data} />
        </div>
      </div>
    );
  }
}

export default paymentScheduleTable;
