import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Table from "../common/table";
import auth from "../../services/authService";
import currencyFormating from "../../utils/currencyFormating";

class LoansTable extends Component {
  columns = [
    {
      path: "customerName",
      label: "Customer"
    },
    {
      path: "contractNumber",
      label: "Contract Number",
      content: loan => (
        <Link to={`/loans/${loan._id}`}>{loan.contractNumber}</Link>
      )
    },
    {
      path: "date",
      label: "Contract Date",
      content: loan =>
        moment(loan.date)
          .format("DD-MM-YY")
          .toString()
    },
    {
      path: "dueDate",
      label: "Due Date",
      content: loan =>
        moment(loan.dueDate)
          .format("DD-MM-YY")
          .toString()
    },
    { path: "rate", label: "Rate" },
    { path: "amount", label: "Amount", content: loan => currencyFormating(loan.amount) },
    { path: "balance", label: "Balance", content: loan => currencyFormating(loan.balance) },
    { path: "loanTypeName", label: "Loan Type" },
    { path: "loanStatusName", label: "Loan Status" },
    { path: "paymentDate", label: "Payment Date" },
  ];
  deleteColumn = {
    key: "delete",
    content: loan => (
      <button
        onClick={() => this.props.onDelete(loan)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }
  render() {
    const { loans, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={loans}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default LoansTable;
