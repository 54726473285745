import http from "./httpService";

const apiEndpoint = "/accounting";

export const getAllAccounts = () => {
  // get costs, incomes, others models
  return http.get(`${apiEndpoint}/allaccounts`);
} 

export const getAccounts = () => {

  return http.get(`${apiEndpoint}/`);
} 

export const getProfit = () => {

  return http.get(`${apiEndpoint}/profit`);
} 

export const getReport = () => {

  return http.get(`${apiEndpoint}/report`);
} 


function accountingUrl(id) {
  return `${apiEndpoint}/${id}`;
}


export function getCurrentMonths() {
  //return [{ data: "05:2019" }];
  return http.get(apiEndpoint);
}

export function getCurrentMonth(id) {
  return http.get(accountingUrl(id));
}

export function setCurrentMonth(currentMonth) {
  if (currentMonth.currentMonthId) {
    const body = { ...currentMonth };
    delete body.currentMonthId;
    return http.put(accountingUrl(currentMonth.currentMonthId), body);
  }
  return http.post(apiEndpoint, currentMonth);
}
