import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoanTypesTables from "./loanTypesTable";
import Pagination from "../common/pagination";
import { getLoanTypes, deleteLoanType } from "../../services/loanTypeService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";

class LoanTypes extends Component {
  state = {
    loanTypes: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    selectedGenre: null,
    sortColumn: {
      path: "name",
      order: "asc"
    }
  };

  async componentDidMount() {
    const { data: loanTypes } = await getLoanTypes();
    this.setState({ loanTypes });
  }

  handleDelete = async loanType => {
    const originalLoanTypes = this.state.loanTypes;
    const loanTypes = this.state.loanTypes.filter(
      lt => lt._id !== loanType._id
    );
    this.setState({ loanTypes });
    try {
      await deleteLoanType(loanType._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404);
      toast.error("This loan Type has already been deleted.");

      this.setState({ loanTypes: originalLoanTypes });
    }
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleSearch = query => {
    this.setState({
      searchQuery: query,
      currentPage: 1
    });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      loanTypes: allLoanTypes
    } = this.state;

    let filtered = allLoanTypes;

    if (searchQuery)
      filtered = allLoanTypes.filter(lt =>
        lt.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const loanTypes = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: loanTypes };
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;

    const { totalCount, data: loanTypes } = this.getPagedData();

    return (
      <div className="page">
      <div className="row">
        <div className="col">
          {user && (
            <Link
            className="btn btn-primary"
            to="/loantypes/new"
            style={{ marginBottom: 20 }}
            >
              New Loan Type
            </Link>
          )}
          <p>There is {totalCount} loan types in the database</p>
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
          <LoanTypesTables
            loanTypes={loanTypes}
            sortColumn={sortColumn}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
            />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            onPageChange={this.handlePageChange}
            currentPage={currentPage}
            />
        </div>
      </div>
      </div>
    );
  }
}

export default LoanTypes;
