import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Table from "../common/table";
import auth from "../../services/authService";
import currencyFormating from "../../utils/currencyFormating";

class ContractsTable extends Component {
    columns = [
        {
            path: "customerName",
            label: "Customer"
        },
        {
            path: "contractNumber",
            label: "Contract Number",
            content: contract => (
                <Link to={`/contracts/${contract._id}`}>{contract.contractNumber}</Link>
            )
        },
        {
            path: "date",
            label: "Contract Date",
            content: contract =>
                moment(contract.date)
                    .format("DD-MM-YY")
                    .toString()
        },
        { path: "limit", label: "Limit", content: contract => currencyFormating(contract.limit) },
        { path: "loanStatusName", label: "Factoring Status" }
    ];
    deleteColumn = {
        key: "delete",
        content: contract => (
            <button
                onClick={() => this.props.onDelete(contract)}
                className="btn btn-danger btn-sm"
            >
                Delete
      </button>
        )
    };

    constructor() {
        super();
        const user = auth.getCurrentUser();
        if (user && user.isAdmin) this.columns.push(this.deleteColumn);
    }
    render() {
        const { contracts, onSort, sortColumn } = this.props;

        return (
            <Table
                columns={this.columns}
                data={contracts}
                sortColumn={sortColumn}
                onSort={onSort}
            />
        );
    }
}

export default ContractsTable;
