/* Lihtne laenu annuiteet from SEB*/
import moment from "moment";

export function generateSchedule(startDate, sumVal, periodVal, intVal) {
  // var sumVal = 5000;
  // var periodVal = 60;
  // var intVal = 5;
  const data = [];
  const pross = intVal / 100;
  let pross_1 = 0;
  let k = 0;
  let k_makse = 0;
  let l_summa = sumVal;
  let p_makse = 0;
  //var date = new Date();
  let date = startDate;

  let arv_intress;
  let jaak;
  let isumm = 0;
  let psumm = 0;
  let tsumm = 0;
  let dateVal;
  let realDate;
  let isPaid;

  for (let i = 1; i <= periodVal; i++) {
    //date.setMonth(date.getMonth() + 1);
    date = moment(date).add(1, "M");

    dateVal = namedDate(date);
    realDate = moment(date).format("MM:YYYY");

    pross_1 = intVal / (100 * 12);
    k = 1 + pross_1;
    k_makse =
      sumVal *
      ((pross_1 * Math.pow(k, periodVal)) / (Math.pow(k, periodVal) - 1));
    l_summa = l_summa - p_makse;
    arv_intress = (l_summa * pross) / 12;
    p_makse = k_makse - arv_intress;

    if (i === 1) {
      jaak = sumVal;
    }
    jaak -= p_makse;
    if (jaak < 0) {
      jaak = 0;
    }

    // k_makse = k_makse;
    // l_summa = l_summa;
    // arv_intress = arv_intress;
    // p_makse = p_makse;
    // jaak = jaak;

    isumm += parseFloat(arv_intress);
    psumm += parseFloat(p_makse);
    tsumm += parseFloat(k_makse);

    data.push({
      _id: i + 100,
      jrk: i,
      date: dateVal,
      realDate: realDate,
      amount: calcNumberFormat(l_summa, 2),
      interest: calcNumberFormat(arv_intress, 2),
      principal: calcNumberFormat(p_makse, 2),
      total: calcNumberFormat(k_makse, 2),
      balance: calcNumberFormat(i !== periodVal ? jaak : 0, 2),
      isPaid: "No"
    });
  }
  //   console.log("intress", isumm);
  //   console.log("pohi", psumm);
  //   console.log("total", tsumm);
  return data;
}

function namedDate(date) {
  const month = moment(date).month() + 1;
  const year = moment(date).year();

  return getMonthName(month) + " " + year;
}

/* decimal format */

function calcNumberFormat(str, pre) {
  let thousandSep = " ";
  let decimalPoint = ".";
  let strString = parseFloat(str) + "";

  let parts = strString.split(".");
  let decimalLength = parts[1] ? parts[1].length : 0;
  let precision;
  let number;
  let decimalpart;
  let integerpart;
  let nfi;
  let nfj;

  if (!isNaN(pre)) {
    if (Math.abs(pre) <= 10) {
      precision = pre;
    }
  } else {
    precision = decimalLength;
  }

  try {
    number = parseFloat(strString + "");
    if (isNaN(number)) {
      return "NaN";
    }
  } catch (e) {
    return "NaN";
  }
  number =
    Math.round((number * Math.pow(10, precision)).toFixed(1)) /
    Math.pow(10, precision);
  integerpart = "" + (number < 0 ? Math.ceil(number) : Math.floor(number));
  if (!isNaN(pre)) {
    decimalpart =
      "" +
      Math.abs(Math.round((number - integerpart) * Math.pow(10, precision)));
    while (decimalpart.length < precision) {
      decimalpart = "0" + decimalpart;
    }
  } else {
    decimalpart = "" + (number - integerpart).toFixed(decimalLength);
  }

  var result = "";
  for (nfj = -1, nfi = integerpart.length; nfi >= 0; nfi--, nfj++) {
    if (nfj % 3 === 0 && nfj > 1) {
      result = thousandSep + result;
    }
    result = integerpart.charAt(nfi) + result;
  }

  if (precision > 0) {
    return result + decimalPoint + decimalpart.replace("0.", "");
  }
  return result;
}

function getMonthName(month) {
  switch (month) {
    case 1:
      month = "jaanuar";
      break;
    case 2:
      month = "veebruar";
      break;
    case 3:
      month = "märts";
      break;
    case 4:
      month = "aprill";
      break;
    case 5:
      month = "mai";
      break;
    case 6:
      month = "juuni";
      break;
    case 7:
      month = "juuli";
      break;
    case 8:
      month = "august";
      break;
    case 9:
      month = "september";
      break;
    case 10:
      month = "oktoober";
      break;
    case 11:
      month = "november";
      break;
    case 12:
      month = "detsember";
      break;
    default:
      month = "jaanuar";
  }
  return month;
}
