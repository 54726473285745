import React, { Component } from "react";
import { Link } from "react-router-dom";
import TakenLoansTables from "./takenLoansTable";
import ListGroup from "../common/listGroup";
import Pagination from "../common/pagination";
import { getTakenLoans, deleteTakenLoan } from "../../services/takenLoanService";
import { getCreditors } from "../../services/creditorServices";
import { getLoanStatuses } from "../../services/loanStatusService";
import { getLoanTypes } from "../../services/loanTypeService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";
import currencyFormating from "../../utils/currencyFormating";
import Loader from '../common/loader';

class TakenLoans extends Component {
    state = {
        takenLoans: [],
        creditors: [],
        loanTypes: [],
        loanStatuses: [],
        currentPage: 1,
        pageSize: 100,
        searchQuery: "",
        selectedLoanType: null,
        selectedLoanStatus: "5c6b179d5e21eb0016864724",
        sortColumn: {
            path: "date",
            order: "asc"
        }
    };

    async componentDidMount() {
        const { data: loanTypesData } = await getLoanTypes();
        const { data: loanStatusesData } = await getLoanStatuses();
        const loanTypes = [{ _id: "", name: "All Loan Types" }, ...loanTypesData];
        const loanStatuses = [{ _id: "", name: "All Loan Statuses" }, ...loanStatusesData];
        const { data: creditors } = await getCreditors();
        const { data: takenLoans } = await getTakenLoans();

        this.setState({ takenLoans, creditors, loanStatuses, loanTypes });
    }

    handleDelete = async takenLoan => {
        if (window.confirm("Are you sure you want delete this item")) {
            const originalLoans = this.state.takenLoans;
            const takenLoans = this.state.takenLoans.filter(item => item._id !== takenLoan._id);
            this.setState({ takenLoans });
            try {
                await deleteTakenLoan(takenLoan._id);
                this.setState({ currentPage: 1 });
            } catch (ex) {
                if (ex.response && ex.response.status === 404);
                toast.error("This loan has already been deleted.");
                this.setState({ takenLoans: originalLoans });
            }
        }
    };

    handlePageChange = page => {
        this.setState({ currentPage: page });
    };

    handleLoanTypeSelect = loanType => {
        this.setState({
            selectedLoanType: loanType,
            selectedLoanStatus: null,
            searchQuery: "",
            currentPage: 1
        });
    };

    handleLoanStatusSelect = loanStatus => {
        this.setState({
            selectedLoanStatus: loanStatus,
            searchQuery: "",
            electedLoanType: null,
            currentPage: 1
        });
    };

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    };

    handleSearch = query => {
        this.setState({
            searchQuery: query,
            selectedLoanStatus: null,
            selectedLoanType: null,
            currentPage: 1
        });
    };

    getPagedData = () => {
        const {
            pageSize,
            currentPage,
            sortColumn,
            selectedLoanType,
            searchQuery,
            selectedLoanStatus,
            takenLoans: allLoans
        } = this.state;

        let filtered = allLoans;

        if (searchQuery)
            filtered = allLoans.filter(takenLoan =>
                takenLoan.creditorName.toLowerCase().startsWith(searchQuery.toLowerCase())
            );
        else if (selectedLoanType && selectedLoanType._id)
            filtered = allLoans.filter(
                takenLoan => takenLoan.loanTypeId === selectedLoanType._id
            );
        else if (selectedLoanStatus && selectedLoanStatus._id)
            filtered = allLoans.filter(
                takenLoan => takenLoan.loanStatusId === selectedLoanStatus._id
            );

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const takenLoans = paginate(sorted, currentPage, pageSize);

        return { totalCount: filtered.length, data: takenLoans };
    };

    render() {
        const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
        const { user } = this.props;
        const { totalCount, data: takenLoans } = this.getPagedData();

        const totalLoansBalance = Math.round(takenLoans.reduce(function (a, b) {
            return a + b.balance
        }, 0) * 100) / 100;

        return (
            <div className="page">
                <div style={{ display: "flex", flexFlow: 'row' }}>
                    <div className="box">
                        {user && (
                            <Link className="btn btn-primary" to="/takenloans/form/new">
                                New Taken Loan
                            </Link>
                        )}

                    </div>
                    <div className="box">
                        <ListGroup
                            selectedItem={this.state.selectedLoanType}
                            items={this.state.loanTypes}
                            onItemSelect={this.handleLoanTypeSelect}
                        />
                    </div>
                    <div className="box">
                        <ListGroup
                            selectedItem={this.state.selectedLoanStatus}
                            items={this.state.loanStatuses}
                            onItemSelect={this.handleLoanStatusSelect}
                        />
                    </div >
                    <div className="box">
                        <p>There is {totalCount} taken loans in the database</p>
                        <p>Total taken loans balance is {currencyFormating(totalLoansBalance)}.</p>
                    </div>
                </div>
                <div style={{ display: "flex", flexFlow: 'row' }}>
                    <SearchBox value={searchQuery} onChange={this.handleSearch} />
                </div>

                <div>
                    <TakenLoansTables
                        takenLoans={takenLoans}
                        sortColumn={sortColumn}
                        onDelete={this.handleDelete}
                        onSort={this.handleSort}
                    />
                    <Pagination
                        itemsCount={totalCount}
                        pageSize={pageSize}
                        onPageChange={this.handlePageChange}
                        currentPage={currentPage}
                    />
                </div>
                <div className="row">
                    <Loader data={this.state.takenLoans} />
                </div>
            </div>
        );
    }
}

export default TakenLoans;
