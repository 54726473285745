import http from "./httpService";

const apiEndpoint = "/costType";

function costTypeUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getCostTypes() {
  return http.get(apiEndpoint);
}

export function getCostType(id) {
  return http.get(costTypeUrl(id));
}

export function saveCostType(costType) {
  if (costType._id) {
    const body = { ...costType };
    delete body._id;
    return http.put(costTypeUrl(costType._id), body);
  }
  return http.post(apiEndpoint, costType);
}

export function deleteCostType(costTypeId) {
  return http.delete(costTypeUrl(costTypeId));
}
