import http from "./httpService";

const apiEndpoint = "/cost";

function costUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getCosts() {
  return http.get(apiEndpoint);
}

export function getCost(id) {
  return http.get(costUrl(id));
}

export function saveCost(cost) {
  if (cost._id) {
    const body = { ...cost };
    delete body._id;
    return http.put(costUrl(cost._id), body);
  }
  return http.post(apiEndpoint, cost);
}

export function deleteCost(costId) {
  return http.delete(costUrl(costId));
}
