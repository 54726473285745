import http from "./httpService";

const apiEndpoint = "/customers";

function customerUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getCustomers() {
  return http.get(apiEndpoint);
}

export function getFactoringCustomers() {
  return http.get(`${apiEndpoint}/factoringcustomers`);
}

export function getCustomer(customerId) {
  return http.get(customerUrl(customerId));
}

export function saveCustomer(customer) {
  if (customer._id) {
    const body = { ...customer };
    delete body._id;
    return http.put(customerUrl(customer._id), body);
  }
  return http.post(apiEndpoint, customer);
}

export function deleteCustomer(customerId) {
  return http.delete(customerUrl(customerId));
}

export function setFactoringContract(customerId, hasFactoringContract) {

  const body = {
    hasFactoringContract: hasFactoringContract
  }

    return http.put(`${apiEndpoint}/setfactoring/${customerId}`, body);
}

//this service only to update soma tables
export function updateTable() {
  return http.put(`${apiEndpoint}/database`);
}
