import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import {
  getLoan,
  deleteLoan,
  getInterest,
  deletePrincipalPayment,
  deleteInterestPayment,
  deleteAdditionalPayment,
  makeAdditionalPayment,
  makePrincipalPayment,
  makeInterestPayment,
  daleteLoanSchedule,
  generateSaveInterest,
  isInterestForPeriodGenerated,
  makeSchedulePayment,
  saveScheduleToDb
} from "../../services/loanService";
import PaymentPrincipalForm from "./paymentPrincipalForm";
import PaymentPrincipalTable from "./paymentPrincipalTable";
import PaymentInterestForm from "./paymentInterestForm";
import PaymentInterestTable from "./paymentInterestTable";
import CalculatedInterestTable from "./calculatedInterestTable";
import PaymentAdditionalForm from "./paymentAdditionalForm";
import PaymentAdditionalTable from "./paymentAdditionalTable";
import PaymentScheduleForm from "./paymentScheduleForm";
import TableNoSort from "../common/tableNoSort";
import InterestForm from "./interestForm";

class LoanDetails extends Component {
  state = {
    data: {
      loan: {
        _id: this.props.match.params.id,
        paidInterest: "NA"
      },
      loans: []
    },
    interestToDate: "NA",
    lastInterestPeriod: "NA"
  };

  columns = [
    {
      path: "customerName",
      label: "Customer"
    },
    {
      path: "contractNumber",
      label: "Contract Number"
    },
    {
      path: "date",
      label: "Contract Date",
      content: loan =>
        moment(loan.date)
          .format("DD-MM-YY")
          .toString()
    },
    {
      path: "dueDate",
      label: "Due Date",
      content: loan =>
        moment(loan.dueDate)
          .format("DD-MM-YY")
          .toString()
    },
    { path: "rate", label: "Rate" },
    { path: "amount", label: "Amount" },
    { path: "balance", label: "Balance", content: loan => Math.round(loan.balance * 100) / 100 },
    { path: "loanTypeName", label: "Loan Type" },
    { path: "loanStatusName", label: "Loan Status" },
    { path: "paymentDate", label: "Payment Date" },
    {
      key: "delete",
      content: loan => (
        <button
          onClick={() => this.handleDelete(loan)}
          className="btn btn-danger btn-sm"
        >
          Delete
        </button>
      )
    }
  ];

  async populateLoan() {
    const loans = this.state.data.loans;
    const { data: loan } = await getLoan(this.props.match.params.id);
    this.state.data.loans.push(loan);
    this.setState({ loans });
    this.state.data.loan.paidInterest = loan.paidInterest;
    this.setState({ loan });
  }
  async populateInterestToDate() {
    let interestToDate;
    let lastInterestPeriod;
    const loanId = this.props.match.params.id;
    const { data } = await getInterest(loanId);
    interestToDate = data.interestDebt;
    lastInterestPeriod = data.lastPeriod.period;
    this.setState({ interestToDate });
    this.setState({ lastInterestPeriod });
  }

  async componentDidMount() {
    await this.populateLoan();
    await this.populateInterestToDate();
  }

  handleDelete = async loan => {
    try {
      await deleteLoan(loan._id);
      toast.info("Loan removed");
      window.location = "/loans";
    } catch (ex) {
      if (ex.response && ex.response.status === 404);
      toast.error("This loan has already been deleted.");
    }
  };

  handlePrincipalPaymentDelete = async payment => {
    if (window.confirm("Are you sure you want delete this item")) {
      const loans = this.state.loans;
      const loanId = loans[0]._id;
      const allPayments = loans[0].principalPayments;
      const originalloans = loans;
      const updatedPayments = allPayments.filter(pp => pp._id !== payment._id);
      loans[0].principalPayments = updatedPayments;
      this.setState({ loans });

      try {
        await deletePrincipalPayment(loanId, payment);
        toast.info("Payment removed");
        window.location.reload();
      } catch (ex) {
        if (ex.response && ex.response.status === 404);
        toast.error("This principal payment is already deleted.");

        this.setState({ loans: originalloans });
      }
    }
  };

  handleInterestPaymentDelete = async payment => {
    if (window.confirm("Are you sure you want delete this item")) {
      const loans = this.state.loans;
      const loanId = loans[0]._id;
      const allPayments = loans[0].interestPayments;
      const originalloans = loans;
      const updatedPayments = allPayments.filter(ip => ip._id !== payment._id);
      loans[0].interestPayments = updatedPayments;
      this.setState({ loans });

      try {
        await deleteInterestPayment(loanId, payment);
        toast.info("Interest payment removed");
        window.location.reload();
      } catch (ex) {
        if (ex.response && ex.response.status === 404);
        toast.error("This interest payment is already deleted.");

        this.setState({ loans: originalloans });
      }
    }
  };

  handleAdditionalPaymentDelete = async payment => {
    if (window.confirm("Are you sure you want delete this item")) {
      const loans = this.state.loans;
      const loanId = loans[0]._id;
      const allPayments = loans[0].additionalPayments;
      const originalloans = loans;
      const updatedPayments = allPayments.filter(pp => pp._id !== payment._id);
      loans[0].additionalPayments = updatedPayments;
      this.setState({ loans });

      try {
        await deleteAdditionalPayment(loanId, payment);
        toast.info("Payment removed");
        window.location.reload();
      } catch (ex) {
        if (ex.response && ex.response.status === 404);
        toast.error("This additional payment is already deleted.");

        this.setState({ loans: originalloans });
      }
    }
  };
  render() {
    const { loan, loans } = this.state.data;
    let paidInterest = loan.paidInterest;
    if (loan.paidInterest != "NA") {
      paidInterest = Math.round(loan.paidInterest * 100) / 100;
    }

    return (
      <div className="page">
        <p>Loan detail view</p>
        <div>
          <TableNoSort columns={this.columns} data={loans} />
        </div>
        <div>
          <Link
            className="btn btn-primary"
            to={`/loans/form/${loan._id}`}
            style={{ marginBottom: 20 }}
          >
            Change loan data
          </Link>
        </div>

        <div className="row">
          <div className="col-sm-2" style={{ width: 200 }}>
            <div className="card bg-light mb-3">
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  Interest Debt To Date
                </h6>
                <h5>{this.state.interestToDate} €</h5>
              </div>
            </div>
          </div>
          <div className="col-sm-2" style={{ width: 200 }}>
            <div className="card bg-light mb-3">
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  Last Interest Period
                </h6>
                <h5>{this.state.lastInterestPeriod}</h5>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="card bg-light mb-3" style={{ width: 200 }}>
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  Interest Paid To Date
                </h6>
                <h5>{paidInterest} €</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3" style={{ width: 200 }}>
            <div className="card bg-light mb-3" style={{ width: 250 }}>
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  Generate Monthly Interest
                </h6>
                <h5>
                  <InterestForm generateService={generateSaveInterest} isInterestService={isInterestForPeriodGenerated} loanId={loan._id} />
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3" style={{ width: 200 }}>
            <div className="card bg-light mb-3" style={{ width: 250 }}>
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  Make Additional Payment
                </h6>
                <h5>
                  <PaymentAdditionalForm service={makeAdditionalPayment} loanId={loan._id} />
                </h5>
              </div>
            </div>
          </div>
          <div className="col-sm-3" style={{ width: 200 }}>
            <div className="card bg-light mb-3" style={{ width: 250 }}>
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  Make Principal Payment
                </h6>
                <h5>
                  <PaymentPrincipalForm service={makePrincipalPayment} loanId={loan._id} />
                </h5>
              </div>
            </div>
          </div>
          <div className="col-sm-2" style={{ width: 200 }}>
            <div className="card bg-light mb-3" style={{ width: 250 }}>
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  Make Interest Payment
                </h6>
                <h5>
                  <PaymentInterestForm service={makeInterestPayment} loanId={loan._id} />
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="accordion" id="accordionExample">
            <div className="card">
              <div className="card-header" id="headingOne">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Additional payments table
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  {loans[0] && (
                    <PaymentAdditionalTable
                      loan={loans[0]}
                      onDelete={this.handleAdditionalPaymentDelete}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingTwo">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Principal payments table
                  </button>
                </h2>
              </div>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  {loans[0] && (
                    <PaymentPrincipalTable
                      loan={loans[0]}
                      onDelete={this.handlePrincipalPaymentDelete}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingThree">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Interest Payments table
                  </button>
                </h2>
              </div>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  {loans[0] && (
                    <PaymentInterestTable
                      loan={loans[0]}
                      onDelete={this.handleInterestPaymentDelete}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingThree">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Calculated Interest table
                  </button>
                </h2>
              </div>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  {loans[0] && (
                    <CalculatedInterestTable
                      monthInterests={loans[0].monthInterests}
                      onDelete={this.handleInterestPaymentDelete}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div style={{ width: 300, marginTop: 30, marginBottom: 30 }}>
            {loans[0] && (
              <PaymentScheduleForm
                makeSchedulePayment={makeSchedulePayment}
                saveScheduleToDb={saveScheduleToDb}
                service={daleteLoanSchedule} loanId={loan._id} loan={loans[0]} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default LoanDetails;

//
