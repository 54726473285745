import React, { Component } from "react";
import { Link } from "react-router-dom";
import Pagination from "../common/pagination";
import {
  getCosts,
  deleteCost
} from "../../services/costService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";
import CostTable from './costTable';

class Costs extends Component {
  state = {
    costs: [],
    currentPage: 1,
    pageSize: 10,
    searchQuery: "",
    selectedGenre: null,
    sortColumn: {
      path: "name",
      order: "asc"
    }
  };

  async componentDidMount() {
    const { data: costs } = await getCosts();
    this.setState({ costs });
  }

  handleDelete = async cost => {
    if (window.confirm("Are you sure you want delete this item")) {
      const originalCosts = this.state.costs;
      const costs = this.state.costs.filter(
        ls => ls._id !== cost._id
      );
      this.setState({ costs });
      try {
        await deleteCost(cost._id);
      } catch (ex) {
        if (ex.response && ex.response.status === 404);
        toast.error("This costs has already been deleted.");

        this.setState({ costs: originalCosts });
      }
    }
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleSearch = query => {
    this.setState({
      searchQuery: query,
      currentPage: 1
    });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      costs: allCosts
    } = this.state;

    let filtered = allCosts;

    if (searchQuery)
      filtered = allCosts.filter(c =>
        c.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const costs = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: costs };
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;

    const { totalCount, data: costs } = this.getPagedData();

    return (
      <div className="page">
      <div className="row">
        <div className="col">
          {user && (
            <Link
              className="btn btn-primary"
              to="/cost/new"
              style={{ marginBottom: 20 }}
            >
              New Cost
            </Link>
          )}
          <p>There is {totalCount} costs in the database</p>
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
          <CostTable
            costs={costs}
            sortColumn={sortColumn}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            onPageChange={this.handlePageChange}
            currentPage={currentPage}
          />
        </div>
      </div>
      </div>
    );
  }
}

export default Costs;
