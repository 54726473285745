import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import moment from "moment";
import { getPayers } from "../../services/payerServices";
import { getContracts } from "../../services/contractService";
import { getFactoring, saveFactoring } from "../../services/factoringService";
import { toast } from "react-toastify";

class FactoringForm extends Form {
  state = {
    data: {
      customerId: "",
      payerId: "",
      invoiceNumber: "",
      invoiceDate: moment(Date.now())
        .format("YYYY-MM-DD")
        .toString(),
      invoiceDueDate: '',
      invoiceAmount: '',
      rate: '',
      fee: '',
      payment: '',
      receivedDate: null,
      paymentDate: null,
      contractNumber: "",
      isActive: 1,
    },
    deadline: '',
    rate: 0,
    contracts: [],
    contract: {},
    customers: [],
    allPayers: [],
    contractPayers: [],
    contractNumber: "",
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    customerId: Joi.string()
      .required()
      .label("Customer"),
    payerId: Joi.string()
      .required()
      .label("Payer"),
    contractNumber: Joi.number()
      .required()
      .label("contractNumber"),
    invoiceNumber: Joi.string()
      .required()
      .label("Invoice Number"),
    invoiceDate: Joi.date()
      .required()
      .label("Invoice Date"),
    invoiceDueDate: Joi.date()
      .required()
      .label("Invoice Due Date"),
    invoiceAmount: Joi.number()
      .required()
      .min(0)
      .label("Invoice Amount"),
    rate: Joi.number()
      .required()
      .min(0)
      .label("Rate"),
    fee: Joi.number()
      .required()
      .min(0)
      .label("Fee"),
    payment: Joi.number()
      .required()
      .min(0)
      .label("Payment"),
    paymentDate: Joi.label("Payment Date"),
    receivedDate: Joi.label("Received Date"),
    isActive: Joi.number()
      .required()
  };

  async populateContracts() {
    const { data: contracts } = await getContracts();

    const activeContracts = contracts.filter(contract => contract.loanStatusName === 'Active');

    const customers = activeContracts.map(contract => {
      return {
        _id: contract.customerId,
        customerId: contract.customerId,
        name: contract.customerName,
      }
    });

    customers.sort((a, b) => (a.name > b.name) ? 1 : -1);

    this.setState({ contracts: activeContracts, customers });
  }

  async populatePayers() {
    const { data: allPayers } = await getPayers();
    this.setState({ allPayers });
  }

  async setPayers() {
    setTimeout(() => {
      const customerId = this.state.data.customerId;
      let contractPayers = [];
      let contractPayerIds;

      //Find customer contracts
      const filteredContract = this.state.contracts.filter(contract =>
        contract.customerId === customerId);

      //If contracts then set payers to state
      if (filteredContract[0] && filteredContract[0].terms.length > 0) {
        const contract = filteredContract[0];
        const contractNumber = contract.contractNumber;
        contractPayerIds = filteredContract[0].terms.map(term => {
          return term.payerId;
        })

        this.state.allPayers.forEach(payer => {
          let isPayerInContract = this.checkIfIdExistInContractTerms(contractPayerIds, payer._id)

          if (isPayerInContract) {
            contractPayers.push(payer)
          }
          this.setState({ contractNumber })
        });

        this.setState({ contractPayers, contract });
      } else {
        toast.error("Warning! No contract or contract terms found");
      }
    }, 1000);
  }

  checkIfIdExistInContractTerms(contractPayerIds, payerId) {
    return contractPayerIds.some(function (arrVal) {
      return payerId === arrVal;
    });
  }

  setRate() {
    setTimeout(() => {
      const payerId = this.state.data.payerId;
      if (this.state.contract.terms) {
        const filteredTerm = this.state.contract.terms.filter(term => term.payerId === payerId);
        if (filteredTerm.length > 0) {
          const data = { ...this.state.data };
          data.rate = filteredTerm[0].rate;
          data.contractNumber = this.state.contractNumber; // lisame ka contractNumber state
          const deadline = filteredTerm[0].deadline; // lisan deadline state, et saan hiljem kasutada seda kui määran kuupäeva
          this.setDueDate();
          this.setState({ data, deadline });
        }
      } else {
        const data = { ...this.state.data };
        data.rate = 0;
        toast.error("Warning. This payer is not in the contract. Rate set zero");
        this.setState({ data });
      }
      //Find interest rate from terms

    }, 1000)
  }

  setDueDate() {
    setTimeout(() => {
      const deadline = this.state.deadline;
      const data = { ...this.state.data };

      data.invoiceDueDate = moment(this.state.data.invoiceDate)
        .add(deadline, "days")
        .format("YYYY-MM-DD")
        .toString();
      this.setState({ data });
    })
  }

  setFee() {
    setTimeout(() => {
      const rate = this.state.data.rate;
      const invoiceAmount = this.state.data.invoiceAmount;

      let fee = 0;

      if (rate > 0 && invoiceAmount > 0) {
        fee = Math.round(invoiceAmount * rate) / 100;
      }
      if (fee > 0) {
        const data = { ...this.state.data };
        data.fee = fee;
        data.payment = Math.round((invoiceAmount - fee) * 100) / 100;
        this.setState({ data });
      } else {
        const data = { ...this.state.data };
        data.fee = 0;
        data.payment = invoiceAmount;
        toast.error("Warning. Fee set zero");
        this.setState({ data });
      }
    }, 1000)
  }



  async populateFactoring() {
    try {
      const factoringId = this.props.match.params.id;
      if (factoringId === "new") return;

      const { data: factoring } = await getFactoring(factoringId);
      this.setState({ data: this.mapToViewModel(factoring) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateContracts();
    await this.populatePayers();
    await this.populateFactoring();
  }

  mapToViewModel(factoring) {
    return {
      _id: factoring._id,
      customerId: factoring.customerId,
      payerId: factoring.payerId,
      invoiceNumber: factoring.invoiceNumber,
      invoiceDate: moment(factoring.invoiceDate)
        .format("YYYY-MM-DD")
        .toString(),
      invoiceDueDate: moment(factoring.invoiceDueDate)
        .format("YYYY-MM-DD")
        .toString(),
      invoiceAmount: factoring.invoiceAmount,
      rate: factoring.rate,
      fee: factoring.fee,
      payment: Math.round(factoring.payment * 100) / 100,
      paymentDate: moment(factoring.paymentDate)
        .format("YYYY-MM-DD")
        .toString(),
      receivedDate: moment(factoring.receivedDate)
        .format("YYYY-MM-DD")
        .toString(),
      contractNumber: factoring.contractNumber,
      isActive: factoring.isActive,
    };
  }

  calcFactoringFee = async (amount, rate) => {
    return Math.round(amount * rate) / 100;
  }

  doSubmit = async () => {
    await saveFactoring(this.state.data);
    this.props.history.goBack();
  };

  payerForNewOrExist = () => {
    if (this.props.match.params.id === 'new') {
      return this.state.contractPayers;
    } else {
      return this.state.allPayers;
    }
  }


  render() {
    return (
      <main>
        <div className={"form"}>
          <h1>Factoring Form</h1>
          <form onSubmit={this.handleSubmit}>
            {this.renderSelect("customerId", "Customer", this.state.customers, {
              onChange: (e) => {
                this.setPayers()
              }
            })}
            {this.renderSelect("payerId", "Payer", this.payerForNewOrExist(), {
              onChange: (e) => {
                this.setRate()
              }
            })}
            {this.renderInput("invoiceNumber", "Invoice Number")}
            {this.renderInput("invoiceDate", "Invoice Date", "date", {
              onChange: (e) => {
                this.setDueDate()
              }
            })}
            {this.renderInput("invoiceDueDate", "Invoice Due Date", "date")}
            {this.renderInput("invoiceAmount", "Invoice Amount", "number", {
              onChange: (e) => {
                this.setFee()
              }
            })}
            {this.renderInput("rate", "Rate", this.state.data.rate)}
            {this.renderInput("fee", "Fee", this.state.data.fee)}
            {this.renderInput("payment", "Payment", Math.round(this.state.data.payment * 100) / 100)}
            {this.renderInput("paymentDate", "Payment Date", "date")}
            {this.renderInput("receivedDate", "Received Date", "date")}
            {this.renderButton("Save")}
          </form>
        </div>
      </main>
    );
  }
}

export default FactoringForm;
