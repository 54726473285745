import axios from "axios";
import http from "./httpService";

const apiEndpoint = "/opentransactions";

export function postOpenTransactions(file, header) {
    const body = file;

    return axios.post(apiEndpoint, body, header)
}

export function initBankBalance(balance) {
    const body = {
        balance
    };

    return axios.post(`${apiEndpoint}/bankbalance`, body)
}

export function getBankBalance() {
    return http.get(`${apiEndpoint}/bankbalance`);
}

export function getOpenTransactions() {
    return http.get(apiEndpoint);
}

export function getLastUploadedTransactionDate() {
    return http.get(`${apiEndpoint}/lastdate`);
}

export function getTransactions() {
    return http.get(`${apiEndpoint}/transactions`);
}
export function getTransaction(transactionsId) {
    return http.get(`${apiEndpoint}/transactions/${transactionsId}`);
}
export function deleteTransaction(transactionsId) {
    return http.delete(`${apiEndpoint}/transactions/${transactionsId}`);
}

export function getOpenDeals(customerId, typeId, InOrOut) {
    return http.get(`${apiEndpoint}/deals/?customerId=${customerId}&typeId=${typeId}&inorout=${InOrOut}`);
}

export function getCustomersByType(typeId) {
    return http.get(`${apiEndpoint}/type/${typeId}`);
}

export function resetTransaction(transactionId) {
    return http.get(`${apiEndpoint}/reset/${transactionId}`);
}

export function getSuggestedTransactions() {
    return http.get(`${apiEndpoint}/tryToFindFactorings/`);
}

export function processTransaction(data, openTransactionId, readyDeals) {
    const body = {
        data,
        openTransactionId,
        readyDeals
    }
    return http.post(`${apiEndpoint}/process`, body)
}