
export const monthlyColumnsAndTotal = [
    {
        path: "name",
        label: "Name"
    }, 
    {
        path: "0",
        label: "Jan"
    }, 
    {
        path: "1",
        label: "Feb"
    }, 
    {
        path: "2",
        label: "Mar"
    }, 
    {
        path: "3",
        label: "Apr"
    }, 
    {
        path: "4",
        label: "May"
    }, 
    {
        path: "5",
        label: "Jun"
    }, 
    {
        path: "6",
        label: "Jul"
    }, 
    {
        path: "7",
        label: "Aug"
    }, 
    {
        path: "8",
        label: "Sep"
    }, 
    {
        path: "9",
        label: "Oct"
    }, 
    {
        path: "10",
        label: "Nov"
    }, 
    {
        path: "11",
        label: "Dec"
    }, 
    {
        path: "total",
        label: "Total"
    }, 
]