import React, { Component } from "react";
import TableNoSort from "../common/tableNoSort";
import auth from "../../services/authService";
import { Link } from "react-router-dom";

class contractTermTable extends Component {
    columns = [
        {
            path: "payerName",
            label: "Payer"
        },
        {
            path: "limit",
            label: "Limit"
        },
        {
            path: "rate",
            label: "Rate"
        },
        {
            path: "deadline",
            label: "Deadline"
        },
        {
            path: "name",
            label: "Edit term",
            content: term => (
              <Link to={{
                pathname:`/contractterm/${term._id}`,
                state: { contract:this.props.contract }

              }}>
               Edit
              </Link>
            )
          }
    ];
    deleteColumn = {
        key: "delete",
        content: term => (
            <button
                onClick={() => this.props.onDelete(term)}
                className="btn btn-danger btn-sm"
            >
                Delete
      </button>
        )
    };

    constructor() {
        super();
        const user = auth.getCurrentUser();
        if (user && user.isAdmin) this.columns.push(this.deleteColumn);
    }
    render() {
        const terms = (this.props.contract && this.props.contract.terms) || [];
       if (!terms) {
            return "No terms entered yet."
        } else {
            return <TableNoSort columns={this.columns} data={terms} />;
        }
    }
}

export default contractTermTable;
