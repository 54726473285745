import React, { Component } from "react";
import { Link } from "react-router-dom";
import Pagination from "../common/pagination";
import {
  getOthers,
  deleteOther,
} from "../../services/otherService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";
import OtherTable from './otherTable';

class Others extends Component {
  state = {
    others: [],
    currentPage: 1,
    pageSize: 10,
    searchQuery: "",
    selectedGenre: null,
    sortColumn: {
      path: "name",
      order: "asc"
    }
  };

  async componentDidMount() {
    const { data: others } = await getOthers();
    this.setState({ others });
  }

  handleDelete = async other => {
    const originalOthers = this.state.others;
    const others = this.state.others.filter(
      ls => ls._id !== other._id
    );
    this.setState({ others });
    try {
      await deleteOther(other._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404);
      toast.error("This others has already been deleted.");

      this.setState({ others: originalOthers });
    }
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleSearch = query => {
    this.setState({
      searchQuery: query,
      currentPage: 1
    });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      others: allOthers
    } = this.state;

    let filtered = allOthers;

    if (searchQuery)
      filtered = allOthers.filter(c =>
        c.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const others = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: others };
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;

    const { totalCount, data: others } = this.getPagedData();

    return (
      <div className="page">
      <div className="row">
        <div className="col">
          {user && (
            <Link
              className="btn btn-primary"
              to="/other/new"
              style={{ marginBottom: 20 }}
            >
              New Other
            </Link>
          )}
          <p>There is {totalCount} others in the database</p>
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
          <OtherTable
            others={others}
            sortColumn={sortColumn}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            onPageChange={this.handlePageChange}
            currentPage={currentPage}
          />
        </div>
      </div>
      </div>
    );
  }
}

export default Others;
