import React, { Component } from "react";
import TableNoSort from "../common/tableNoSort";

class TransactionDealsTable extends Component {
  columns = [
    { 
      key: 'participant',
      content: transaction => {
        const participant = transaction.payerName || transaction.customerName;
          return <div>{participant}</div>;
      },
      path: "customerName", label: "Participant" },
    { path: "contractNumber", label: "Contract" },
    { path: "balance", label: "Balance" },
    { path: "amount", label: "Amount" },
    { path: "additional", label: "Additional" },
    { path: "principal", label: "Principal" },
    { path: "interest", label: "Interest" },
    { path: "schedule", label: "Schedule" },
    
  ];
 
  render() {
    const deals = this.props.deals;
    return <TableNoSort columns={this.columns} data={deals} />;
  }
}

export default TransactionDealsTable;
