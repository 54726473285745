import React from "react";

const NotFound = () => {
  return (
    <div className="page">
      <h1>NotFound</h1>;
    </div>
  )
};

export default NotFound;
