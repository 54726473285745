import React, { Component } from "react";
import ListGroup from "../common/listGroup";
import { Link } from "react-router-dom";
import FactoringTable from "./factoringTable";
import Pagination from "../common/pagination";
import { deleteFactoring, getFactorings, setFactoringDate } from "../../services/factoringService";
import { getCustomers } from "../../services/customerService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";
import currencyFormating from "../../utils/currencyFormating";
import moment from "moment";
import Loader from '../common/loader';

class Factoring extends Component {
  state = {
    factorings: [],
    customers: [],
    loanTypes: [{ _id: "", name: "All factorings" },{_id: 1, name: 'Not paid'}, {_id:2, name: 'Not received'}],
    loanStatuses: [],

    currentPage: 1,
    pageSize: 100,
    searchQueryByCustomer: "",
    searchQueryByPayer: "",
    selectedLoanType: null,
    selectedLoanStatus: null,
    sortColumn: {
      path: "invoiceDate",
      order: "desc"
    },
  };

  async componentDidMount() {
    const { data: customers } = await getCustomers();
    const { data: factorings }  = await getFactorings();

    this.setState({ factorings, customers });
  }

  handleDelete = async factoring => {
    if (window.confirm("Are you sure you want delete this item")) {
      const originalFactorings = this.state.factorings;
      const factorings = this.state.factorings.filter(item => item._id !== factoring._id);
      this.setState({ factorings });
      try {
        await deleteFactoring(factoring._id);
        this.setState({ currentPage: 1 });
      } catch (ex) {
        if (ex.response && ex.response.status === 404);
        toast.error("This loan has already been deleted.");
        this.setState({ factorings: originalFactorings });
      }
    }
  };

  handleDateChange = async (factoring, date, dateName) => {
    const originalFactorings = this.state.factorings;
    let factorings = this.state.factorings;
    let updateFactoring = factorings.find(item => item._id === factoring._id);
    updateFactoring[dateName] = moment(date).toString();

    this.setState({ factorings });
    try {
      if(updateFactoring[dateName] === "Invalid date") {
        updateFactoring[dateName] = null;
      }
      await setFactoringDate(factoring._id, updateFactoring[dateName], dateName )
      toast.info("Date set");
    } catch (ex) {
      if (ex.response && ex.response.status === 404);
      toast.error("Cannot update date");
      this.setState({ factorings: originalFactorings });
    }
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleLoanTypeSelect = loanType => {
    this.setState({
      selectedLoanType: loanType,
      selectedLoanStatus: null,
      searchQueryByCustomer: "",
      searchQueryByPayer: "",
      currentPage: 1
    });
  };

  handleLoanStatusSelect = loanStatus => {
    this.setState({
      selectedLoanStatus: loanStatus,
      searchQueryByCustomer: "",
      searchQueryByPayer: "",
      electedLoanType: null,
      currentPage: 1
    });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleSearchByCustomer = query => {
    this.setState({
      searchQueryByCustomer: query,
      searchQueryByPayer: '',
      selectedLoanStatus: null,
      selectedLoanType: null,
      currentPage: 1
    });
  };
  handleSearchByPayer = query => {
    this.setState({
      searchQueryByCustomer: '',
      searchQueryByPayer: query,
      selectedLoanStatus: null,
      selectedLoanType: null,
      currentPage: 1
    });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedLoanType,
      searchQueryByCustomer,
      searchQueryByPayer,
      selectedLoanStatus,
      factorings: allFactorings
    } = this.state;

    let filtered = allFactorings;

    if (searchQueryByCustomer)
      filtered = allFactorings.filter(factoring =>
        factoring.customerName.toLowerCase().startsWith(searchQueryByCustomer.toLowerCase())
      );
    else if (selectedLoanType) {
        if (selectedLoanType.name === 'Not paid') {
          filtered = allFactorings.filter(
            factoring => factoring.paymentDate === null);
          } else if (selectedLoanType.name === 'Not received') {
            filtered = allFactorings.filter(
            factoring => factoring.receivedDate === null);
          }
    } else if (selectedLoanStatus && selectedLoanStatus._id) {
      filtered = allFactorings.filter(
        factoring => factoring.loanStatusId === selectedLoanStatus._id
      )
    } else if (searchQueryByPayer) {
      filtered = allFactorings.filter(factoring =>
        factoring.payerName.toLowerCase().startsWith(searchQueryByPayer.toLowerCase())
      );
    }
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const factorings = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: factorings };
  };

  loader() {
    return {

    }
  }

  render() {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQueryByCustomer,
      searchQueryByPayer,
      customers,
    } = this.state;
    const { user } = this.props;
    const { totalCount, data: filteredFactorings } = this.getPagedData();

    const notReceivedFacotrings = filteredFactorings.filter((factoring) => {
      return factoring.receivedDate === null;
    })
    const notPaidFacotrings = filteredFactorings.filter((factoring) => {
      return factoring.paymentDate === null;
    })

    const totalFactoringBalance = Math.round(notReceivedFacotrings.reduce(function (a, b) {
      return a + b.invoiceAmount
    }, 0) * 100) / 100;

    const totalNotPaidFacotringBalance = Math.round(notPaidFacotrings.reduce(function (a, b) {
      return a + b.payment
    }, 0) * 100) / 100;

    return (
      <div className="page">
         <div style={{display:'flex', flexFlow: 'row'}}>
            <div className="box">
              <p>
                {user && (
                  <Link className="btn btn-primary" to="/factoring/new">
                    Add Factoring
                  </Link>
                )}
              </p>
            </div>
            <div className="box">
              <div className="col-sm">
                <ListGroup
                  selectedItem={this.state.selectedLoanType}
                  items={this.state.loanTypes}
                  onItemSelect={this.handleLoanTypeSelect}
                />
              </div>
            </div>
              <div className="box">
                <p style={{ fontWeight: 'normal'}}>There is {totalCount} factorings in the database</p>
                <p style={{ fontWeight: 'normal'}}>Total not received factoring balance in page is {currencyFormating(totalFactoringBalance)}</p>
                <p style={{ fontWeight: 'normal'}}>Total not paid factoring balance in page is {currencyFormating(totalNotPaidFacotringBalance)}</p>
              </div>
          </div>
          <div style={{display:'flex', flexFlow: 'row'}}>
            <div className="box">
              <p>Search by Customer:</p>
              <SearchBox value={searchQueryByCustomer} onChange={this.handleSearchByCustomer} />
            </div>
            <div className="box">
              <p>Search by Payer:</p>
              <SearchBox value={searchQueryByPayer} onChange={this.handleSearchByPayer} />
            </div>
          </div>
          <FactoringTable
            factorings={filteredFactorings}
            customers={customers}
            sortColumn={sortColumn}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
            onDateChange={this.handleDateChange}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            onPageChange={this.handlePageChange}
            currentPage={currentPage}
          />

      <div className="row">
        <Loader data={this.state.factorings}/>
      </div>
    </div>
    );
  }
}

export default Factoring;
