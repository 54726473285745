import React, { Component } from "react";
import FactoringPartialTable from "./factoringPartialTable";
import FactoringPartialForm from "./factoringPartialForm";
import { getFactoring, deletePartialPayment } from "../../services/factoringService";
import { toast } from "react-toastify";

class FactoringPartial extends Component {
    state = {
        factoring: {},
    };

    async componentDidMount() {
        const { data: factoring } = await getFactoring(this.props.match.params.id);
        this.setState({ factoring });
    }


    handlePaymentDelete = async payment => {
        if (window.confirm("Are you sure you want delete this item")) {
            const factoring = this.state.factoring;
            const partialPayments = factoring.partialPayments;
            const originalFactoring = factoring;
            const updatedPartialPayments = partialPayments.filter(p => p._id !== payment._id);
            factoring.partialPayments = updatedPartialPayments;
            this.setState({ factoring });
            try {
                await deletePartialPayment(factoring._id, payment);
                toast.info("Payment removed");
                window.location.reload();
            } catch (ex) {
                if (ex.response && ex.response.status === 404);
                toast.error("This payment is already deleted.");
        
                this.setState({ originalFactoring });
            }
        }
        };

    render() {
        const { factoring } = this.state;
        const payments = factoring.partialPayments? factoring.partialPayments : [];

        return (
            <div className="page">
            <div className="row">
                <div className="col">
                    <FactoringPartialForm  factoringId={factoring._id}/>

                    <FactoringPartialTable
                        payments={payments} onDelete={this.handlePaymentDelete}
                    />
                </div>
            </div>
            </div>
        );
    }
}

export default FactoringPartial;
