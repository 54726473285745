import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getCost, saveCost } from "../../services/costService";
import { getCostTypes } from "../../services/costTypeService";

class CostForm extends Form {
  state = {
    data: {
      name: "",
      typeId: "",

    },
    costTypes: [],
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Cost"),
    typeId: Joi.string()
    .required()
    .label("Cost Type"),
  };

  async populateCostTypes() {
    const { data: costTypes } = await getCostTypes();
    this.setState({ costTypes });
  }

  async populateCost() {
    try {
      const costId = this.props.match.params.id;
      if (costId === "new") return;

      const { data: cost } = await getCost(costId);
      this.setState({ data: this.mapToViewModel(cost) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateCostTypes();
  }

  mapToViewModel(cost) {
    return {
      _id: cost._id,
      name: cost.name,
      typeId: cost.typeId,
    };
  }

  doSubmit = async () => {
    await saveCost(this.state.data);

    this.props.history.push("/cost");
  };

  render() {
    return (
      <div>
        <h1>Cost Form</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Cost Name")}
          {this.renderSelect("typeId", "Cost Type", this.state.costTypes)}
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default CostForm;
