import http from "./httpService";

const apiEndpoint = "/loanstatuses";

function loanStatusUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getLoanStatuses() {
  return http.get(apiEndpoint);
}

export function getLoanStatus(id) {
  return http.get(loanStatusUrl(id));
}

export function saveLoanStatus(loanStatus) {
  if (loanStatus._id) {
    const body = { ...loanStatus };
    delete body._id;
    return http.put(loanStatusUrl(loanStatus._id), body);
  }
  return http.post(apiEndpoint, loanStatus);
}

export function deleteLoanStatus(loanStatusId) {
  return http.delete(loanStatusUrl(loanStatusId));
}
