import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Table from "../common/table";
import auth from "../../services/authService";
import DatePicker from "react-date-picker";
import FileSaver from "file-saver";
import { getPDF } from "../../services/downloadPDF";
import { getXML } from "../../services/downloadXML";
import { getCSV } from "../../services/downloadCSV";
import { toast } from "react-toastify";

class FactoringTable extends Component {
  constructor() {
    super();
    this.state = {
      selectedFactoringIds: [],
    }
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) {
      this.columns.push(this.deleteColumn);
    };
  }
  columns = [
    {
      path: "customerName",
      label: "Customer"
    },
    {
      path: "invoiceNumber",
      label: "Number",
      content: factoring => (
        <Link to={`/factoring/${factoring._id}`}>{factoring.invoiceNumber}</Link>
      )
    },
    {
      path: "invoiceDate",
      label: "Date",
      content: factoring =>
        moment(factoring.invoiceDate)
          .format("DD-MM-YY")
          .toString()
    },
    {
      path: "invoiceAmount",
      label: "Amount"
    },
    {
      path: "payerName",
      label: "Payer"
    },
    {
      path: "payment",
      label: "Payment"
    },
    {
      path: "paymentDate",
      label: "Payment Date",
      content: factoring => {
        let currentDate = factoring.paymentDate;
        if (currentDate) {
          currentDate = new Date(currentDate);
        }
        return <DatePicker value={currentDate} onChange={date => this.props.onDateChange(factoring, date, 'paymentDate')} />
      }
    },
    {
      path: "invoiceDueDate",
      label: "Due Date",
      content: factoring =>
        moment(factoring.invoiceDueDate)
          .format("DD-MM-YY")
          .toString()
    },
    {
      path: "receivedDate",
      label: "Received Date",
      content: factoring => {
        let currentDate = factoring.receivedDate;
        if (currentDate) {
          currentDate = new Date(currentDate);
        }
        return <DatePicker value={currentDate} onChange={date => this.props.onDateChange(factoring, date, 'receivedDate')} />
      }
    },

    { path: "rate", label: "Rate" },
    { path: "fee", label: "Fee" },
    {
      key: "partialPayment",
      label: "Partial Payment",
      content: factoring => (
        <Link to={`/factoring/partial/${factoring._id}`}>Payments</Link>
      )
    },
    {
      label: "Select",
      key: "select",
      content: factoring => (
        <input
          type="checkbox"
          onChange={() => {
            this.handleFactoringSelection(factoring._id);
          }}
        >
        </input>
      )
    }
  ];
  deleteColumn = {
    label: "Delete",
    key: "delete",
    content: factoring => (
      <button
        onClick={() => this.props.onDelete(factoring)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  handleFactoringSelection = (factoringId) => {
    let selectedFactoringIds = [];
    selectedFactoringIds = this.state.selectedFactoringIds;
    const isInArray = selectedFactoringIds.includes(factoringId);

    if (isInArray) {
      selectedFactoringIds = selectedFactoringIds.filter((id) => {
        return id !== factoringId;
      })
    } else {
      selectedFactoringIds.push(factoringId)
    }

    this.setState({ selectedFactoringIds });
  }

  b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'application/pdf' });
  }

  handleDownloadPDF = async () => {
    const factoringIds = this.state.selectedFactoringIds;
    if (factoringIds.length > 0) {
      let { customerName } = this.props.factorings.find((factoring) => {
        return factoring._id === factoringIds[0];
      })
      customerName = customerName.replace(' ', '_')

      try {
        const pdfDoc = await getPDF(factoringIds);;
        const blob = this.b64toBlob(pdfDoc.data);
        FileSaver.saveAs(blob, `${customerName}_${moment().format('DD.MM.YYYY')}_Raport.pdf`);
        window.location.reload();
      } catch (ex) {
        if (ex.response && ex.response.status === 500);
        toast.error("Cannot download pdf.");
      }
    } else {
      toast.warn('No factorings selected');
    }
  };

  handleDownloadCSV = async () => {
    const selectedFactorings = this.state.selectedFactoringIds;
    const factoringIds = selectedFactorings.length > 0 ? selectedFactorings : this.props.factorings.map(f => f._id);

    try {
      const csvDoc = await getCSV(factoringIds);
      console.log('csvDoc', csvDoc);
      //const blob = this.b64toBlob(csvDoc.data);
      FileSaver.saveAs(new Blob([csvDoc.data], { type: 'application/pdf' }), `Raport ${moment().format('DD.MM.YYYY')}.csv`);
      window.location.reload();
    } catch (ex) {
      console.log(ex)
      if (ex.response && ex.response.status === 500);
      toast.error("Cannot download csv.");
    }
  };

  doesAllCustomerHaveIBAN(factoringIds) {
    const customers = this.props.customers;
    for (let i = 0; i < factoringIds.length; i++) {
      const factoring = this.props.factorings.find(factoring => factoring._id === factoringIds[i])
      const customer = customers.find(customer => customer._id === factoring.customerId);

      if (!customer.IBAN) {
        toast.error(`${customer.name} IBAN is missing.`);
        return false
      }
    }
    return true;
  }

  handleDownloadXML = async () => {
    const factoringIds = this.state.selectedFactoringIds;
    if (factoringIds.length > 0) {
      if (!this.doesAllCustomerHaveIBAN(factoringIds)) {
        return;
      }

      try {
        const xmlDoc = await getXML(factoringIds);
        console.log('xmlDoc', xmlDoc);
        const blob = new Blob([xmlDoc.data], { type: "xml;charset=utf-8" });
        FileSaver.saveAs(blob, `Payment_${moment().format('DD.MM.YYYY')}.xml`);
        window.location.reload();
      } catch (ex) {
        if (ex.response && ex.response.status === 500);
        console.log('error', ex);
        toast.error("Cannot download xml.");
      }
    } else {
      toast.warn('No factorings selected');
    }
  };

  render() {
    const { factorings, onSort, sortColumn } = this.props;

    return (
      <div>
        <div className='boxTransparentRight'>
          <button
            disabled={this.state.selectedFactoringIds.length < 1}
            onClick={() => this.handleDownloadPDF()}
            className="btn btn-primary btn-sm"
          >
            PDF
          </button>
          <button
            disabled={this.state.selectedFactoringIds.length < 1}
            onClick={() => this.handleDownloadXML()}
            className="btn btn-secondary btn-sm"
          >
            XML
          </button>
          <button
            onClick={() => this.handleDownloadCSV()}
            className="btn btn-secondary btn-sm"
          >
            CSV
          </button>
        </div>

        <Table
          columns={this.columns}
          data={factorings}
          sortColumn={sortColumn}
          onSort={onSort}
        />
      </div>
    );
  }
}

export default FactoringTable;
