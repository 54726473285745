import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getIncomeType, saveIncomeType } from "../../services/incomeTypeService";

class IncomeTypeForm extends Form {
  state = {
    data: {
      name: ""
    },
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("IncomeType")
  };

  async populateIncomeType() {
    try {
      const incomeTypeId = this.props.match.params.id;
      if (incomeTypeId === "new") return;

      const { data: incomeType } = await getIncomeType(incomeTypeId);
      this.setState({ data: this.mapToViewModel(incomeType) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateIncomeType();
  }

  mapToViewModel(incomeType) {
    return {
      _id: incomeType._id,
      name: incomeType.name
    };
  }

  doSubmit = async () => {
    await saveIncomeType(this.state.data);

    this.props.history.push("/incomeType");
  };

  render() {
    return (
      <div>
        <h1>IncomeType Form</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "IncomeType Name")}
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default IncomeTypeForm;
