import React, { Component } from "react";
import { Link } from "react-router-dom";
import ContractsTables from "./contractsTable";
import ListGroup from "../common/listGroup";
import Pagination from "../common/pagination";
import { getContracts, deleteContract } from "../../services/contractService";
import { getCustomers, setFactoringContract } from "../../services/customerService";
import { getLoanStatuses } from "../../services/loanStatusService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";
import Loader from '../common/loader';

class Contracts extends Component {
    state = {
        contracts: [],
        customers: [],
        loanStatuses: [],
        currentPage: 1,
        pageSize: 100,
        searchQuery: "",
        selectedLoanStatus: "5c6b179d5e21eb0016864724",
        sortColumn: {
            path: "date",
            order: "asc"
        }
    };

    async componentDidMount() {
        const { data: loanStatusesData } = await getLoanStatuses();
        const loanStatuses = [{ _id: "", name: "All Factoring Statuses" }, ...loanStatusesData];
        const { data: customers } = await getCustomers();
        const { data: contracts } = await getContracts();

        this.setState({ contracts, customers, loanStatuses });
    }

    handleDelete = async contract => {
        if (window.confirm("Are you sure you want delete this item")) {
            const originalContracts = this.state.contracts;
            const contracts = this.state.contracts.filter(item => item._id !== contract._id);
            this.setState({ contracts });
            try {
                await deleteContract(contract._id);
                await setFactoringContract(contract.customerId, false);
                this.setState({ currentPage: 1 });
            } catch (ex) {
                if (ex.response && ex.response.status === 404);
                toast.error("This contract has already been deleted.");
                this.setState({ contracts: originalContracts });
            }
        }
    };

    handlePageChange = page => {
        this.setState({ currentPage: page });
    };

    handleLoanStatusSelect = loanStatus => {
        this.setState({
            selectedLoanStatus: loanStatus,
            searchQuery: "",
            currentPage: 1
        });
    };

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    };

    handleSearch = query => {
        this.setState({
            searchQuery: query,
            selectedLoanStatus: null,
            currentPage: 1
        });
    };

    getPagedData = () => {
        const {
            pageSize,
            currentPage,
            sortColumn,
            searchQuery,
            selectedLoanStatus,
            contracts: allContracts
        } = this.state;

        let filtered = allContracts;

        if (searchQuery)
            filtered = allContracts.filter(contract =>
                contract.customerName.toLowerCase().startsWith(searchQuery.toLowerCase())
            );
        else if (selectedLoanStatus && selectedLoanStatus._id)
            filtered = allContracts.filter(
                contract => contract.loanStatusId === selectedLoanStatus._id
            );

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const contracts = paginate(sorted, currentPage, pageSize);

        return { totalCount: filtered.length, data: contracts };
    };

    render() {
        const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
        const { user } = this.props;
        const { totalCount, data: contracts } = this.getPagedData();

        return (
            <div className="page">
                <div style={{ display: 'flex', flexFlow: 'row' }}>
                    <div className="box">
                        {user && (
                            <Link className="btn btn-primary" to="/contracts/form/new">
                                New Factoring Contract
                            </Link>
                        )}
                    </div>
                    <div className="box">
                        <ListGroup
                            selectedItem={this.state.selectedLoanStatus}
                            items={this.state.loanStatuses}
                            onItemSelect={this.handleLoanStatusSelect}
                        />
                    </div>
                    <div className="box">
                        <p>There is {totalCount} factoring contracts in the page</p>
                    </div>
                </div>
                <div style={{ display: 'flex', flexFlow: 'row' }}>
                    <SearchBox value={searchQuery} onChange={this.handleSearch} />
                </div>
                <ContractsTables
                    contracts={contracts}
                    sortColumn={sortColumn}
                    onDelete={this.handleDelete}
                    onSort={this.handleSort}
                />
                <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                />
                <div className="row">
                    <Loader data={this.state.contracts} />
                </div>
            </div>
        );
    }
}

export default Contracts;
