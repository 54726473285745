import React from "react";

const Loader = props => {
    if (props.data && props.data.length === 0) {
        return (
            <div>
                <div className="spinner-border m-5" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            </div>
        );
    } else {
       return ( <div> </div> )
    }
};
export default Loader;
