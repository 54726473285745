import React, { Component} from "react";
import { getTransactions, deleteTransaction, resetTransaction } from '../../services/bankService';
import { toast } from "react-toastify";
import TransactionTable from './transactionTable';
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { Link } from "react-router-dom";


class  Transactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactions: [],
            currentPage: 1,
            pageSize: 100,
            searchQuery: "",
            sortColumn: {
                path: "name",
                order: "asc"
            }
        }
    }

    async componentDidMount() {
        const { data: transactions } = await getTransactions();
        this.setState({ transactions });
    }

    handleDelete = async transaction => {
        if (window.confirm("Are you sure you want delete this item")) {
            const originalTransaction = this.state.transaction;
            const transactions = this.state.transactions.filter(m => m._id !== transaction._id);
            this.setState({ transactions });
            try {
                await deleteTransaction(transaction._id);
            } catch (ex) {
                if (ex.response && ex.response.status === 404);
                toast.error("This transaction has already been deleted.");

                this.setState({ transaction: originalTransaction });
            }
        }
    };

    handleReset = async transaction => {
        if (window.confirm("Are you sure you want reset this item")) {
            const originalTransaction = this.state.transaction;
            const transactions = this.state.transactions.filter(m => m._id !== transaction._id);
            this.setState({ transactions });
            try {
                await resetTransaction(transaction._id);
            } catch (ex) {
                if (ex.response && ex.response.status === 404);
                toast.error("This transaction has already been reseted.");

                this.setState({ transaction: originalTransaction });
            }
        }
    };

    handlePageChange = page => {
        this.setState({ currentPage: page });
    };

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    };

    handleSearch = query => {
        this.setState({
            searchQuery: query,
            currentPage: 1
        });
    };

    getPagedData = () => {
        const {
            pageSize,
            currentPage,
            sortColumn,
            searchQuery,
            transactions: allTransactions
        } = this.state;

        let filtered = allTransactions;

        if (searchQuery)
            filtered = allTransactions.filter(c =>
                c.name.toLowerCase().startsWith(searchQuery.toLowerCase())
            );

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const transactions = paginate(sorted, currentPage, pageSize);

        return { totalCount: filtered.length, data: transactions };
    };

    render() {
        const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
        const { totalCount, data: transactions } = this.getPagedData();
        return (
            <div className="container page">
                <div>
                <div><Link to={'/opentransactions/'}>Open Transactions</Link></div>
                <p>There is {totalCount} transactions in the database</p>
                <SearchBox value={searchQuery} onChange={this.handleSearch} />
            
                <TransactionTable
                    transactions={transactions}
                    sortColumn={sortColumn}
                    onDelete={this.handleDelete}
                    onReset={this.handleReset}
                    onSort={this.handleSort}
                />
                <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                />
                </div>
            </div>
 
        )
    }

}

export default Transactions;