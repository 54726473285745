import http from "./httpService";

const apiEndpoint = "/payers";

function payerUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export function getPayers() {
    return http.get(apiEndpoint);
}

export function getPayer(payerId) {
    return http.get(payerUrl(payerId));
}

export function savePayer(payer) {
    if (payer._id) {
        const body = { ...payer };
        delete body._id;
        return http.put(payerUrl(payer._id), body);
    }
    return http.post(apiEndpoint, payer);
}

export function deletePayer(payerId) {
    return http.delete(payerUrl(payerId));
}
