import React, { Component } from "react";
import { Link } from "react-router-dom";
import Pagination from "../common/pagination";
import {
  getIncomes,
  deleteIncome
} from "../../services/incomeService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";
import IncomeTable from './incomeTable';

class Incomes extends Component {
  state = {
    incomes: [],
    currentPage: 1,
    pageSize: 10,
    searchQuery: "",
    selectedGenre: null,
    sortColumn: {
      path: "name",
      order: "asc"
    }
  };

  async componentDidMount() {
    const { data: incomes } = await getIncomes();
    this.setState({ incomes });
  }

  handleDelete = async income => {
    if (window.confirm("Are you sure you want delete this item")) {
      const originalIncomes = this.state.incomes;
      const incomes = this.state.incomes.filter(
        ls => ls._id !== income._id
      );
      this.setState({ incomes });
      try {
        await deleteIncome(income._id);
      } catch (ex) {
        if (ex.response && ex.response.status === 404);
        toast.error("This incomes has already been deleted.");

        this.setState({ incomes: originalIncomes });
      }
    }
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleSearch = query => {
    this.setState({
      searchQuery: query,
      currentPage: 1
    });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      incomes: allIncomes
    } = this.state;

    let filtered = allIncomes;

    if (searchQuery)
      filtered = allIncomes.filter(c =>
        c.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const incomes = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: incomes };
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;

    const { totalCount, data: incomes } = this.getPagedData();
    
    return (
      <div className="page">
      <div className="row">
        <div className="col">
          {user && (
            <Link
              className="btn btn-primary"
              to="/income/new"
              style={{ marginBottom: 20 }}
            >
              New Income
            </Link>
          )}
          <p>There is {totalCount} Incomes in the database</p>
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
          <IncomeTable
            incomes={incomes}
            sortColumn={sortColumn}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            onPageChange={this.handlePageChange}
            currentPage={currentPage}
          />
        </div>
      </div>
      </div>
    );
  }
}

export default Incomes;
