import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import moment from "moment";
import { getContract, saveContract } from "../../services/contractService";
import { getLoanStatuses } from "../../services/loanStatusService";
import { getCustomers, setFactoringContract } from "../../services/customerService";

class ContractForm extends Form {
    state = {
        data: {
            customerId: "",
            date: moment(Date.now())
                .format("YYYY-MM-DD")
                .toString(),
            contractNumber: 1000,
            limit: 15000,
            loanStatusId: "5c6b179d5e21eb0016864724"
        },
        customers: [],
        loanStatuses: [],
        errors: {}
    };

    schema = {
        _id: Joi.string(),
        customerId: Joi.string()
            .required()
            .label("Customer"),
        date: Joi.date()
            .required()
            .label("Date"),
        contractNumber: Joi.number()
            .required()
            .min(0)
            .label("Contract Number"),
        limit: Joi.number()
            .required()
            .min(0)
            .max(200000)
            .label("Limit"),
        loanStatusId: Joi.string()
            .label("Factoring Status")
            .required()
    };

    async populateLoanStatuses() {
        const { data: loanStatuses } = await getLoanStatuses();
        this.setState({ loanStatuses });
    }

    async populateCustomers() {
        const { data: customers } = await getCustomers();
        this.setState({ customers });
    }

    async populateContract() {
        try {
            const contractId = this.props.match.params.id;
            if (contractId === "new") return;

            const { data: contract } = await getContract(contractId);
            this.setState({ data: this.mapToViewModel(contract) });
        } catch (ex) {
            if (ex.response && ex.response.status === 404)
                this.props.history.replace("/not-found");
        }
    }

    async componentDidMount() {
        await this.populateLoanStatuses();
        await this.populateContract();
        await this.populateCustomers();
    }

    mapToViewModel(contract) {
        return {
            _id: contract._id,
            customerId: contract.customerId,
            date: moment(contract.date)
                .format("YYYY-MM-DD")
                .toString(),
            contractNumber: contract.contractNumber,
            limit: contract.limit,
            loanStatusId: contract.loanStatusId
        };
    }

    doSubmit = async () => {
        await saveContract(this.state.data);
        await setFactoringContract(this.state.data.customerId, true);
        this.props.history.goBack();
    };

    render() {
        return (
            <div>
                <h1>Factoring Contract Form</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.renderSelect("customerId", "Customer", this.state.customers)}
                    {this.renderInput("date", "Date", "date")}
                    {this.renderInput("contractNumber", "Contract Number", "number")}
                    {this.renderInput("limit", "Limit", "number")}
                    {this.renderSelect(
                        "loanStatusId",
                        "Factoring Status",
                        this.state.loanStatuses
                    )}
                    {this.renderButton("Save")}
                </form>
            </div>
        );
    }
}

export default ContractForm;
