import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoansTables from "./loansTable";
import ListGroup from "../common/listGroup";
import Pagination from "../common/pagination";
import { getLoans, deleteLoan } from "../../services/loanService";
import { getCustomers } from "../../services/customerService";
import { getLoanStatuses } from "../../services/loanStatusService";
import { getLoanTypes } from "../../services/loanTypeService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";
import currencyFormating from "../../utils/currencyFormating";
import Loader from '../common/loader';

class Loans extends Component {
  state = {
    loans: [],
    customers: [],
    loanTypes: [],
    loanStatuses: [],
    currentPage: 1,
    pageSize: 200,
    searchQuery: "",
    selectedLoanType: null,
    selectedLoanStatus: { _id: "5c6b179d5e21eb0016864724" },
    sortColumn: {
      path: "date",
      order: "asc"
    }
  };

  async componentDidMount() {
    const { data: loanTypesData } = await getLoanTypes();
    const { data: loanStatusesData } = await getLoanStatuses();
    const loanTypes = [{ _id: "", name: "All Loan Types" }, ...loanTypesData];
    const loanStatuses = [{ _id: "", name: "All Loan Statuses" }, ...loanStatusesData];
    const { data: customers } = await getCustomers();
    const { data: loans } = await getLoans();

    this.setState({ loans, customers, loanStatuses, loanTypes });
  }

  handleDelete = async loan => {
    if (window.confirm("Are you sure you want delete this item")) {
      const originalLoans = this.state.loans;
      const loans = this.state.loans.filter(item => item._id !== loan._id);
      this.setState({ loans });
      try {
        await deleteLoan(loan._id);
        this.setState({ currentPage: 1 });
      } catch (ex) {
        if (ex.response && ex.response.status === 404);
        toast.error("This loan has already been deleted.");
        this.setState({ loans: originalLoans });
      }
    }
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleLoanTypeSelect = loanType => {
    this.setState({
      selectedLoanType: loanType,
      selectedLoanStatus: null,
      searchQuery: "",
      currentPage: 1
    });
  };

  handleLoanStatusSelect = loanStatus => {
    this.setState({
      selectedLoanStatus: loanStatus,
      searchQuery: "",
      electedLoanType: null,
      currentPage: 1
    });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleSearch = query => {
    this.setState({
      searchQuery: query,
      selectedLoanStatus: null,
      selectedLoanType: null,
      currentPage: 1
    });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedLoanType,
      searchQuery,
      selectedLoanStatus,
      loans: allLoans
    } = this.state;

    let filtered = allLoans;

    if (searchQuery)
      filtered = allLoans.filter(loan =>
        loan.customerName.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    else if (selectedLoanType && selectedLoanType._id)
      filtered = allLoans.filter(
        loan => loan.loanTypeId === selectedLoanType._id
      );
    else if (selectedLoanStatus && selectedLoanStatus._id)
      filtered = allLoans.filter(
        loan => loan.loanStatusId === selectedLoanStatus._id
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const loans = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: loans };
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;
    const { totalCount, data: loans } = this.getPagedData();

    const totalLoansBalance = Math.round(loans.reduce(function (a, b) {
      return a + b.balance
    }, 0) * 100) / 100;

    return (
      <div className="page">
        <div style={{ display: "flex", flexFlow: 'row' }}>
          <div className="box">
            <p>
              {user && (
                <Link className="btn btn-primary" to="/loans/form/new">
                  New Loan
                </Link>
              )}
            </p>
          </div>
          <div className="box">
            <ListGroup
              selectedItem={this.state.selectedLoanStatus}
              items={this.state.loanStatuses}
              onItemSelect={this.handleLoanStatusSelect}
            />
          </div>
          <div className="box">
            <p>There is {totalCount} loans in the page</p>
            <p>Total loans balance is {currencyFormating(totalLoansBalance)}</p>
          </div>
        </div>
        <div style={{ display: "flex", flexFlow: 'row' }}>
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
        </div>
        <div className="row">
          <div className="col">
            <LoansTables
              loans={loans}
              sortColumn={sortColumn}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
            />
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
              currentPage={currentPage}
            />
          </div>
        </div>

        <div className="row">
          <Loader data={this.state.loans} />
        </div>
      </div>
    );
  }
}

export default Loans;
