import http from "./httpService";

const apiEndpoint = "/other";

function otherUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getOthers() {
  return http.get(apiEndpoint);
}

export function getOther(id) {
  return http.get(otherUrl(id));
}

export function saveOther(other) {
  if (other._id) {
    const body = { ...other };
    delete body._id;
    return http.put(otherUrl(other._id), body);
  }
  return http.post(apiEndpoint, other);
}

export function deleteOther(otherId) {
  return http.delete(otherUrl(otherId));
}
