import React, { Component } from "react";
import TableNoSort from "../common/tableNoSort";

class AccountingTable extends Component {
  columns = [
    {
      path: "date",
      label: "Date"
    },
    {
      path: "Cat",
      label: 'Kategooria',
      key: "cat",
      content: account => {
        if (account.costId) {
          return 'Kulu';
        } else if (account.incomeId) {
          return 'Tulu';
        } else {
          return  'Muud';
        }
      }
    },
    {
      path: "type",
      label: 'Type',
      key: "type",
      content: account => {
        if (account.costId) {
          const cost = this.props.allAccounts.costs.find( acc => acc._id === account.costId);
          return cost.typeName;
        } else if (account.incomeId) {
          const income = this.props.allAccounts.incomes.find( acc => acc._id === account.incomeId);
          return income.typeName;
        }
      }
    },
    {
      path: "Id",
      label: 'Liik',
      key: "id",
      content: account => {
        if (account.costId) {
          const cost = this.props.allAccounts.costs.find( acc => acc._id === account.costId);
          return cost.name;
        } else if (account.incomeId) {
          const income = this.props.allAccounts.incomes.find( acc => acc._id === account.incomeId);
          return income.name;
        } else if (account.otherId) {
          const other = this.props.allAccounts.others.find( acc => acc._id === account.otherId);
          return other.name;
        }
      }
    },
    {
      path: "amount",
      label: "Amount",
      key: 'amount',
      content: account => {
        if (account.transaction === "DBIT") {
          return account.amount * -1;
        } else {
          return account.amount;
        }
      }
    },
  ];

  render() {
    //Shows loans that are active and contract date is same or lower than current date

    return <TableNoSort columns={this.columns} data={this.props.accounts} />;
  }
}

export default AccountingTable;
