import React, { Component } from "react";
import TableNoSort from "../common/tableNoSort";
import auth from "../../services/authService";
import moment from "moment";

class paymentPrincipalTable extends Component {
  columns = [
    {
      path: "date",
      label: "Date",
      content: payment =>
        moment(payment.date)
          .format("DD-MM-YY")
          .toString()
    },
    {
      path: "amount",
      label: "Amount"
    },
    {
      path: "comment",
      label: "Comment"
    }
  ];
  deleteColumn = {
    key: "delete",
    content: payment => (
      <button
        onClick={() => this.props.onDelete(payment)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }
  render() {
    const { principalPayments } = this.props.loan;

    return <TableNoSort columns={this.columns} data={principalPayments} />;
  }
}

export default paymentPrincipalTable;
