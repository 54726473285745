import React, { Component } from "react";
import TableNoSort from "../common/tableNoSort";
import moment from "moment";

class calculatedInterestTable extends Component {
  columns = [
    {
      path: "period",
      label: "Period",
    },
    {
      path: "start",
      label: "Start (Excluded)",
      content: interest =>
      moment(interest.start)
          .format("DD-MM-YY")
          .toString()
    },
    {
        path: "end",
        label: "End (Included)",
        content: interest =>
        moment(interest.end)
            .format("DD-MM-YY")
            .toString()
    },
    {
      path: "amount",
      label: "Amount"
  },
    {
        path: "days",
        label: "Days"
    },
    {
        path: "dayrate",
        label: "DayRate"
    },
    {
        path: "dayInterest",
        label: "Day Interest"
    },
    {
        path: "totalInterest",
        label: "Total Interest"
    },
  ];

  render() {
    const monthInterests = this.props.monthInterests;
    let tableData = [];

    for(let i = 0; i < monthInterests.length; i++){
        let monthInterest = monthInterests[i];
        let row = {
            period: monthInterest.period,
            start:'',
            end:'',
            amount:'',
            days:'',
            dayrate: '',
            dayInterest: '',
            totalInterest: ''
        }

        for(let j = 0; j < monthInterest.monthInterestTable.length; j++) {
            tableData.push({ ...row, ...monthInterest.monthInterestTable[j]});
        }
    }

    return <TableNoSort columns={this.columns} data={tableData} />;
  }
}

export default calculatedInterestTable;
