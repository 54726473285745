import React, { Component } from "react";
import { Link } from "react-router-dom";
import CustomerTables from "./customerTable";
import Pagination from "../common/pagination";
import { getCustomers, deleteCustomer } from "../../services/customerService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";
import Loader from '../common/loader';

class Customers extends Component {
  state = {
    customers: [],
    loansType: [],
    currentPage: 1,
    pageSize: 100,
    searchQuery: "",
    selectedGenre: null,
    sortColumn: {
      path: "name",
      order: "asc"
    }
  };

  async componentDidMount() {
    const { data: customers } = await getCustomers();
    this.setState({ customers });
  }

  handleDelete = async customer => {
    const originalCustomers = this.state.customers;
    const customers = this.state.customers.filter(m => m._id !== customer._id);
    this.setState({ customers });
    try {
      await deleteCustomer(customer._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404);
      toast.error("This customer has already been deleted.");

      this.setState({ customers: originalCustomers });
    }
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleSearch = query => {
    this.setState({
      searchQuery: query,
      currentPage: 1
    });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      customers: allCustomers
    } = this.state;

    let filtered = allCustomers;

    if (searchQuery)
      filtered = allCustomers.filter(c =>
        c.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const customers = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: customers };
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;

    const { totalCount, data: customers } = this.getPagedData();

    return (
      <div className="page">
        <div className={"page"}>
          <div className="row">
            <div className="col">
              {user && (
                <Link
                  className="btn btn-primary"
                  to="/customers/new"
                  style={{ marginBottom: 20 }}
                >
                  New Customer
                </Link>
              )}
              <p>There are {totalCount} customers in the database</p>
              <SearchBox value={searchQuery} onChange={this.handleSearch} />
              <CustomerTables
                customers={customers}
                sortColumn={sortColumn}
                onDelete={this.handleDelete}
                onSort={this.handleSort}
              />
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                onPageChange={this.handlePageChange}
                currentPage={currentPage}
              />
            </div>
          </div>
          <div className="row">
            <Loader data={this.state.customers} />
          </div>
        </div>
      </div>
    );
  }
}

export default Customers;
