import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import moment from "moment";
import { getLoan, saveLoan } from "../../services/loanService";
import { getLoanTypes } from "../../services/loanTypeService";
import { getLoanStatuses } from "../../services/loanStatusService";
import { getCustomers } from "../../services/customerService";

class LoanForm extends Form {
  state = {
    data: {
      customerId: "",
      date: moment(Date.now())
        .format("YYYY-MM-DD")
        .toString(),
      contractNumber: 1000,
      dueDate: moment(Date.now())
        .format("YYYY-MM-DD")
        .toString(),
      rate: 33,
      amount: 1000,
      balance: 0,
      loanTypeId: "5c6b177d5e21eb0016864722",
      loanStatusId: "5c6b179d5e21eb0016864724",
      paymentDate: 15
    },
    customers: [],
    loanTypes: [],
    loanStatuses: [],
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    customerId: Joi.string()
      .required()
      .label("Customer"),
    date: Joi.date()
      .required()
      .label("Date"),
    contractNumber: Joi.number()
      .required()
      .min(0)
      .label("Contract Number"),
    dueDate: Joi.date()
      .required()
      .label("Due Date"),
    rate: Joi.number()
      .required()
      .min(0)
      .max(100)
      .label("Rate"),
    amount: Joi.number().label("Amount"),
    balance: Joi.number().label("Balance"),
    loanTypeId: Joi.string()
      .required()
      .label("Loan Type"),
    loanStatusId: Joi.string()
      .label("Loan Status")
      .required(),
    paymentDate: Joi.number()
      .required()
      .label("Payment Date"),
  };

  async populateLoanStatuses() {
    const { data: loanStatuses } = await getLoanStatuses();
    this.setState({ loanStatuses });
  }

  async populateLoanTypes() {
    const { data: loanTypes } = await getLoanTypes();
    this.setState({ loanTypes });
  }

  async populateCustomers() {
    const { data: customers } = await getCustomers();
    this.setState({ customers });
  }

  async populateLoan() {
    try {
      const loanId = this.props.match.params.id;
      if (loanId === "new") return;

      const { data: loan } = await getLoan(loanId);
      this.setState({ data: this.mapToViewModel(loan) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateLoanStatuses();
    await this.populateLoanTypes();
    await this.populateLoan();
    await this.populateCustomers();
  }

  mapToViewModel(loan) {
    return {
      _id: loan._id,
      customerId: loan.customerId,
      date: moment(loan.date)
        .format("YYYY-MM-DD")
        .toString(),
      contractNumber: loan.contractNumber,
      dueDate: moment(loan.dueDate)
        .format("YYYY-MM-DD")
        .toString(),
      rate: loan.rate,
      amount: loan.amount,
      balance: loan.balance,
      loanTypeId: loan.loanTypeId,
      loanStatusId: loan.loanStatusId,
      paymentDate: loan.paymentDate,
    };
  }

  doSubmit = async () => {
    await saveLoan(this.state.data);
    this.props.history.goBack();
  };

  render() {
    return (
      <div className={"form"}>
        <h1>Loan Form</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderSelect("customerId", "Customer", this.state.customers)}
          {this.renderInput("date", "Date", "date")}
          {this.renderInput("contractNumber", "Contract Number", "number")}
          {this.renderInput("dueDate", "Due Date", "date")}
          {this.renderInput("rate", "Rate", "number")}
          {this.renderInput("amount", "Amount", "number")}
          {this.renderInput("balance", "Balance(Must be 0 for new loan)", "number")}
          {this.renderSelect("loanTypeId", "Loan Type", this.state.loanTypes)}
          {this.renderSelect(
            "loanStatusId",
            "Loan Status",
            this.state.loanStatuses
          )}
          {this.renderInput("paymentDate", "Payment Date", "number")}
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default LoanForm;
