import http from "./httpService";

const apiEndpoint = "/takenLoans";

function loanUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export function getTakenLoans() {
    return http.get(apiEndpoint);
}

export function getTakenLoan(loanId) {
    return http.get(loanUrl(loanId));
}

export function saveTakenLoan(loan) {
    if (loan._id) {
        const body = { ...loan };
        delete body._id;
        return http.put(loanUrl(loan._id), body);
    }
    return http.post(apiEndpoint, loan);
}

export function deleteTakenLoan(loanId) {
    return http.delete(loanUrl(loanId));
}

export function makePrincipalPayment(loanId, data) {
    const body = {
        payment: data,
        method: "make",
        name: "principalPayments",
        account: "balance"
    };

    return http.put(`${apiEndpoint}/payment/${loanId}`, body);
}

export function deletePrincipalPayment(loanId, data) {
    const body = {
        payment: data,
        method: "delete",
        name: "principalPayments",
        account: "balance"
    };

    return http.put(`${apiEndpoint}/payment/${loanId}`, body);
}

export function makeInterestPayment(loanId, data) {
    const body = {
        payment: data,
        method: "make",
        name: "interestPayments",
        account: "paidInterest"
    };

    return http.put(`${apiEndpoint}/payment/${loanId}`, body);
}

export function deleteInterestPayment(loanId, data) {
    const body = {
        payment: data,
        method: "delete",
        name: "interestPayments",
        account: "paidInterest"
    };

    return http.put(`${apiEndpoint}/payment/${loanId}`, body);
}

export function makeAdditionalPayment(loanId, data) {
    const body = {
        payment: data,
        method: "make",
        name: "additionalPayments",
        account: "balance"
    };

    return http.put(`${apiEndpoint}/payment/${loanId}`, body);
}

export function deleteAdditionalPayment(loanId, data) {
    const body = {
        payment: data,
        method: "delete",
        name: "additionalPayments",
        account: "balance"
    };

    return http.put(`${apiEndpoint}/payment/${loanId}`, body);
}

export function saveScheduleToDb(loanId, data) {
    const body = {
        schedule: data,
        method: "save"
    };

    return http.put(`${apiEndpoint}/schedule/${loanId}`, body);
}

export function daleteLoanSchedule(loanId) {
    const body = {
        method: "delete"
    };

    return http.put(`${apiEndpoint}/schedule/${loanId}`, body);
}

export function makeSchedulePayment(loanId, data) {
    const body = {
        payment: data,
        method: "make"
    };

    return http.put(`${apiEndpoint}/schedule/${loanId}`, body);
}

export function generateSaveInterest(loanId, data) {
    const body = {
        method: data.method,
        monthEndDate: data.date
    };

    return http.put(`${apiEndpoint}/interest/${loanId}`, body);
}

export function getInterest(loanId) {
    return http.get(`${apiEndpoint}/interest/${loanId}`);
}

export function isInterestForPeriodGenerated(loanId, data) {
    const body = {
        monthEndDate: data.date
    };
    return http.post(`${apiEndpoint}/interest/${loanId}`, body);
}
