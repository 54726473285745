import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import auth from "../../services/authService";

class CostTable extends Component {
  columns = [
    {
      path: "name",
      label: "Cost Name",
      content: cost => (
        <Link to={`/cost/${cost._id}`}>
          {cost.name}
        </Link>
      )
    },
    {
      path: "typeName",
      label: "Cost Type"
    },
  ];
  deleteColumn = {
    key: "delete",
    content: cost => (
      <button
        onClick={() => this.props.onDelete(cost)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();

    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }
  render() {
    const { costs, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={costs}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default CostTable;
