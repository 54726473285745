import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Table from "../common/table";

import {
	getNotReceivedFactorings,
  } from '../../services/factoringService';

class NotReceivedFactoring extends Component {
  constructor() {
    super();
    this.state = {
      factorings: [],
	  sortColumn: {
		path: "invoiceDate",
		order: "desc"
	  },
    }

  }

  columns = [
    {
      path: "customerName",
      label: "Customer"
    },
    {
        path: "invoiceNumber",
        label: "Number",
        content: factoring => (
          <Link to={`/factoring/${factoring._id}`}>{factoring.invoiceNumber}</Link>
        )
    },
    {
        path: "invoiceDate",
        label: "Date",
        content: factoring =>
            moment(factoring.invoiceDate)
            .format("DD-MM-YY")
            .toString()
    },
    {
        path: "invoiceAmount",
        label: "Amount"
    },
    {
        path: "payerName",
        label: "Payer"
    },
    {
        path: "payment",
        label: "Payment"
    },
    {   path: "paymentDate",
        label: "Payment Date",
    },
    {
      path: "invoiceDueDate",
      label: "Due Date",
      content: factoring =>
            moment(factoring.invoiceDueDate)
              .format("DD-MM-YY")
              .toString()
      },
    {
      path: "receivedDate",
      label: "Received Date",
    },

    { path: "rate", label: "Rate" },
    { path: "fee", label: "Fee" },
  ];

  async componentDidMount() {
    const { data: factorings }  = await getNotReceivedFactorings();

    this.setState({ factorings });
  }

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };


  render() {
    const { factorings, sortColumn } = this.state;

    return (
      <div>
		<Table
			columns={this.columns}
			data={factorings}
			sortColumn={sortColumn}
			onSort={this.handleSort}
		/>
      </div>
    );
  }
}

export default NotReceivedFactoring;
