import React, { Component } from "react";
import _ from "lodash";
import { v4 as uuidV4 } from 'uuid';

class TableBody extends Component {
  generateUuid = () => uuidV4();

  renderCell = (item, column) => {
    if (column.content) return column.content(item);

    return _.get(item, column.path);
  };

  createKey = (item, column) => {
      if (!item._id) {
        return this.generateUuid();
      }
    return item._id + (column.path || column.key);
  };

  render() {
    const { data, columns } = this.props;
    return (
      <tbody>
        {data.map(item => (
          <tr key={item._id || this.generateUuid()}>
            {columns.map(column => (
              <td key={this.createKey(item, column)}>
                {this.renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
