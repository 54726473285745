import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import auth from "../../services/authService";

class LoanTypesTable extends Component {
  columns = [
    {
      path: "name",
      label: "Loan Type Name",
      content: loanTypes => (
        <Link to={`/loantypes/${loanTypes._id}`}>{loanTypes.name}</Link>
      )
    }
  ];
  deleteColumn = {
    key: "delete",
    content: loanTypes => (
      <button
        onClick={() => this.props.onDelete(loanTypes)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }
  render() {
    const { loanTypes, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={loanTypes}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default LoanTypesTable;
