import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getPayer, savePayer } from "../../services/payerServices";

class PayerForm extends Form {
    state = {
        data: {
            name: "",
            regCode: "",
            contactPerson: "",
            email: "",
            phone: ""
        },
        errors: {}
    };

    schema = {
        _id: Joi.string(),
        name: Joi.string()
            .required()
            .min(0)
            .max(50)
            .label("Name"),
        regCode: Joi.number()
            .required()
            .label("Registration Code"),
        contactPerson: Joi.string()
            .min(0)
            .max(50)
            .label("Contact Person")
            .optional()
            .allow('', null),
        email: Joi.string()
            .email()
            .label("Email")
            .optional()
            .allow('', null),
        phone: Joi.number()
            .min(0)
            .label("Telephone")
            .optional()
            .allow('', null),
    };

    async populatePayer() {
        try {
            const payerId = this.props.match.params.id;
            if (payerId === "new") return;

            const { data: payer } = await getPayer(payerId);
            this.setState({ data: this.mapToViewModel(payer) });
        } catch (ex) {
            if (ex.response && ex.response.status === 404)
                this.props.history.replace("/not-found");
        }
    }

    async componentDidMount() {
        await this.populatePayer();
    }

    mapToViewModel(payer) {
        return {
            _id: payer._id,
            name: payer.name,
            regCode: payer.regCode,
            contactPerson: payer.contactPerson,
            email: payer.email,
            phone: payer.phone
        };
    }

    doSubmit = async () => {
        await savePayer(this.state.data);
        this.props.history.push("/payers");
    };

    render() {
        return (
            <div className={"form"}>
                <h1>Payer Form</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.renderInput("name", "Name")}
                    {this.renderInput("regCode", "Registration Code", "number")}
                    {this.renderInput("contactPerson", "Contact Person")}
                    {this.renderInput("email", "Email")}
                    {this.renderInput("phone", "Telephone", "number")}
                    {this.renderButton("Save")}
                </form>
            </div>
        );
    }
}

export default PayerForm;
