import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import {
  updateParool,
  getUser,
  getQRCode,
  disableTwoWayAuth,
  enableTwoWayAuth,
} from "../../services/userService";
import auth from "../../services/authService";

class Profile extends Form {
  state = {
    data: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
    user: {},
    errors: {},
    isTwoWayAuthEnabled: false,
    QRCode: null,
  };

  async componentDidMount() {
    const user = auth.getCurrentUser();
    const {data: fetchedUser} = await getUser(user._id);
    const isTwoWayAuthEnabled = fetchedUser ? fetchedUser.isTwoWayAuthEnabled : false;

    this.setState({ user, isTwoWayAuthEnabled });
  }

  schema = {
    oldPassword: Joi.string()
      .required()
      .min(5)
      .label("Password"),
    newPassword: Joi.string()
      .required()
      .min(5)
      .label("Password"),
    newPasswordConfirm: Joi.string()
      .required()
      .valid(Joi.ref('newPassword')).options({
        language: {
          any: {
            allowOnly: '!!Password is confirmed when button is active',
          }
        }
      }),
  };

  doSubmit = async () => {
    try {
      const response = await updateParool(this.state.user._id, this.state.data);
      auth.loginWithJwt(response.headers["x-auth-token"]);
      window.location = "/";
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = err.response.data;
        this.setState({ errors });
      }
    }
  };

  handleEnableTwoWayAuth = async () => {
    await enableTwoWayAuth(this.state.user._id);
    window.location.reload();
  }

  handleDisableTwoWayAuth = async () => {
    await disableTwoWayAuth(this.state.user._id);
    window.location.reload();
  }

  handleGetQRCode = async () => {
    const {data: QRCode } = await getQRCode(this.state.user._id);
    this.setState({ QRCode });
  }

  render() {
    console.log('tere', this.state.QRCode)
    return (
      <div className="page">
        <h1>Hi pal, Update password!</h1>
        <form className="form" onSubmit={this.handleSubmit}>
          {this.renderInput("oldPassword", "Old Password", "password")}
          {this.renderInput("newPassword", "New Password", "password")}
          {this.renderInput("newPasswordConfirm", "New Password Confirm", "password")}
          {this.renderButton("Update")}
        </form>
        <h1>Hi pal, Enable 2fa!!</h1>
        <div className="d-grid gap-3">
          <div className="p-2">
            {this.state.isTwoWayAuthEnabled
            && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleDisableTwoWayAuth}
            >Disable Two Way Auth</button>)}
          </div>
          <div className="p-2">
            {this.state.isTwoWayAuthEnabled
              && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handleGetQRCode}
              >Get QR Code</button>)}
          </div>
          <div className="p-2">
            {this.state.QRCode
              && (<img src={this.state.QRCode} alt="qrcode"/>)}
          </div>
        </div>
        {!this.state.isTwoWayAuthEnabled
          && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.handleEnableTwoWayAuth}
          >Enable Two Way Auth</button>)}
      </div>
    );
  }
}

export default Profile;
