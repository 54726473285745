import React, { Component} from "react";
import {
    postOpenTransactions,
    getOpenTransactions,
    deleteTransaction,
    getLastUploadedTransactionDate,
    getBankBalance
 } from '../../services/bankService';
import { toast } from "react-toastify";
import OpenTransactionTable from './openTransactionTable';
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { Link } from "react-router-dom";


class  OpenTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            loaded: 0,
            openTransactions: [],
            currentPage: 1,
            pageSize: 100,
            searchQuery: "",
            lastTransactionDate: 'NA',
            bankBalance: 'NA',
            sortColumn: {
                path: "name",
                order: "asc"
            }
        }
    }

    handleFileImport = event => {
       this.setState({
        selectedFile:event.target.files[0],
        loaded: 0,
       })
    }

    handleFileUpload = async () => {
        const data = new FormData();
        const file = this.state.selectedFile;

        data.append('file', file);
        const { data: responseStatus } = await postOpenTransactions(data, {
            headers: {
                'content-type': `text/xml; boundary=${data._boundary}`

            }
        })

        this.setState({
            selectedFile:null
        })
        if (responseStatus === 'ok') {
            toast.info("File uploaded");
        } else if(responseStatus === 'Wrong bank statement!') {
            toast.error("Wrong bank statement!");
        } else {
            toast.warn("File upload filed. Try Again!");
        }

        setTimeout(()=> {
            window.location.reload();
        }, 1000)


    }

    async componentDidMount() {
        const { data: openTransactions } = await getOpenTransactions();
        const { data: lastTransactionDate } = await getLastUploadedTransactionDate();
        const { data: bankBalanceObj } = await getBankBalance();
        const bankBalance = bankBalanceObj.balance;

        this.setState({ openTransactions, lastTransactionDate, bankBalance });
    }

    handleDelete = async openTransaction => {
        if (window.confirm("Are you sure you want delete this item")) {
            const originalOpenTransactions = this.state.openTransactions;
            const openTransactions = this.state.openTransactions.filter(m => m._id !== openTransaction._id);
            this.setState({ openTransactions });
            try {
                await deleteTransaction(openTransaction._id);
            } catch (ex) {
                if (ex.response && ex.response.status === 404);
                toast.error("This transaction has already been deleted.");

                this.setState({ openTransactions: originalOpenTransactions });
            }
        }
    };

    handlePageChange = page => {
        this.setState({ currentPage: page });
    };

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    };

    handleSearch = query => {
        this.setState({
            searchQuery: query,
            currentPage: 1
        });
    };

    getPagedData = () => {
        const {
            pageSize,
            currentPage,
            sortColumn,
            searchQuery,
            openTransactions: allTransactions
        } = this.state;

        let filtered = allTransactions;

        if (searchQuery)
            filtered = allTransactions.filter(c =>
                c.name.toLowerCase().startsWith(searchQuery.toLowerCase())
            );

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const openTransactions = paginate(sorted, currentPage, pageSize);

        return { totalCount: filtered.length, data: openTransactions };
    };

    render() {
        const { pageSize,
            currentPage,
            sortColumn,
            searchQuery,
            lastTransactionDate,
            bankBalance,
        } = this.state;
        const { totalCount, data: openTransactions } = this.getPagedData();
        return (
            <div className="page">
                <div style={{display:'flex', flexFlow: 'row'}}>
                <div className="box">
                    <label>Upload Your XML File </label>
                    <div>
                        <input type="file" name="file" onChange={this.handleFileImport}/>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        <button type="button" className="btn btn-success btn-block button" onClick={this.handleFileUpload}>Upload</button>
                    </div>
                </div>
                <div>
                <div className="box"><Link to={'/transactions/'}>Processed Transactions</Link></div>
                <div className="box"><Link to={'/suggestedTransactions/'}>Suggested Transactions</Link></div>
                <div className="box">
                    <p>Last date imported {lastTransactionDate}</p>
                    <p>Bank Balance {bankBalance} €</p>
                    <p>There is {totalCount} open transactions on the page</p>
                </div>
                </div>

                </div>
                <SearchBox value={searchQuery} onChange={this.handleSearch} />

                <OpenTransactionTable
                    openTransactions={openTransactions}
                    sortColumn={sortColumn}
                    onDelete={this.handleDelete}
                    onSort={this.handleSort}
                />
                <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    onPageChange={this.handlePageChange}
                    currentPage={currentPage}
                />
            </div>

        )
    }

}

export default OpenTransaction;