import React from "react";
import Joi from "joi-browser";
import moment from "moment";
import Form from "../common/form";
//import { makeAdditionalPayment } from "../services/loanService";
import { toast } from "react-toastify";
import { generateSchedule } from "../../utils/generateLoanSchedule";
import PaymentScheduleTable from "./paymentScheduleTable";

class PaymentScheduleForm extends Form {
  state = {
    data: {
      date: moment(Date.now())
        .format("YYYY-MM-DD")
        .toString(),
      amount: 1000,
      months: 12,
      rate: 24
    },
    schedule: this.props.loan.schedule,
    isSchedule: this.props.loan.isSchedule,
    errors: {}
  };

  schema = {
    date: Joi.date()
      .required()
      .label("Loan start Date"),
    amount: Joi.number()
      .required()
      .label("Payment Amount"),
    months: Joi.number()
      .required()
      .label("Due date in months"),
    rate: Joi.number()
      .required()
      .label("Interest rate")
  };

  doSubmit = async () => {
    let { date, amount, months, rate } = this.state.data;
    const schedule = generateSchedule(date, amount, months, rate);
    this.setState({ schedule });
  };

  handleScheduleDelete = async loanId => {
    if (window.confirm("Are you sure you want delete this schedule")) {
      let schedule = this.state.schedule;
      const originalSchedule = [...schedule];
      schedule = [];
      this.setState({ schedule });

      try {
        const daleteLoanSchedule = this.props.service;
        await daleteLoanSchedule(loanId);
        toast.info("Schedule removed");
        window.location.reload();
      } catch (ex) {
        if (ex.response && ex.response.status === 404);
        toast.error("This schedule is already deleted.");

        this.setState({ schedule: originalSchedule });
      }
    }
  };

  render() {
    const schedule = this.state.schedule;
    const isSchedule = this.state.isSchedule;
    const loanId = this.props.loanId;
    return (
      <div>
        <div>
          <h3>Generate Loan Schedule</h3>
        </div>
        <div>
          {isSchedule && (
            <button
              onClick={() => this.handleScheduleDelete(loanId)}
              className="btn btn-primary"
              style={{ marginBottom: 20 }}
            >
              Delete Schedule
            </button>
          )}
        </div>
        <div>
          {!isSchedule && (
            <form onSubmit={this.handleSubmit}>
              {this.renderInput("date", "Loan Start Date", "date")}
              {this.renderInput("amount", "Loan Amount", "number")}
              {this.renderInput("months", "Due date in months", "number")}
              {this.renderInput("rate", "Interest rate", "number")}
              {this.renderButton("Generate Schedule")}
            </form>
          )}
        </div>
        <div>
          {(isSchedule || schedule.length !== 0) && (
            <PaymentScheduleTable
              makeSchedulePayment={this.props.makeSchedulePayment}
              saveScheduleToDb={this.props.saveScheduleToDb}
              loanId={loanId}
              data={schedule}
              isSchedule={isSchedule}
            />
          )}
        </div>
      </div>
    );
  }
}

export default PaymentScheduleForm;
