import http from "./httpService";

const apiEndpoint = "/income";

function incomeUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getIncomes() {
  return http.get(apiEndpoint);
}

export function getIncome(id) {
  return http.get(incomeUrl(id));
}

export function saveIncome(income) {
  if (income._id) {
    const body = { ...income };
    delete body._id;
    return http.put(incomeUrl(income._id), body);
  }
  return http.post(apiEndpoint, income);
}

export function deleteIncome(incomeId) {
  return http.delete(incomeUrl(incomeId));
}
