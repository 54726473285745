import React from "react";
import Joi from "joi-browser";
import moment from "moment";
import Form from "../common/form";
import { toast } from "react-toastify";
import { addPartialPayment } from "../../services/factoringService";

class factoringPartialForm extends Form {
  state = {
    data: {
      date: moment(Date.now())
        .format("YYYY-MM-DD")
        .toString(),
      amount: "",
      comment: ""
    },
    errors: {}
  };

  schema = {
    date: Joi.date()
      .required()
      .label("Payment Date"),
    amount: Joi.number()
      .required()
      .label("Payment Amount"),
    comment: Joi.string().label("Comment").allow('')
  };

  doSubmit = async () => {
    const factoringId = this.props.factoringId;
    let { amount, date, comment } = this.state.data;
    await addPartialPayment(factoringId, { amount, date, comment });

    toast.info("Additional payment made!");
    amount = 0;
    comment = "";
    this.setState({ amount, comment });
    window.location.reload();
  };

  render() {
    return (
      <div className={"form"}>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("date", "Payment Date", "date")}
          {this.renderInput("amount", "Payment Amount", "number")}
          {this.renderInput("comment", "Comment")}
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default factoringPartialForm;
