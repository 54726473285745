import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import auth from "../../services/authService";

class LoanStatusesTable extends Component {
  columns = [
    {
      path: "name",
      label: "Loan Status Name",
      content: loanStatuses => (
        <Link to={`/loanstatuses/${loanStatuses._id}`}>
          {loanStatuses.name}
        </Link>
      )
    }
  ];
  deleteColumn = {
    key: "delete",
    content: loanStatuses => (
      <button
        onClick={() => this.props.onDelete(loanStatuses)}
        className="btn btn-danger btn-sm"
      >
        Delete
      </button>
    )
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }
  render() {
    const { loanStatuses, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={loanStatuses}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default LoanStatusesTable;
