import jsPDF from "jspdf";
import "jspdf-autotable";

export function generatePdf(head, body) {
  //removing first element mongoDB _id and 3rd period
  body.forEach(element => {
    element.shift();
    element.splice(2, 1);
  });

  let doc = new jsPDF();
  doc.autoTable({ head: head, body: body, showHead: "everyPage" });
  doc.output("dataurlnewwindow"); // avab eraldi tabis
  //doc.save("paymentSchedule.pdf");
}
