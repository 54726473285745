import React, { Component } from "react";
import { Link } from "react-router-dom";
import Pagination from "../common/pagination";
import {
  getCostTypes,
  deleteCostType
} from "../../services/costTypeService";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";
import CostTypeTable from './costTypeTable';

class CostTypes extends Component {
  state = {
    costTypes: [],
    currentPage: 1,
    pageSize: 10,
    searchQuery: "",
    selectedGenre: null,
    sortColumn: {
      path: "name",
      order: "asc"
    }
  };

  async componentDidMount() {
    const { data: costTypes } = await getCostTypes();
    this.setState({ costTypes });
  }

  handleDelete = async costType => {
    if (window.confirm("Are you sure you want delete this item")) {
      const originalCostTypes = this.state.costTypes;
      const costTypes = this.state.costTypes.filter(
        ls => ls._id !== costType._id
      );
      this.setState({ costTypes });
      try {
        await deleteCostType(costType._id);
      } catch (err) {
        console.log(err.response.data)
        if (err.response && err.response.status === 404) {
          toast.error("This costTypes has already been deleted.");
        }
        if (err.response && err.response.status === 400) {
          toast.error(`${err.response.data}`);
        }

        this.setState({ costTypes: originalCostTypes });
      }
    }
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handleSearch = query => {
    this.setState({
      searchQuery: query,
      currentPage: 1
    });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      costTypes: allCostTypes
    } = this.state;

    let filtered = allCostTypes;

    if (searchQuery)
      filtered = allCostTypes.filter(c =>
        c.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const costTypes = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: costTypes };
  };

  render() {
    const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
    const { user } = this.props;

    const { totalCount, data: costTypes } = this.getPagedData();

    return (
      <div className="page">
      <div className="row">
        <div className="col">
          {user && (
            <Link
              className="btn btn-primary"
              to="/costType/new"
              style={{ marginBottom: 20 }}
            >
              New Cost Type
            </Link>
          )}
          <p>There is {totalCount} costTypes in the database</p>
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
          <CostTypeTable
            costTypes={costTypes}
            sortColumn={sortColumn}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            onPageChange={this.handlePageChange}
            currentPage={currentPage}
          />
        </div>
      </div>
      </div>
    );
  }
}

export default CostTypes;
