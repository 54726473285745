import jwtDecode from "jwt-decode";
import http from "./httpService";

const apiEndpoint = "/auth";
const tokenKey = "token";

http.setJwt(getJwt());

export async function login(email, password, secret) {
  const { data: jwt } = await http.post(apiEndpoint, { email, password, secret });
  localStorage.setItem(tokenKey, jwt);
}
export async function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}
export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const decodedJwt = jwtDecode(jwt);
    const date = new Date(decodedJwt.exp * 1000)
    if (new Date() > date) return null;
    return decodedJwt;
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}
export default {
  login,
  logout,
  getCurrentUser,
  loginWithJwt,
  getJwt
};
