import React, { Component } from "react";
import { getAccounts, getAllAccounts } from "../../services/accountingService";
import AccountingTable from "./accountingTable";

class Accounting extends Component {
  state = {
    accounts: [],
    allAccounts: []
  };



  async componentDidMount() {
    try {
      const { data: accounts } = await getAccounts();
      const { data: Allaccounts } = await getAllAccounts();
      this.setState({ accounts, Allaccounts });

    } catch(err) {
        console.log('Cannot get accounts', err)
    }

  }

  render() {
    return (
      <div>
        <div className="row" style={{ marginBottom: 30 }}>
          <div className="col">
            <h1>Accounting</h1>
          </div>
        </div>
        <div>
          <AccountingTable accounts={this.state.accounts} allAccounts={this.state.Allaccounts}/>
        </div>
      </div>
    );
  }
}

export default Accounting;
