import React, { Component } from "react";
import TransactionDealsTable from "./transactionDealsTable";
import { getTransaction } from '../../services/bankService';

class FactoringPartial extends Component {
    state = {
        transaction: {},
    };

    async componentDidMount() {
        const { data: transaction } = await getTransaction(this.props.match.params.id);
        this.setState({ transaction });
    }

    render() {
        const { transaction } = this.state;

        let deals = [];
        if (transaction.additionalInfo && transaction.additionalInfo.readyDeals) {
            deals = transaction.additionalInfo.readyDeals;
        }

        return (
            <div className="row">
                <div className="col">
                    <TransactionDealsTable
                        deals={deals}
                    />
                </div>
            </div>
        );
    }
}

export default FactoringPartial;
