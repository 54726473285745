import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getIncome, saveIncome } from "../../services/incomeService";
import { getIncomeTypes } from "../../services/incomeTypeService";

class IncomeForm extends Form {
  state = {
    data: {
      name: "",
      typeId: '',
    },
    incomeTypes: [],
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Income"),
    typeId: Joi.string()
    .required()
    .label("typeId"),
  };

  async populateIncomeTypes() {
    const { data: incomeTypes } = await getIncomeTypes();
    this.setState({ incomeTypes });
  }

  async populateIncome() {
    try {
      const incomeId = this.props.match.params.id;
      if (incomeId === "new") return;

      const { data: income } = await getIncome(incomeId);
      this.setState({ data: this.mapToViewModel(income) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateIncomeTypes();
    await this.populateIncome();
  }

  mapToViewModel(income) {
    return {
      _id: income._id,
      name: income.name,
      typeId: income.typeId
    };
  }

  doSubmit = async () => {
    await saveIncome(this.state.data);

    this.props.history.push("/income");
  };

  render() {
    return (
      <div>
        <h1>Income Form</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Income Name")}
          {this.renderSelect("typeId", "Income Type", this.state.incomeTypes)}
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default IncomeForm;
