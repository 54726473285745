import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getCreditor, saveCreditor } from "../../services/creditorServices";

class CreditorForm extends Form {
    state = {
        data: {
            name: "",
            regCode: "",
            contactPerson: "",
            email: "",
            phone: ""
        },
        errors: {}
    };

    schema = {
        _id: Joi.string(),
        name: Joi.string()
            .required()
            .min(0)
            .max(50)
            .label("Name"),
        regCode: Joi.number()
            .required()
            .label("Registration Code"),
        contactPerson: Joi.string()
            .required()
            .min(0)
            .max(50)
            .label("Contact Person"),
        email: Joi.string()
            .required()
            .email()
            .label("Email"),
        phone: Joi.number()
            .required()
            .min(0)
            .label("Telephone")
    };

    async populateCreditor() {
        try {
            const creditorId = this.props.match.params.id;
            if (creditorId === "new") return;

            const { data: creditor } = await getCreditor(creditorId);
            this.setState({ data: this.mapToViewModel(creditor) });
        } catch (ex) {
            if (ex.response && ex.response.status === 404)
                this.props.history.replace("/not-found");
        }
    }

    async componentDidMount() {
        await this.populateCreditor();
    }

    mapToViewModel(creditor) {
        return {
            _id: creditor._id,
            name: creditor.name,
            regCode: creditor.regCode,
            contactPerson: creditor.contactPerson,
            email: creditor.email,
            phone: creditor.phone
        };
    }

    doSubmit = async () => {
        await saveCreditor(this.state.data);
        this.props.history.push("/creditors");
    };

    render() {
        return (
            <div>
                <h1>Creditor Form</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.renderInput("name", "Name")}
                    {this.renderInput("regCode", "Registration Code", "number")}
                    {this.renderInput("contactPerson", "Contact Person")}
                    {this.renderInput("email", "Email")}
                    {this.renderInput("phone", "Telephone", "number")}
                    {this.renderButton("Save")}
                </form>
            </div>
        );
    }
}

export default CreditorForm;
