import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import auth from "../../services/authService";

class OpenTransactionTable extends Component {
    columns = [
        { path: "name", label: "Name" },
        { path: "transaction", label: "D/B" },
        { path: "date", label: "Date" },
        { path: "amount", label: "Amount" },
        { path: "description", label: "Description" },
        {
        key: "payment",
        content: openTransactions => (
            <Link to={`/transaction/process/${openTransactions._id}`}>Process</Link>
        )
        }
    ];
    deleteColumn = {
        key: "delete",
        content: openTransactions => (
            <button
                onClick={() => this.props.onDelete(openTransactions)}
                className="btn btn-danger btn-sm"
            >
                Delete
      </button>
        )
    };

    constructor() {
        super();
        const user = auth.getCurrentUser();
        if (user && user.isAdmin) this.columns.push(this.deleteColumn);
    }
    render() {
        const { openTransactions, onSort, sortColumn } = this.props;

        return (
            <Table
                columns={this.columns}
                data={openTransactions}
                sortColumn={sortColumn}
                onSort={onSort}
            />
        );
    }
}

export default OpenTransactionTable;
