import React, { Component } from "react";
import { Link } from "react-router-dom";
import PayersTables from "./payersTable";
import Pagination from "../common/pagination";
import { getPayers, deletePayer } from "../../services/payerServices";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import SearchBox from "../common/searchBox";
import { toast } from "react-toastify";
import Loader from '../common/loader';

class Payers extends Component {
    state = {
        payers: [],
        currentPage: 1,
        pageSize: 100,
        searchQuery: "",
        sortColumn: {
            path: "name",
            order: "asc"
        }
    };

    async componentDidMount() {
        const { data: payers } = await getPayers();
        this.setState({ payers });
    }

    handleDelete = async payer => {
        const originalPayers = this.state.payers;
        const payers = this.state.payers.filter(m => m._id !== payer._id);
        this.setState({ payers });
        try {
            await deletePayer(payer._id);
        } catch (ex) {
            if (ex.response && ex.response.status === 404);
            toast.error("This payer has already been deleted.");

            this.setState({ payers: originalPayers });
        }
    };

    handlePageChange = page => {
        this.setState({ currentPage: page });
    };

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    };

    handleSearch = query => {
        this.setState({
            searchQuery: query,
            currentPage: 1
        });
    };

    getPagedData = () => {
        const {
            pageSize,
            currentPage,
            sortColumn,
            searchQuery,
            payers: allPayers
        } = this.state;

        let filtered = allPayers;

        if (searchQuery)
            filtered = allPayers.filter(c =>
                c.name.toLowerCase().startsWith(searchQuery.toLowerCase())
            );

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const payers = paginate(sorted, currentPage, pageSize);

        return { totalCount: filtered.length, data: payers };
    };

    render() {
        const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
        const { user } = this.props;

        const { totalCount, data: payers } = this.getPagedData();

        return (
            <div className="page">
                <div className="row">
                    <div className="col">
                        {user && (
                            <Link
                                className="btn btn-primary"
                                to="/payers/new"
                                style={{ marginBottom: 20 }} >
                                New Payer
                            </Link>
                        )}
                        <p>There is {totalCount} payers in the database</p>
                        <SearchBox value={searchQuery} onChange={this.handleSearch} />
                        <PayersTables
                            payers={payers}
                            sortColumn={sortColumn}
                            onDelete={this.handleDelete}
                            onSort={this.handleSort}
                        />
                        <Pagination
                            itemsCount={totalCount}
                            pageSize={pageSize}
                            onPageChange={this.handlePageChange}
                            currentPage={currentPage}
                        />
                    </div>
                </div>
                <div className="row">
                    <Loader data={this.state.payers} />
                </div>
            </div>
        );
    }
}

export default Payers;
