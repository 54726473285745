import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

class Settings extends Component {
  state = {};

  async componentDidMount() { }

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  render() {
    return (
      <div className="page">
        <div>
          <h2 style={{margin:'20px'}}>SETTING PAGE</h2>
        </div>
        <div style={{display:'flex', flexFlow: 'row'}}>
          <div style={{ marginLeft:"10px"}}>
            <Link className="btn btn-info" to="/customers">
              Customers
            </Link>
          </div>
          <div style={{ marginLeft:"10px"}}>
            <Link className="btn btn-info" to="/creditors">
              Creditors
            </Link>
          </div>
          <div style={{ marginLeft:"10px"}}>
            <Link className="btn btn-info" to="/payers">
              Payers
            </Link>
          </div>
          <div style={{ marginLeft:"10px"}}>
            <Link className="btn btn-info" to="/loanstatuses">
              Loan Statuses
            </Link>
          </div>
          <div style={{ marginLeft:"10px"}}>
            <Link className="btn btn-info" to="/loantypes">
              Loan Types
            </Link>
          </div>
          <div style={{ marginLeft:"10px"}}>
            <Link className="btn btn-info" to="/cost">
              Costs
            </Link>
          </div>
          <div style={{ marginLeft:"10px"}}>
            <Link className="btn btn-info" to="/income">
              Incomes
            </Link>
          </div>
          <div style={{ marginLeft:"10px"}}>
            <Link className="btn btn-info" to="/costType">
              Cost Types
            </Link>
          </div>
          <div style={{ marginLeft:"10px"}}>
            <Link className="btn btn-info" to="/incomeType">
              Income Types
            </Link>
          </div>
          <div style={{ marginLeft:"10px"}}>
            <Link className="btn btn-info" to="/other">
              Others
            </Link>
          </div>
          <div style={{ marginLeft:"10px"}}>
            <Link className="btn btn-info" to="/accounting">
              Accounting
            </Link>
          </div>
        </div>
          {/* <div className="col-sm-6">
            <div className="card" style={{ width: 180 }}>
              <div className="card-body">
                <h5 className="card-title">Update Table</h5>
                <p className="card-text">
                  There you can updated table
                </p>
                <p>
                  <Link className="btn btn-primary" to="/updateTable">
                    Update Table
                  </Link>
                </p>
              </div>
            </div>
          </div> */}
        </div>
    );
  }
}

export default Settings;
