import http from "./httpService";

const apiEndpoint = "/contracts";

function contractUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export function getContracts() {
    return http.get(apiEndpoint);
}

export function getContract(contractId) {
    return http.get(contractUrl(contractId));
}

export function getContractPayers(customerId) {
    return http.get(`${apiEndpoint}/payers/${customerId}`);
}

export function saveContract(contract) {
    if (contract._id) {
        const body = { ...contract };
        delete body._id;
        return http.put(contractUrl(contract._id), body);
    }
    return http.post(apiEndpoint, contract);
}

export function deleteContract(contractId) {
    return http.delete(contractUrl(contractId));
}

export function saveTerm(contractId, termId, data) {
    const body = {
        data, termId
    };

    return http.put(`${apiEndpoint}/term/${contractId}`, body);
}

export function deleteContractTerm(contractId, data) {
    const body = data;

    return http.put(`${apiEndpoint}/deleteterm/${contractId}`, body);
}
