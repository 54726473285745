import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { saveCostType, getCostType } from "../../services/costTypeService";

class CostForm extends Form {
  state = {
    data: {
      name: ""
    },
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Cost")
  };

  async populateCost() {
    try {
      const costId = this.props.match.params.id;
      if (costId === "new") return;

      const { data: cost } = await getCostType(costId);
      this.setState({ data: this.mapToViewModel(cost) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateCost();
  }

  mapToViewModel(cost) {
    return {
      _id: cost._id,
      name: cost.name
    };
  }

  doSubmit = async () => {
    await saveCostType(this.state.data);

    this.props.history.push("/costType");
  };

  render() {
    return (
      <div>
        <h1>Cost Type Form</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Cost Name")}
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default CostForm;
