import React from "react";
import { Link, NavLink } from "react-router-dom";

const NavBar = ({ user }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <Link className="navbar-brand" to="/">
        Loan App
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      {user && (
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <NavLink className="nav-item nav-link" to="/loans">
              Loans
            </NavLink>
            <NavLink className="nav-item nav-link" to="/takenloans">
              Taken Loans
            </NavLink>
            <NavLink className="nav-item nav-link" to="/factoring">
              Factorings
            </NavLink>
            <NavLink className="nav-item nav-link" to="/opentransactions">
              Bank
            </NavLink>
            <NavLink className="nav-item nav-link" to="/factoring/overdues">
              Factoring Overdues
            </NavLink>
            <NavLink className="nav-item nav-link" to="/factoringfilter">
              Factoring Filter
            </NavLink>
            <NavLink className="nav-item nav-link" to="/contracts">
              Factoring Contracts
            </NavLink>
            <NavLink className="nav-item nav-link" to="/settings">
              Settings
            </NavLink>
            {!user && (
              <React.Fragment>
                <NavLink className="nav-item nav-link" to="/login">
                  Login
                </NavLink>
                <NavLink className="nav-item nav-link" to="/register">
                  Register
                </NavLink>
              </React.Fragment>
            )}
            {user && (
              <React.Fragment>
                <NavLink className="nav-item nav-link" to="/logout">
                  Log out
                </NavLink>
                <NavLink className="nav-item nav-link" to="/profile">
                  Tere, {user.name}!
                </NavLink>
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
