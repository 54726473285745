import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getOthers, saveOther } from "../../services/otherService";

class OtherForm extends Form {
  state = {
    data: {
      name: "",

    },
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Other"),
  };

  async populateOther() {
    try {
      const otherId = this.props.match.params.id;
      if (otherId === "new") return;

      const { data: other } = await getOthers(otherId);
      this.setState({ data: this.mapToViewModel(other) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateOther();
  }

  mapToViewModel(other) {
    return {
      _id: other._id,
      name: other.name,
    };
  }

  doSubmit = async () => {
    await saveOther(this.state.data);

    this.props.history.push("/other");
  };

  render() {
    return (
      <div>
        <h1>Other Form</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Other Name")}
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default OtherForm;
