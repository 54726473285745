import http from "./httpService";

const apiEndpoint = "/creditors";

function creditorUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export function getCreditors() {
    return http.get(apiEndpoint);
}

export function getCreditor(creditorId) {
    return http.get(creditorUrl(creditorId));
}

export function saveCreditor(creditor) {
    if (creditor._id) {
        const body = { ...creditor };
        delete body._id;
        return http.put(creditorUrl(creditor._id), body);
    }
    return http.post(apiEndpoint, creditor);
}

export function deleteCreditor(creditorId) {
    return http.delete(creditorUrl(creditorId));
}
