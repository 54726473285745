import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Table from "../common/table";
import auth from "../../services/authService";
import currencyFormating from "../../utils/currencyFormating";

class TakenLoansTable extends Component {
    columns = [
        {
            path: "creditorName",
            label: "Creditor"
        },
        {
            path: "contractNumber",
            label: "Contract Number",
            content: takenLoan => (
                <Link to={`/takenloans/${takenLoan._id}`}>{takenLoan.contractNumber}</Link>
            )
        },
        {
            path: "date",
            label: "Contract Date",
            content: takenLoan =>
                moment(takenLoan.date)
                    .format("DD-MM-YY")
                    .toString()
        },
        {
            path: "dueDate",
            label: "Due Date",
            content: takenLoan =>
                moment(takenLoan.dueDate)
                    .format("DD-MM-YY")
                    .toString()
        },
        { path: "rate", label: "Rate" },
        { path: "amount", label: "Amount", content: takenLoan => currencyFormating(takenLoan.amount) },
        { path: "balance", label: "Balance", content: takenLoan => currencyFormating(takenLoan.balance) },
        { path: "loanTypeName", label: "Loan Type" },
        { path: "loanStatusName", label: "Loan Status" }
    ];
    deleteColumn = {
        key: "delete",
        content: takenLoan => (
            <button
                onClick={() => this.props.onDelete(takenLoan)}
                className="btn btn-danger btn-sm"
            >
                Delete
      </button>
        )
    };

    constructor() {
        super();
        const user = auth.getCurrentUser();
        if (user && user.isAdmin) this.columns.push(this.deleteColumn);
    }
    render() {
        const { takenLoans, onSort, sortColumn } = this.props;

        return (
            <Table
                columns={this.columns}
                data={takenLoans}
                sortColumn={sortColumn}
                onSort={onSort}
            />
        );
    }
}

export default TakenLoansTable;
