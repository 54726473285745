import React, { Component } from "react";
import Table from "../common/table";
import { getSuggestedTransactions, processTransaction } from '../../services/bankService';

class SuggestedTransactionTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestedTransactions: [],
            sortColumn: {
                path: "suggestedFactoring.customerName",
                order: "asc"
            }
        }
    }

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    };

    columns = [
        { path: "suggestedFactoring.customerName", label: "Name" },
        { path: "suggestedFactoring.invoiceNumber", label: "Number" },
        { path: "suggestedFactoring.invoiceAmount", label: "Amount" },
        { path: "suggestedFactoring.payment", label: "Payment" },
        { path: "suggestedFactoring.payerName", label: "Payer" },
        { path: "payment.name", label: "Transaction Name" },
        { path: "payment.description", label: "Transaction desc" },
        { path: "payment.amount", label: "Transaction amount" },
        {
            key: "delete",
            content: transaction => (
                <button
                    onClick={async () => {
                        const data = {
                            typeId: '1',
                            customerId: transaction.suggestedFactoring.customerId,
                            secondCustomerId: '',
                            equalsTotal: 'Yes',
                            residualAmount: 0,
                            residualComment: '',
                            costId: '',
                        }
                        console.log('daata', data);
                        console.log('transaction.payment._id', transaction.payment._id);
                        console.log('transaction.payment]', transaction.payment);

                        transaction.suggestedFactoring.amount = transaction.payment.amount;

                        await processTransaction(data, transaction.payment._id, [transaction.suggestedFactoring]);
                        window.location.reload();
                        }
                    }
                    className="btn btn-danger btn-sm"
                >
                    Confirm
          </button>
            )
        },
    ];

    async componentDidMount() {
        const { data: suggestedTransactions } = await getSuggestedTransactions();
        this.setState({ suggestedTransactions });
    }

    render() {

        const { suggestedTransactions, sortColumn } = this.state;

        return (
            <Table
            columns={this.columns}
            data={suggestedTransactions}
            sortColumn={sortColumn}
            onSort={this.handleSort}
        />
        );
    }
}

export default SuggestedTransactionTable;
