import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";

class TransactionTable extends Component {
    columns = [
        { path: "name", label: "Name" },
        { path: "transaction", label: "D/B" },
        { path: "date", label: "Date" },
        { path: "amount", label: "Amount" },
        { path: "description", label: "Description" },
        {
        key: "metdata",
        content: transactions => (
            <Link to={`/transaction/metadata/${transactions._id}`}>MetaData</Link>
        )
        },
        {
        key: "readyDeals",
        content: transactions => (
            <Link to={`/transaction/deals/${transactions._id}`}>Deals</Link>
        )
        },
        {
        key: "reset",
        content: transactions => (
            <button
                onClick={() => this.props.onReset(transactions)}
                className="btn btn-danger btn-sm"
            >
                Reset
            </button>
        )
        },
    ]

    // deleteColumn = {
    //     key: "delete",
    //     content: transactions => (
    //         <button
    //             onClick={() => this.props.onDelete(transactions)}
    //             className="btn btn-danger btn-sm"
    //         >
    //             Delete
    //   </button>
    //     )
    // };

    render() {
        const { transactions, onSort, sortColumn } = this.props;

        return (
            <Table
                columns={this.columns}
                data={transactions}
                sortColumn={sortColumn}
                onSort={onSort}
            />
        );
    }
}

export default TransactionTable;
