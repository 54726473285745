import http from "./httpService";

const apiEndpoint = "/users";

export function register(user) {
  return http.post(apiEndpoint, {
    email: user.username,
    password: user.password,
    name: user.name
  });
}

export function updateParool(userId, data) {
  return http.put(`${apiEndpoint}/parool/${userId}`, {
    oldPassword: data.oldPassword,
    newPassword: data.newPassword,
  });
}
export function getUser(userId) {
  return http.get(`${apiEndpoint}/${userId}`);
}

export function enableTwoWayAuth(userId) {
  return http.post(`${apiEndpoint}/enableTwoWayAuth/${userId}`);
}

export function disableTwoWayAuth(userId) {
  return http.post(`${apiEndpoint}/disableTwoWayAuth/${userId}`);
}

export function getQRCode(userId) {
  return http.get(`${apiEndpoint}/qrcode/${userId}`);
}