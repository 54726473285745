import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { toast } from "react-toastify";
import { getCustomersByType, getOpenDeals, processTransaction } from "../../services/bankService";
import { getAllAccounts } from "../../services/accountingService";
import TableNoSort from "../common/tableNoSort";
import { Redirect } from "react-router-dom";


class TransactionForm extends Form {
  constructor(props){
    super(props);

    this.state = {
      data: {
          typeId:'',
          customerId: '',
          secondCustomerId: '',
          equalsTotal: 'No',
          residualAmount: 0,
          residualComment: '',
          costId: '',
      },
      proccessReady: false,
      errors: {},
      types: [
          {_id: 1, name:'Factoring'},
          {_id: 2, name:'Loan'},
          {_id: 3, name:'Taken Loan'},
          {_id: 4, name:'Cost'},
          {_id: 5, name:'Income'},
          {_id: 6, name:'Other'}
      ],
      customers: [],
      secondCustomers: [],
      openDeals: [],
      secondOpenDeals: [],
      costs: [],
      incomes: [],
      others: [],
      readyTransactions: [],
      isInputDisabledLoanAdd: false,
      isInputDisabledLoanIn: false,
      skipFormSubmitHandler: false,
      isSecondCustomer: false,
      showSecondCustomerToggle: false,
    };
  }

  schema = {
    typeId: Joi.number(),
    costId: Joi.string().optional().allow('', null),
    customerId: Joi.string().optional().allow('', null),
    secondCustomerId: Joi.string().optional().allow('', null),
    equalsTotal: Joi.boolean().truthy(true),
    residualAmount: Joi.number().optional(),
    residualComment: Joi.string().optional().allow('', null),
  }

  loanColumns = [
    { key: 'participant',
    content: deal => (
      <div>{deal.customerName || deal.creditorName}</div>
    ),label: 'Participant' },
    {path: 'contractNumber', label: 'Contract'},
    {path: 'balance', label: 'Balance'},
    {key: 'principal',
    content: deal => (
      <input
      type='decimal'
      hidden={this.state.isInputDisabledLoanIn}
      onChange={(e) => {
        let readyTransactions = this.state.readyTransactions;
        const principal = parseFloat(e.target.value);
        const data = this.state.data;
        //previous amount 
        let oldAmount = 0;
        const oldDeal = readyTransactions.filter(item => item._id === deal._id);

        if (oldDeal[0] && oldDeal[0].principal) {
          oldAmount = oldDeal[0].principal;
        };

        //set new prop to deal, if Nan then 0
        if (principal) {
          deal.principal = principal;
        } else {
          deal.principal = 0;
        }

        data.residualAmount = Math.round((data.residualAmount + (oldAmount - deal.principal)) * 100) / 100;

        // push deal to array of ready transaction
        if (!readyTransactions.find(insertedDeal => insertedDeal._id === deal._id)) {
          readyTransactions.push(deal)
        }
        // remove from array if amount is not number
        if(isNaN(principal)) {
          readyTransactions = readyTransactions.filter(item => item._id !== deal._id)
        }
        data.equalsTotal = true;
        this.setState({ readyTransactions, data })    
      }}
      >
      
      </input>
    ), label: 'Principal'
    },
    {key: 'interest',
    content: deal => (
      <input
      type='decimal'
      hidden={this.state.isInputDisabledLoanIn}
      onChange={(e) => {
        let readyTransactions = this.state.readyTransactions;
        const interest = parseFloat(e.target.value);
        const data = this.state.data;

        //previous amount 
        let oldAmount = 0;
        const oldDeal = readyTransactions.filter(item => item._id === deal._id);

        if (oldDeal[0] && oldDeal[0].interest) {
          oldAmount = oldDeal[0].interest;
        };

        //set new prop to deal, if Nan then 0
        if (interest) {
          deal.interest = interest;
        } else {
          deal.interest = 0;
        }

        data.residualAmount = Math.round((data.residualAmount + (oldAmount - deal.interest)) * 100) / 100;

        // push deal to array of ready transaction
        if (!readyTransactions.find(insertedDeal => insertedDeal._id === deal._id)) {
          readyTransactions.push(deal)
        }
        // remove from array if amount is not number
        if(isNaN(interest)) {
          readyTransactions = readyTransactions.filter(item => item._id !== deal._id)
        }
        data.equalsTotal = true;
        this.setState({ readyTransactions, data })    
      }}
      >
      
      </input>
    ), label: 'Interest'
    },
    {key: 'additional',
    content: deal => (
      <input
      type='decimal'
      hidden={this.state.isInputDisabledLoanAdd}
      onChange={(e) => {
        let readyTransactions = [...this.state.readyTransactions];
        const additional = parseFloat(e.target.value);
        const data = this.state.data;

        //previous amount 
        let oldAmount = 0;
        const oldDeal = readyTransactions.filter(item => item._id === deal._id);

        if (oldDeal[0] && oldDeal[0].additional) {
          oldAmount = oldDeal[0].additional;
        };

        //set new prop to deal, if Nan then 0
        if (additional) {
          deal.additional = additional;
        } else {
          deal.additional = 0;
        }

        data.residualAmount = Math.round((data.residualAmount + (oldAmount - deal.additional)) * 100) / 100;

        // push deal to array of ready transaction
        if (!readyTransactions.find(insertedDeal => insertedDeal._id === deal._id)) {
          readyTransactions.push(deal)
        }
        // remove from array if amount is not number
        if(isNaN(additional)) {
          readyTransactions = readyTransactions.filter(item => item._id !== deal._id)
        }
        data.equalsTotal = true;
        this.setState({ readyTransactions, data })    
      }}
      >
      
      </input>
    ), label: 'Additional'
    },
    {key: 'initBalance',
    content: deal => (
      <input
      type='decimal'
      hidden={this.state.isInputDisabledLoanAdd}
      onChange={(e) => {
        let readyTransactions = [...this.state.readyTransactions];
        const initBalance = parseFloat(e.target.value);
        const data = this.state.data;

        //previous amount 
        let oldAmount = 0;
        const oldDeal = readyTransactions.filter(item => item._id === deal._id);

        if (oldDeal[0] && oldDeal[0].initBalance) {
          oldAmount = oldDeal[0].initBalance;
        };

        //set new prop to deal, if Nan then 0
        if (initBalance) {
          deal.initBalance = initBalance;
        } else {
          deal.initBalance = 0;
        }

        data.residualAmount = Math.round((data.residualAmount + (oldAmount - deal.initBalance)) * 100) / 100;

        // push deal to array of ready transaction
        if (!readyTransactions.find(insertedDeal => insertedDeal._id === deal._id)) {
          readyTransactions.push(deal)
        }
        // remove from array if amount is not number
        if(isNaN(initBalance)) {
          readyTransactions = readyTransactions.filter(item => item._id !== deal._id)
        }
        data.equalsTotal = true;
        this.setState({ readyTransactions, data })    
      }}
      >
      
      </input>
    ), label: 'init Balance'
    },
    {key: "schedule",
    content: deal => (
      <button
      hidden={this.state.isInputDisabledLoanIn}
      onClick={ async () => {
        this.setState({skipFormSubmitHandler: true})
        const transactionAmount = this.props.openTransactionAmount;

        if (deal.isSchedule) {
          const onePaymentFromSchedule = parseFloat(deal.schedule[0].total.replace(/[^0-9\.-]+/g,""));
          if (transactionAmount === onePaymentFromSchedule) {
            const data = this.state.data;
            data.residualAmount = 0;
            deal.schedule = onePaymentFromSchedule;

            await processTransaction(data, this.props.openTransactionId, [deal])
            
            let proccessReady = this.state.proccessReady;
            proccessReady = true;
            this.setState({ proccessReady });
            
          } else {
            toast.warn(`Transaction amount ${transactionAmount}€ not equal deal amount ${onePaymentFromSchedule}€`)
          }  
        } else {
          toast.warn('This loan do not have schedule');
        }

        this.setState({skipFormSubmitHandler: false})
      }
    }
    className="btn btn-primary btn-sm"
    >
    make payment
  </button>
), label: "Schedule payment"}
  ]

  accountColumns = [
    { key: 'name',
    content: account => (
      <div>{account.name}</div>
    ),label: 'Name' },
    { key: 'type',
    content: account => (
      <div>{account.typeName}</div>
    ),label: 'Type' },
    {key: "input",
        content: account => (
          <input
          type="decimal"
          onChange={(e) => {
            let readyTransactions = [...this.state.readyTransactions];
            
            const data = {...this.state.data};
            const amount = parseFloat(e.target.value);
            
            //previous amount 
            let oldAmount = 0;
            const oldDeal = readyTransactions.filter(item => item._id === account._id);

            if (oldDeal[0] && oldDeal[0].amount) {
              oldAmount = oldDeal[0].amount;
            };
   
            //set new prop to deal, if Nan then 0
            if (amount) {
              account.amount = amount;
            } else {
              account.amount = 0;
            }

            data.residualAmount = Math.round((data.residualAmount + (oldAmount - account.amount)) * 100) / 100;
            
            // push deal to array of ready transaction
            if (!readyTransactions.find(insertedAccount => insertedAccount._id === account._id)) {
              readyTransactions.push(account)
            }
            // remove from array if amount is not number
            if(isNaN(amount)) {
              readyTransactions = readyTransactions.filter(item => item._id !== account._id)
            }
            
            //this.checkIfAmountIsReadyProcess(amount, dealAmount)
            
            this.setState({ readyTransactions, data })          
          }}
          />
          ), label: "Value"},
          {key: "oneAccount",
          content: account => (
            <button
            onClick={ async () => {
                this.setState({skipFormSubmitHandler: true})
                const data = {...this.state.data};
                data.residualAmount = 0;
                account.amount = this.props.openTransactionAmount;

                await processTransaction(data, this.props.openTransactionId, [account])

                let proccessReady = this.state.proccessReady;
                proccessReady = true;
                this.setState({ proccessReady, skipFormSubmitHandler: false });
            }
          }
          className="btn btn-primary btn-sm"
          >
          Select
        </button>
    )}
  ];
      
  factoringColumns = [
        { key: 'participant',
          content: deal => (
            <div>{deal.customerName || deal.payerName}</div>
          ),label: 'Participant' },
        { path: "invoiceNumber", label: "Invoice/Contract" },
        { key: "amount", 
          content: deal => (
          <div>{deal.invoiceAmount || deal.payment}</div>

          ),
        
        label: "Amount"},
        {key: "input",
        content: deal => (
          <input
          type="decimal"
          onChange={(e) => {
            let readyTransactions = [...this.state.readyTransactions];
            
            const data = this.state.data;
            const amount = parseFloat(e.target.value);
            const dealAmount = deal.invoiceAmount || deal.payment;
            
            //previous amount 
            let oldAmount = 0;
            const oldDeal = readyTransactions.filter(item => item._id === deal._id);

            if (oldDeal[0] && oldDeal[0].amount) {
              oldAmount = oldDeal[0].amount;
            };
   
            //set new prop to deal, if Nan then 0
            if (amount) {
              deal.amount = amount;
            } else {
              deal.amount = 0;
            }

            data.residualAmount = Math.round((data.residualAmount + (oldAmount - deal.amount)) * 100) / 100;
            
            // push deal to array of ready transaction
            if (!readyTransactions.find(insertedDeal => insertedDeal._id === deal._id)) {
              readyTransactions.push(deal)
            }
            // remove from array if amount is not number
            if(isNaN(amount)) {
              readyTransactions = readyTransactions.filter(item => item._id !== deal._id)
            }
            
            this.checkIfAmountIsReadyProcess(amount, dealAmount)
            
            this.setState({ readyTransactions, data })          
          }}
          />
          ), label: "Value"},
          {key: "oneDeal",
          content: deal => (
            <button
            onClick={ async () => {
              this.setState({ skipFormSubmitHandler: true });
              const currentAmount = deal.invoiceAmount || deal.payment;
              if(this.props.openTransactionAmount === currentAmount){
                const data = this.state.data;
                data.residualAmount = 0;
                deal.amount = currentAmount;

                await processTransaction(data, this.props.openTransactionId, [deal])
                
                let proccessReady = this.state.proccessReady;
                proccessReady = true;
                this.setState({ proccessReady });
                
              } else {
                toast.warn(`Transaction amount ${this.props.openTransactionAmount}€ not equal deal amount ${currentAmount}€`)
              }
              this.setState({ skipFormSubmitHandler: false });  
            }
          }
          className="btn btn-primary btn-sm"
          >
          Select
        </button>
    )}
  ]

  sumArrayAmounts = (readyTransactions) => {
    return readyTransactions.reduce((acc, curr) => {
      const amount = curr.amount || 0;
      const principal = curr.principal || 0;
      const interest = curr.interest || 0;
      const additional = curr.additional || 0;
      const initBalance = curr.initBalance || 0;
      return acc + amount + principal + interest + additional + initBalance;
    }, 0);
  
  }

  checkIfAmountIsReadyProcess = (input, dealAmount) => {
    const data = this.state.data;
    if (input <= dealAmount) {
        data.equalsTotal = true;
        toast.info('Amount is correct.')
    } else if(!isNaN(input)) {
      data.equalsTotal = "No";
      toast.warn(`Amount ${input}€ exceeds deal amount ${dealAmount}€`)
    } else {
      data.equalsTotal = "No";
    }
  }


  checkIfSumIsReadyProcess = (sumOfDeals, transactionAmount) => {
      const totalAmount = Math.round((sumOfDeals + parseFloat(this.state.data.residualAmount)) * 100) / 100 ;

      if (totalAmount === transactionAmount) {
        toast.info('Amount is correct.')
        return true;
      } else {
        toast.warn(`Total amount ${totalAmount}€ not equal transaction amount ${transactionAmount}€`)
        return false;
      }
  }

  setCustomers = async () => {
      setTimeout(async () => {
        const typeId = this.state.data.typeId;

        if (typeId === '1' &&  this.props.openTransactionInOrOut === 'CRDT') {
          this.setState({ showSecondCustomerToggle: true })
        } else {
          this.setState({ showSecondCustomerToggle: false })
        }
        if (!['4', '5', '6'].includes(typeId)) {
          let customers = [];
          const { data: filteredCustomers } = await getCustomersByType(typeId)
          let data = {...this.state.data};
          data.customerId = '';
          let readyTransactions = [];

          if (filteredCustomers[0] && filteredCustomers[0].customerName) {
              filteredCustomers.forEach(customer => {
                  customers.push({
                      _id: customer.customerId,
                      name: customer.customerName
                  })
              });
          } else {
              filteredCustomers.forEach(customer => {
                  customers.push({
                      _id: customer.creditorId,
                      name: customer.creditorName
                  })
              });
          }
          this.setState({ customers, readyTransactions, data })
        }
      }, 1000)
  }

  openDeals = async () => {
    setTimeout(async () => {
      const data = this.state.data;
      const isCBITorDBIT = this.props.openTransactionInOrOut;

      // render second customers
      if (
        isCBITorDBIT === 'CRDT'
        && data.typeId === '1'
      ) {
        const secondCustomers = this.state.customers.filter(customer => customer._id !== data.customerId);
        this.setState({ secondCustomers })
      }
      
      const { data: openDeals } = await getOpenDeals(data.customerId, data.typeId, isCBITorDBIT)
      // kui muutub siis teeb tyhjaks
      let readyTransactions = [];

      //disable input if CRDT or DBIT
      let { isInputDisabledLoanAdd, isInputDisabledLoanIn } = this.state;
      if (data.typeId === '2' && isCBITorDBIT === 'CRDT') {
        isInputDisabledLoanAdd = true;
        isInputDisabledLoanIn = false;
      }
      else if (data.typeId === '2' && isCBITorDBIT === 'DBIT') {
        isInputDisabledLoanAdd = false;
        isInputDisabledLoanIn = true;
      }
      else if  (data.typeId === '3' && isCBITorDBIT === 'CRDT') {
        isInputDisabledLoanAdd = false;
        isInputDisabledLoanIn = true;
      }
      else if (data.typeId === '3' && isCBITorDBIT === 'DBIT') {
        isInputDisabledLoanAdd = true;
        isInputDisabledLoanIn = false;
      }

      // init residual amont
      data.residualAmount = this.props.openTransactionAmount;
      this.setState({ openDeals, readyTransactions, isInputDisabledLoanAdd, isInputDisabledLoanIn, data })
    }, 1000)
  }

  resetSecondCustomerValues = () => {
    const data = {...this.state.data};
    
    const secondCustomerOpenDeals = this.state.secondOpenDeals.map(factoring => factoring._id);
    const readyTransactions = this.state.readyTransactions.filter(factoring => {
      if (secondCustomerOpenDeals.includes(factoring._id)) {
        data.residualAmount = data.residualAmount + factoring.amount;
        return false;
      }
      return true;
    });

    this.setState({ readyTransactions, data })
  }

  secondOpenDeals = async () => {
    setTimeout(async () => {
      this.resetSecondCustomerValues();
      const { data: secondOpenDeals } = await getOpenDeals(this.state.data.secondCustomerId, '1', 'CRDT')
        .catch(err => console.log(err));
      

      this.setState({ secondOpenDeals })
    }, 1000)
}
  getAccountsSelection = async () => {
    setTimeout(async () => {
      if ([ '4', '5','6'].includes(this.state.data.typeId)) {
  
        const data = {...this.state.data};
        data.equalsTotal = true;
        const {data: allAccounts } = await getAllAccounts();

        // kui muutub siis teeb tyhjaks
        let readyTransactions = [];
  
        // init residual amont
        data.residualAmount = this.props.openTransactionAmount;

        this.setState({ 
          costs: allAccounts.costs, 
          incomes: allAccounts.incomes, 
          others: allAccounts.others, 
          readyTransactions, 
          data 
        })
      }
    }, 1000)
}

  doSubmit = async () => {
    if (this.state.skipFormSubmitHandler) {
      return;
    } else {
      // check if number are ok
      const readyTransactions = this.state.readyTransactions;
      const { openTransactionAmount, openTransactionId } = this.props;
      const data = this.state.data;
      let sumOfDeals = Math.round(this.sumArrayAmounts(readyTransactions) * 100) / 100;
  
      if (this.checkIfSumIsReadyProcess(sumOfDeals, openTransactionAmount)) {
        await processTransaction(data, openTransactionId, readyTransactions)

        let proccessReady = this.state.proccessReady;
        proccessReady = true;
        this.setState({ proccessReady })
      }
    }
  
  };

  togglesIsSecondCustomer = () => {
    this.resetSecondCustomerValues();
    this.setState({ isSecondCustomer: !this.state.isSecondCustomer })
  }

  render() {
    const openDeals = this.state.openDeals;
    const isSecondCustomer = this.state.isSecondCustomer;
    const showSecondCustomerToggle = this.state.showSecondCustomerToggle;
    const secondOpenDeals = this.state.secondOpenDeals;
    const typeId = this.state.data.typeId;
    const customerId = this.state.data.customerId;

    if (this.state.proccessReady) {
      return <Redirect to='/opentransactions'/>
    };

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
            {this.renderSelect("typeId", "Type", this.state.types,{ onChange: (e) => {
              this.setCustomers();
              this.getAccountsSelection();
            }
            })}
            {showSecondCustomerToggle && <div><input
              type="checkbox"
              onChange={() => this.togglesIsSecondCustomer()}
              ></input><label> Second customer</label></div>
            }
            {typeId > 0 && typeId < 4 && this.renderSelect("customerId", "Participant", this.state.customers,
                { onChange: (e) => { this.openDeals() }
            })}
            {typeId  === '1' && openDeals.length > 0 &&
            (
            <TableNoSort
              columns={this.factoringColumns}
              data={openDeals}
            />)}
            {typeId === '1' && isSecondCustomer && this.renderSelect("secondCustomerId", "Participant 2", this.state.secondCustomers,
            { onChange: (e) => { this.secondOpenDeals() }
            })}
            {typeId  === '1' 
            && secondOpenDeals.length > 0 
            && this.state.data.secondCustomerId
            && isSecondCustomer
            &&
            (
            <TableNoSort
              columns={this.factoringColumns}
              data={secondOpenDeals}
            />)}
            {typeId  === '2' && openDeals.length > 0 &&
            (
            <TableNoSort
              columns={this.loanColumns}
              data={openDeals}
            />)}
            {typeId  === '3' && openDeals.length > 0 &&
            (
            <TableNoSort
              columns={this.loanColumns}
              data={openDeals}
            />)}
            {typeId  === '4' &&
            (
            <TableNoSort
              columns={this.accountColumns}
              data={this.state.costs}
            />)}
            {typeId  === '5' &&
            (
            <TableNoSort
              columns={this.accountColumns}
              data={this.state.incomes}
            />)}
            {typeId  === '6' &&
            (
            <TableNoSort
              columns={this.accountColumns}
              data={this.state.others}
            />)}
            { customerId !== '' && this.renderInput("residualAmount", "Residual Amount", "number")}
            { customerId !== '' && this.renderInput("residualComment", "Comment")}
            {this.renderButton("Proceed")}
        </form>
        <div>
        </div>
      </div>
      
    );
  }
}

export default TransactionForm;
