import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "joi-browser";

import Form from "../common/form";
import auth from "../../services/authService";

class LoginForm extends Form {
  state = {
    data: {
      username: "",
      password: "" ,
      secret: "" ,
    },
    errors: {}
  };

  schema = {
    username: Joi.string()
      .required()
      .label("Username"),
    password: Joi.string()
      .required()
      .label("Password"),
    secret: Joi.string()
      .label("Secret")
      .optional()
      .allow('')
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      await auth.login(data.username, data.password, data.secret);
      const { state } = this.props.location;
      window.location = state ? state.from.pathname : "/dashboard";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/dashboard" />;
    return (
      <div className={"form"}>
        <h1>Login</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("username", "Username")}
          {this.renderInput("password", "Password", "password")}
          {this.renderInput("secret", "Secret")}
          {this.renderButton("Login")}
        </form>
      </div>
    );
  }
}

export default LoginForm;
