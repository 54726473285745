import React, { Component } from "react";
import { getTransaction } from '../../services/bankService';
import TransactionForm from './transactionForm';

class Transaction extends Component {
    state = {
        openTransaction: {},
    };

    async componentDidMount() {
        const { data: openTransaction } = await getTransaction (this.props.match.params.id);
        this.setState({ openTransaction });
    }

  render() {
    const openTransaction = this.state.openTransaction;

    return (
        <div className={"page"}>
            <p>DBIT on väljamakse</p>
            <p>CRDT on sissetulek</p>
            <table className="table table-sm">
                <thead>
                    <tr>
                    <th scope="col">Name</th>
                    <th scope="col">D/B</th>
                    <th scope="col">Date</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>{openTransaction.name}</td>
                    <td>{openTransaction.transaction}</td>
                    <td>{openTransaction.date}</td>
                    <td>{openTransaction.amount}</td>
                    <td>{openTransaction.description}</td>
                    </tr>
                </tbody>
            </table>
            <TransactionForm
                openTransactionId={openTransaction._id}
                openTransactionAmount={openTransaction.amount}
                openTransactionInOrOut={openTransaction.transaction}
            />
        </div>



    )
  }
}


export default Transaction;
