import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getLoanStatus, saveLoanStatus } from "../../services/loanStatusService";

class LoanStatusForm extends Form {
  state = {
    data: {
      name: ""
    },
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Loan Status")
  };

  async populateLoanStatuses() {
    try {
      const loanStatusId = this.props.match.params.id;
      if (loanStatusId === "new") return;

      const { data: loanStatus } = await getLoanStatus(loanStatusId);
      this.setState({ data: this.mapToViewModel(loanStatus) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateLoanStatuses();
  }

  mapToViewModel(loanStatus) {
    return {
      _id: loanStatus._id,
      name: loanStatus.name
    };
  }

  doSubmit = async () => {
    await saveLoanStatus(this.state.data);

    this.props.history.push("/loanstatuses");
  };

  render() {
    return (
      <div className={"form"}>
        <h1>Loan Status Form</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Loan Status Name")}
          {this.renderButton("Save")}
        </form>
      </div>
    );
  }
}

export default LoanStatusForm;
